import React, { useState, useEffect, useRef } from 'react';
import { MdAdd, MdDeleteForever, MdRefresh } from 'react-icons/md';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { format, parseISO, formatDistanceToNow } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';
import { useSelector } from 'react-redux';
import api from '~/services/api';
import colors from '~/styles/colors';

import { FieldGroup } from '~/components/Forms';
import { Button } from '~/components/Buttons/Button';
import Can from '~/components/Can';
import ClipboardButton from '~/components/ClipboardButton';
import { BaseContainer } from '~/components/BaseContainer';
import { Table } from '~/components/Table';
import { Title } from '~/components/Title';
import { NoInfoToShow } from '~/components/NoInfoToShow';
import Actions from '~/components/Actions';
import Pagination from '~/components/Pagination';
import ColumnHeader from '~/components/ColumnHeader';
import getErrorMessage from '~/lib/getErrorMessage';

import { Header, CellWrapper } from './styles';

function SupportTokens({ contract, maxWidthPercent }) {
  const role = useSelector((state) => state.role.role);

  const [filter, setFilter] = useState('');
  const time = useRef(null);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [tokens, setTokens] = useState([]);
  const [order, setOrder] = useState({ field: 'created_at', asc: false });
  const [refresh, setRefresh] = useState(true);

  useEffect(() => {
    async function load(f) {
      if (contract) {
        setLoading(true);

        const response = await api.get(
          `support-tokens/contracts/${contract.id}`,
          {
            params: {
              q: f,
              page,
              pageLimit,
              order: order.field,
              direction: order.asc ? 'ASC' : 'DESC',
            },
          }
        );

        const data = response.data.map((tk) => {
          return {
            ...tk,
            expirationDateFormatted:
              tk.expiration_date &&
              format(parseISO(tk.expiration_date), 'dd/MM/yyyy HH:mm:ss'),
            expirationDistance:
              tk.expiration_date &&
              formatDistanceToNow(parseISO(tk.expiration_date), {
                locale: pt,
                // addSuffix: true,
              }),
            createdAtFormatted:
              tk.createdAt &&
              format(parseISO(tk.createdAt), 'dd/MM/yyyy HH:mm:ss'),
          };
        });

        setTotalPages(Number(response.headers['x-api-totalpages']));
        setTotal(Number(response.headers['x-api-total']));
        setTokens(data);

        setLoading(false);
      }
    }

    clearTimeout(time.current);
    time.current = setTimeout(() => {
      load(filter);
    }, 600);
  }, [filter, page, pageLimit, order, contract, refresh]);

  function reload() {
    setFilter(' ');
    setFilter('');
  }

  async function createNewToken() {
    const payload = {};

    try {
      if (!contract) {
        return;
      }

      await api.post(`support-tokens/contracts/${contract.id}/`, payload); // Edit

      toast.success('Token de atendimento gerado com sucesso!');
      reload();
    } catch (err) {
      toast.error(
        <div>
          Falha ao salvar! <br /> <br />
          {getErrorMessage(err)}
        </div>
      );
    }
  }

  function handleCreate() {
    createNewToken();
  }

  async function handleDelete(id) {
    if (!window.confirm('Deseja mesmo cancelar o token de atendimento?')) {
      return;
    }

    try {
      await api.delete(`support-tokens/${id}`);
      toast.success('Token de atendimento cancelado com sucesso!');
      reload();
    } catch (err) {
      toast.error(
        <div>
          Falha ao cancelar Token de atendimento! <br /> <br />
          {getErrorMessage(err)}
        </div>
      );
    }
  }

  function handleChangeOrder(field) {
    setPage(1);
    setOrder({ field, asc: field === order.field ? !order.asc : true });
  }

  function handleRefresh() {
    setLoading(true);
    setRefresh(!refresh);
  }

  return (
    <BaseContainer maxWidthPercent={maxWidthPercent}>
      <Title>Tokens de Atendimento Ativos {total > 0 && `(${total})`}</Title>

      <Header>
        {/* <SearchInput
          placeholder="token de atendimento"
          value={filter}
          onChange={(e) => handleFilterChange(e.target.value)}
        /> */}
        <div />
        <FieldGroup>
          <Button
            type="button"
            padding="6px"
            disabled={loading}
            onClick={handleRefresh}>
            <MdRefresh size={24} color={colors.iconLight} />
          </Button>

          <Can
            role={role}
            perform="support-tokens:create"
            yes={() => (
              <Button primary type="button" onClick={handleCreate}>
                <MdAdd size={24} color={colors.iconLight} />
                <span>Cadastrar</span>
              </Button>
            )}
          />
        </FieldGroup>
      </Header>
      <Table>
        <thead>
          <tr>
            <th>
              <ColumnHeader
                label="Token"
                showOrder={order.field === 'token'}
                asc={order.asc}
                onClick={() => handleChangeOrder('token')}
              />
            </th>
            <th>
              <ColumnHeader
                label="Expira em"
                showOrder={order.field === 'expiration_date'}
                asc={order.asc}
                onClick={() => handleChangeOrder('expiration_date')}
              />
            </th>
            <th>
              <ColumnHeader
                label="Criado em"
                showOrder={order.field === 'created_at'}
                asc={order.asc}
                onClick={() => handleChangeOrder('created_at')}
              />
            </th>
            <th>
              <ColumnHeader label="Criado por" />
            </th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {tokens.map((item) => (
            <tr key={item.id}>
              <td>
                <CellWrapper>
                  {item.token}
                  <div>
                    <ClipboardButton value={item.token} />
                  </div>
                </CellWrapper>
              </td>
              <td>{`${item.expirationDateFormatted} ( ${item.expirationDistance} )`}</td>
              <td>{item.createdAtFormatted}</td>
              <td>{item.createdBy && item.createdBy.name}</td>
              <td>
                <Actions height={90} width={150}>
                  <Can
                    role={role}
                    perform="support-tokens:delete"
                    yes={() => (
                      <li>
                        <MdDeleteForever color="#DE3B3B" size={16} />
                        <button
                          type="button"
                          onClick={() => handleDelete(item.id)}>
                          Cancelar
                        </button>
                      </li>
                    )}
                    no={() => (
                      <li>
                        <MdDeleteForever
                          color={colors.textDisabled}
                          size={16}
                        />
                        <button type="button" disabled>
                          Cancelar
                        </button>
                      </li>
                    )}
                  />
                </Actions>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {!loading && tokens.length === 0 ? (
        <NoInfoToShow>Nenhuma informação a exibir.</NoInfoToShow>
      ) : (
        <Pagination
          totalPages={totalPages}
          page={page}
          setPage={setPage}
          pageLimit={pageLimit}
          setPageLimit={setPageLimit}
        />
      )}
    </BaseContainer>
  );
}

SupportTokens.propTypes = {
  contract: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
  maxWidthPercent: PropTypes.number,
};

SupportTokens.defaultProps = {
  contract: null,
  maxWidthPercent: undefined,
};

export default SupportTokens;
