import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { format, parseISO, isToday } from 'date-fns';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import api from '~/services/api';
import { BaseContainer } from '~/components/BaseContainer';
import { NoInfoToShow } from '~/components/NoInfoToShow';
import ColumnHeader from '~/components/ColumnHeader';
import checkPermission from '~/lib/checkPermission';
import getErrorMessage from '~/lib/getErrorMessage';

import { List, Row, Cell } from './styles';

function LastExceptions({ maxWidthPercent }) {
  const role = useSelector((state) => state.role.role);

  const [exceptions, setExceptions] = useState([]);
  const time = useRef(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function loadExceptions(f) {
      try {
        setLoading(true);
        const response = await api.get('exceptions', { params: { q: f } });

        const data = response.data.map((ex) => {
          return {
            ...ex,
            createdAtFormatted:
              ex.createdAt && format(parseISO(ex.createdAt), 'HH:mm'),
            isToday: isToday(parseISO(ex.createdAt)),
          };
        });

        const todayData = data.filter((ex) => ex.isToday);

        setExceptions(todayData);
        setLoading(false);
      } catch (err) {
        toast.error(
          <div>
            Falha ao carregar dados! <br /> <br />
            {getErrorMessage(err)}
          </div>
        );
      }
      return true;
    }

    /**
     * Check permissions
     */
    if (role && !checkPermission(role, 'exceptions:visit')) {
      return;
    }

    clearTimeout(time.current);
    time.current = setTimeout(() => {
      loadExceptions();
    }, 600);
  }, [role]);

  // function handleFilterChange(value) {
  //   setPage(1);
  //   setFilter(value);
  // }

  // function handleChangeOrder(field) {
  //   setPage(1);
  //   setOrder({ field, asc: field === order.field ? !order.asc : true });
  // }

  return (
    <BaseContainer maxWidthPercent={maxWidthPercent}>
      {/* <Title>Exceptions de Hoje {total > 0 && `(${total})`}</Title> */}

      {!loading && exceptions.length > 0 && (
        <List>
          <Row>
            <Cell style={{ width: 80 }}>
              <ColumnHeader label="Data" />
            </Cell>
            <Cell style={{ width: 120 }}>
              <ColumnHeader label="Aplicação" />
            </Cell>
            <Cell style={{ width: 300 }}>
              <ColumnHeader label="Mensagem" />
            </Cell>
            <Cell style={{ width: 180 }}>
              <ColumnHeader label="Cliente" />
            </Cell>
          </Row>
          {exceptions.map((item) => (
            <Row key={item.id}>
              <Cell style={{ width: 80 }}>{item.createdAtFormatted}</Cell>
              <Cell style={{ width: 120 }}>{item[`application.name`]}</Cell>
              <Cell style={{ width: 300 }}>{item.message}</Cell>
              <Cell style={{ width: 180 }}>{item[`customer.name`]}</Cell>
            </Row>
          ))}
        </List>
      )}

      {!loading && exceptions.length === 0 && (
        <NoInfoToShow>Nenhuma informação a exibir.</NoInfoToShow>
      )}
    </BaseContainer>
  );
}

LastExceptions.propTypes = {
  maxWidthPercent: PropTypes.number,
};

LastExceptions.defaultProps = {
  maxWidthPercent: null,
};

export default LastExceptions;
