import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input } from '@rocketseat/unform';
import * as Yup from 'yup';

import Logo from '~/components/Logo';
import { Container, LinkContainer } from './syles';
import { signInRequest } from '~/store/modules/auth/actions';
// import logo from '~/assets/logo.png';

const schema = Yup.object().shape({
  email: Yup.string()
    .email('Insira um e-mail válido')
    .required('O e-mail é obrigatório'),
  password: Yup.string().required('A senha é obrigatória'),
});

function SignIn() {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.auth.loading);

  function handleSubmit({ email, password }) {
    dispatch(signInRequest(email, password));
  }

  return (
    <Container>
      {/* <img src={logo} alt="API Central" /> */}
      <Logo />
      <h3>API CENTRAL</h3>
      <Form schema={schema} onSubmit={handleSubmit}>
        <label htmlFor="email">SEU E-MAIL</label>
        <Input name="email" type="email" placeholder="exemplo@e-mail.com" />

        <label htmlFor="password">SUA SENHA</label>
        <Input name="password" type="password" placeholder="***************" />

        <button type="submit">{loading ? 'Carregando...' : 'Entrar'}</button>
      </Form>
      <LinkContainer>
        <Link to="/reset-password">Esqueci minha senha</Link>
      </LinkContainer>
    </Container>
  );
}

export default SignIn;
