/* eslint-disable no-console */
import React, { useState, useEffect, useRef } from 'react';
import {
  MdAdd,
  MdEdit,
  MdVisibility,
  MdDeleteForever,
  MdRefresh,
} from 'react-icons/md';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { format, parseISO } from 'date-fns';
import api from '~/services/api';
import history from '~/services/history';
import colors from '~/styles/colors';
import { FieldGroup } from '~/components/Forms';
import { Button } from '~/components/Buttons/Button';
import Can from '~/components/Can';
import { BaseContainer } from '~/components/BaseContainer';
import { Table } from '~/components/Table';
import { Title } from '~/components/Title';
import { NoInfoToShow } from '~/components/NoInfoToShow';
import ColumnHeader from '~/components/ColumnHeader';
import Actions from '~/components/Actions';
import Pagination from '~/components/Pagination';
import ActiveInactiveStatus from '~/components/ActiveInactiveStatus';

import checkPermission from '~/lib/checkPermission';
import { LinkButton } from '~/components/Buttons/LinkButton';
import SearchInput from '~/components/Inputs/SearchInput';
import { Header } from './styles';

function Communications() {
  const role = useSelector((state) => state.role.role);

  const [filter, setFilter] = useState('');
  const [communications, setCommunications] = useState([]);
  const time = useRef(null);
  const [totalPages, setTotalPages] = useState(1);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState({ field: 'created_at', asc: true });
  const [refresh, setRefresh] = useState(true);

  useEffect(() => {
    async function loadInfos(f) {
      try {
        setLoading(true);
        const response = await api.get('communications', {
          params: {
            q: f,
            page,
            pageLimit,
            order: order.field,
            direction: 'DESC',
          },
        });

        const data = response.data.map((item) => {
          return {
            ...item,
            initialDateFormatted:
              item.initial_date &&
              format(parseISO(item.initial_date), 'dd/MM/yyyy'),
            initialDateEn:
              item.initial_date &&
              format(parseISO(item.initial_date), 'yyyy-MM-dd'),
            finalDateEn:
              item.final_date &&
              format(parseISO(item.final_date), 'yyyy-MM-dd'),
            finalDateFormatted:
              item.final_date &&
              format(parseISO(item.final_date), 'dd/MM/yyyy'),
            createdAtFormatted:
              item.createdAt &&
              format(parseISO(item.createdAt), 'dd/MM/yyyy HH:mm'),
          };
        });

        setTotalPages(Number(response.headers['x-api-totalpages']));
        setTotal(Number(response.headers['x-api-total']));
        setCommunications(data);
        setLoading(false);
      } catch (err) {
        toast.error(
          <div>
            Falha ao carregar dados! <br /> <br />
          </div>
        );
      }
      return true;
    }

    /**
     * Check permissions
     */
    if (role && !checkPermission(role, 'communications:visit')) {
      history.push('/denied');
      return;
    }

    clearTimeout(time.current);
    time.current = setTimeout(() => {
      loadInfos(filter);
    }, 600);
  }, [filter, page, pageLimit, order, role, refresh]);

  function handleFilterChange(value) {
    setPage(1);
    setFilter(value);
  }

  function handleCreate() {
    history.push('/communications/create');
  }

  function handleEdit(id) {
    const communicationsToEdit = communications.find((item) => item.id === id);
    if (communicationsToEdit) {
      history.push({
        pathname: '/communications/edit',
        communication: communicationsToEdit,
      });
    }
  }

  async function handleDelete(id) {
    // eslint-disable-next-line no-alert
    if (!window.confirm('Deseja mesmo excluir o comunicado?')) {
      return;
    }

    try {
      await api.delete(`communications/${id}`);
      toast.success('Comunicado excluído com sucesso!');

      setFilter('');
      setPage(communications.length === 1 ? 1 : page);
      setRefresh(!refresh);
    } catch (err) {
      toast.error(
        <div>
          Falha ao excluir a comunicado! <br /> <br />
        </div>
      );
    }
  }

  function handleChangeOrder(field) {
    setPage(1);
    setOrder({ field, asc: field === order.field ? !order.asc : true });
  }

  function handleRefresh() {
    setLoading(true);
    setRefresh(!refresh);
  }

  return (
    <BaseContainer>
      <Title>Comunicados {total > 0 && `(${total})`}</Title>

      <Header>
        <SearchInput
          placeholder="nome, aplicações"
          value={filter}
          onChange={(e) => handleFilterChange(e.target.value)}
        />
        <FieldGroup>
          <Button
            type="button"
            padding="6px"
            disabled={loading}
            onClick={handleRefresh}>
            <MdRefresh size={24} color={colors.iconLight} />
          </Button>

          <Can
            role={role}
            perform="communications:create"
            yes={() => (
              <Button primary type="button" onClick={handleCreate}>
                <MdAdd size={24} color={colors.iconLight} />
                <span>Cadastrar</span>
              </Button>
            )}
          />
        </FieldGroup>
      </Header>
      <Table>
        <thead>
          <tr>
            <th>
              <ColumnHeader
                label="Nome"
                showOrder={order.field === 'name'}
                asc={order.asc}
                onClick={() => handleChangeOrder('name')}
              />
            </th>
            <th>
              <ColumnHeader
                label="Aplicações"
                showOrder={order.field === 'application.name'}
                asc={order.asc}
                onClick={() => handleChangeOrder('application.name')}
              />
            </th>
            <th>
              <ColumnHeader
                label="Data Inicial"
                showOrder={order.field === 'initial_date'}
                asc={order.asc}
                onClick={() => handleChangeOrder('initial_date')}
              />
            </th>
            <th>
              <ColumnHeader
                label="Data Final"
                showOrder={order.field === 'final_date'}
                asc={order.asc}
                onClick={() => handleChangeOrder('final_date')}
              />
            </th>
            <th>
              <ColumnHeader
                label="Criado em"
                showOrder={order.field === 'created_at'}
                asc={order.asc}
                onClick={() => handleChangeOrder('created_at')}
              />
            </th>
            <th>
              <ColumnHeader
                label="Ativo"
                showOrder={order.field === 'active'}
                asc={order.asc}
                onClick={() => handleChangeOrder('active')}
              />
            </th>

            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {communications.map((item) => (
            <tr key={item.id}>
              <td>
                <Can
                  role={role}
                  perform="communications:edit"
                  yes={() => (
                    <LinkButton onClick={() => handleEdit(item.id)}>
                      {item.name}
                    </LinkButton>
                  )}
                  no={() => <>{item.name}</>}
                />
              </td>

              <td>{item.applications_name}</td>
              <td>{item.initialDateFormatted}</td>
              <td>{item.finalDateFormatted}</td>
              <td>{item.createdAtFormatted}</td>
              <td>
                <ActiveInactiveStatus active={item.active} />
              </td>
              <td>
                <Actions key={item.id} height={90} width={150}>
                  <Can
                    role={role}
                    perform="communications:edit"
                    yes={() => (
                      <li>
                        <MdEdit color="#4D85EE" size={16} />
                        <button
                          type="button"
                          onClick={() => handleEdit(item.id)}>
                          Editar
                        </button>
                      </li>
                    )}
                    no={() => (
                      <li>
                        <MdVisibility color="#4D85EE" size={16} />
                        <button
                          type="button"
                          onClick={() => handleEdit(item.id)}>
                          Visualizar
                        </button>
                      </li>
                    )}
                  />

                  <Can
                    role={role}
                    perform="communications:delete"
                    yes={() => (
                      <li>
                        <MdDeleteForever color="#DE3B3B" size={16} />
                        <button
                          type="button"
                          onClick={() => handleDelete(item.id)}>
                          Excluir
                        </button>
                      </li>
                    )}
                    no={() => (
                      <li>
                        <MdDeleteForever
                          color={colors.textDisabled}
                          size={16}
                        />
                        <button type="button" disabled>
                          Excluir
                        </button>
                      </li>
                    )}
                  />
                </Actions>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {!loading && communications.length === 0 ? (
        <NoInfoToShow>Nenhuma informação a exibir.</NoInfoToShow>
      ) : (
        <Pagination
          totalPages={totalPages}
          page={page}
          setPage={setPage}
          pageLimit={pageLimit}
          setPageLimit={setPageLimit}
        />
      )}
    </BaseContainer>
  );
}

export default Communications;
