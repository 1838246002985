import React from 'react';
import PropTypes from 'prop-types';

import { Container, Image } from './styles';

function ExceptionModal({ exception }) {
  return (
    <Container>
      <Image src={exception[`image.url`]} alt={exception.message} />
    </Container>
  );
}

ExceptionModal.propTypes = {
  exception: PropTypes.shape(),
};

ExceptionModal.defaultProps = { exception: null };

export default ExceptionModal;
