import produce from 'immer';

const INITIAL_STATE = {
  role: null,
};

export default function role(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@auth/SIGN_IN_SUCCESS': {
        draft.role = action.payload.user.role;
        break;
      }

      case '@role/REFRESH_ROLE_SUCCESS': {
        draft.role = action.payload.role;
        break;
      }

      case '@auth/SIGN_OUT': {
        draft.role = null;
        break;
      }

      default:
    }
  });
}
