import React, { useState, useEffect, useRef } from 'react';
import { MdAdd, MdEdit, MdDeleteForever, MdRefresh } from 'react-icons/md';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { format, parseISO } from 'date-fns';
import { useSelector } from 'react-redux';

import api from '~/services/api';
import colors from '~/styles/colors';

import Can from '~/components/Can';
import { BaseContainer } from '~/components/BaseContainer';
import { Table } from '~/components/Table';
import { Title } from '~/components/Title';
import { NoInfoToShow } from '~/components/NoInfoToShow';
import Actions from '~/components/Actions';
import Pagination from '~/components/Pagination';
import { Button } from '~/components/Buttons/Button';
import ColumnHeader from '~/components/ColumnHeader';
import getErrorMessage from '~/lib/getErrorMessage';
import { FieldGroup, Field } from '~/components/Forms';
import Modal from '~/components/Modal';
import DatabaseForm from '~/pages/Databases/DatabaseForm';
import { LinkButton } from '~/components/Buttons/LinkButton';
import SearchInput from '~/components/Inputs/SearchInput';

import { Header, FormContainer, AppsContainer, AppContainer } from './styles';

function DatabaseList({ contract, maxWidthPercent }) {
  const role = useSelector((state) => state.role.role);

  const [filter, setFilter] = useState('');
  const time = useRef(null);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [databases, setDatabases] = useState([]);
  const [order, setOrder] = useState({
    field: 'path_db',
    asc: true,
  });
  const [refresh, setRefresh] = useState(true);
  const [isShowingModal, setIsShowingModal] = useState(false);
  const [databaseToEdit, setDatabaseToEdit] = useState();

  useEffect(() => {
    async function loadDatabases(f) {
      if (contract) {
        setLoading(true);

        const response = await api.get(`contracts/${contract.id}/databases`, {
          params: {
            q: f,
            page,
            pageLimit,
            order: order.field,
            direction: order.asc ? 'ASC' : 'DESC',
          },
        });

        const data = response.data.map((item) => {
          return {
            ...item,
            applicationsFormatted:
              item.database_applications &&
              item.database_applications.map((dbApp) => {
                return (
                  <AppContainer key={`${dbApp.application.id}`}>
                    {dbApp.application.name}
                  </AppContainer>
                );
              }),
            createdAtFormatted:
              item.createdAt &&
              format(parseISO(item.createdAt), 'dd/MM/yyyy HH:mm:ss'),
          };
        });

        setTotalPages(Number(response.headers['x-api-totalpages']));
        setTotal(Number(response.headers['x-api-total']));
        setDatabases(data);

        setLoading(false);
      }
    }

    clearTimeout(time.current);
    time.current = setTimeout(() => {
      loadDatabases(filter);
    }, 600);
  }, [filter, page, pageLimit, order, contract, refresh]);

  function handleFilterChange(value) {
    setPage(1);
    setFilter(value);
  }

  function reload() {
    setFilter(' ');
    setFilter('');
  }

  function handleCreate() {
    setDatabaseToEdit();
    setIsShowingModal(true);
  }

  async function handleDelete(id) {
    if (!window.confirm('Deseja mesmo excluir o database?')) {
      return;
    }

    try {
      await api.delete(`contracts/${contract.id}/databases/${id}`);
      toast.success('Database removido com sucesso!');
      reload();
    } catch (err) {
      toast.error(
        <div>
          Falha ao remover database! <br /> <br />
          {getErrorMessage(err)}
        </div>
      );
    }
  }

  function handleChangeOrder(field) {
    setPage(1);
    setOrder({ field, asc: field === order.field ? !order.asc : true });
  }

  function handleRefresh() {
    setLoading(true);
    setRefresh(!refresh);
  }

  function handleEdit(id) {
    const db = databases.find((d) => d.id === id);
    if (db) {
      setDatabaseToEdit(db);
      setIsShowingModal(true);
    }
  }

  function handleHide() {
    setIsShowingModal(false);
    handleRefresh();
  }

  return (
    <BaseContainer maxWidthPercent={maxWidthPercent}>
      <Title>Databases {total > 0 && `(${total})`}</Title>

      <Modal isShowing={isShowingModal} hide={handleHide}>
        <FormContainer>
          <DatabaseForm
            contract_id={contract.id}
            database={databaseToEdit}
            hide={handleHide}
          />
        </FormContainer>
      </Modal>

      <Header>
        <SearchInput
          placeholder="path_db, remote_db, chave"
          value={filter}
          onChange={(e) => handleFilterChange(e.target.value)}
        />

        <FieldGroup>
          <Field flex="none">
            <Button
              type="button"
              padding="6px"
              disabled={loading}
              onClick={handleRefresh}>
              <MdRefresh size={24} color={colors.iconLight} />
            </Button>
          </Field>

          <Can
            role={role}
            perform="contracts:edit"
            yes={() => (
              <Button
                primary
                type="button"
                // padding="6px"
                onClick={handleCreate}>
                <MdAdd size={24} color={colors.iconLight} />
                <span>Cadastrar</span>
              </Button>
            )}
          />
        </FieldGroup>
      </Header>
      <Table>
        <thead>
          <tr>
            <th>Aplicações</th>
            <th>
              <ColumnHeader
                label="Chave"
                showOrder={order.field === 'key'}
                asc={order.asc}
                onClick={() => handleChangeOrder('key')}
              />
            </th>

            <th>
              <ColumnHeader
                label="Path DB"
                showOrder={order.field === 'path_db'}
                asc={order.asc}
                onClick={() => handleChangeOrder('path_db')}
              />
            </th>

            <th>
              <ColumnHeader
                label="Servidor"
                showOrder={order.field === 'server_info'}
                asc={order.asc}
                onClick={() => handleChangeOrder('server_info')}
              />
            </th>

            <th>
              <ColumnHeader
                label="Criado em"
                showOrder={order.field === 'created_at'}
                asc={order.asc}
                onClick={() => handleChangeOrder('created_at')}
              />
            </th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {databases.map((item) => (
            <tr key={item.id}>
              <td>
                {item.applicationsFormatted &&
                item.applicationsFormatted.length > 0 ? (
                  <AppsContainer>{item.applicationsFormatted}</AppsContainer>
                ) : (
                  <div>Todas</div>
                )}
              </td>
              <td>{item.key}</td>
              <td>
                <Can
                  role={role}
                  perform="contracts:edit"
                  yes={() => (
                    <LinkButton onClick={() => handleEdit(item.id)}>
                      {item.path_db}
                    </LinkButton>
                  )}
                  no={() => <>{item.path_db}</>}
                />
              </td>
              <td>{item.server_info}</td>
              <td>{item.createdAtFormatted}</td>
              <td>
                <Actions height={90} width={150}>
                  <li>
                    <MdEdit color="#4D85EE" size={16} />
                    <button type="button" onClick={() => handleEdit(item.id)}>
                      Editar
                    </button>
                  </li>
                  <Can
                    role={role}
                    perform="contracts:delete"
                    yes={() => (
                      <li>
                        <MdDeleteForever color="#DE3B3B" size={16} />
                        <button
                          type="button"
                          onClick={() => handleDelete(item.id)}>
                          Remover
                        </button>
                      </li>
                    )}
                    no={() => (
                      <li>
                        <MdDeleteForever
                          color={colors.textDisabled}
                          size={16}
                        />
                        <button type="button" disabled>
                          Remover
                        </button>
                      </li>
                    )}
                  />
                </Actions>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {!loading && databases.length === 0 ? (
        <NoInfoToShow>Nenhuma informação a exibir.</NoInfoToShow>
      ) : (
        <Pagination
          totalPages={totalPages}
          page={page}
          setPage={setPage}
          pageLimit={pageLimit}
          setPageLimit={setPageLimit}
        />
      )}
    </BaseContainer>
  );
}

DatabaseList.propTypes = {
  contract: PropTypes.shape({
    id: PropTypes.number.isRequired,
    contract: PropTypes.shape(),
  }),
  maxWidthPercent: PropTypes.number,
};

DatabaseList.defaultProps = {
  contract: null,
  maxWidthPercent: undefined,
};

export default DatabaseList;
