import styled from 'styled-components';
import colors from '../../../../styles/colors';

export const Container = styled.div`
  background: ${(props) => {
    switch (props.status) {
      case 'active':
        return colors.statusGreenSoft;
      case 'test':
        return colors.statusYellowSoft;
      case 'cancel':
        return colors.statusRedSoft;
      case 'susp':
        return colors.statusBlueSoft;
      default:
        break;
    }
  }};

  color: ${(props) => {
    switch (props.status) {
      case 'active':
        return colors.statusGreenStrong;
      case 'test':
        return colors.statusYellowStrong;
      case 'cancel':
        return colors.statusRedStrong;
      case 'susp':
        return colors.statusBlueStrong;
      default:
        break;
    }
  }};

  padding: 5px 10px;
  border-radius: 10px;
  /* font-weight: bold; */
  display: inline-block;

  span {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 10px;

    background: ${(props) => {
      switch (props.status) {
        case 'active':
          return colors.statusGreenStrong;
        case 'test':
          return colors.statusYellowStrong;
        case 'cancel':
          return colors.statusRedStrong;
        case 'susp':
          return colors.statusBlueStrong;
        default:
          break;
      }
    }};
  }
`;
