import React from 'react';
import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import history from '~/services/history';
import api from '~/services/api';
import getErrorMessage from '~/lib/getErrorMessage';

import { updateProfileSuccess, updateProfileFailure } from './actions';

export function* updateProfile({ payload }) {
  try {
    const {
      name,
      email,
      avatar_id,
      old_password,
      password,
      confirm_password,
      ...rest
    } = payload.data;

    const profile = {
      name,
      email,
      avatar_id,
      old_password,
      password,
      confirm_password,
      ...(rest.oldPassword ? rest : {}),
    };

    const response = yield call(api.put, 'users/profile', profile);

    toast.success('Perfil atualizado com sucesso!');

    yield put(updateProfileSuccess(response.data));
    history.push('/');
  } catch (err) {
    toast.error(
      <div>
        Falha ao salvar! <br /> <br />
        {getErrorMessage(err)}
      </div>
    );

    yield put(updateProfileFailure());
  }
}

export default all([takeLatest('@user/UPDATE_PROFILE_REQUEST', updateProfile)]);
