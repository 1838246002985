const permissionRules = {
  master: {
    static: [
      'users:visit',
      'users:create',
      'users:edit',
      'users:delete',

      'applications:visit',
      'applications:create',
      'applications:edit',
      'applications:delete',

      'contracts:visit',
      'contracts:create',
      'contracts:edit',
      'contracts:delete',
      'contracts/tech:visit',
      'contracts/tech:edit',
      'contracts/finance:visit',
      'contracts/finance:edit',
      'contracts/commercial:visit',
      'contracts/commercial:edit',

      'communications:visit',
      'communications:create',
      'communications:edit',
      'communications:delete',

      'licenses:visit',
      'licenses:create',
      'licenses:edit',
      'licenses:delete',

      'integrations:visit',
      'integrations:create',
      'integrations:edit',
      'integrations:delete',

      'customers:visit',
      'customers:create',
      'customers:edit',

      'partners:visit',
      'partners:create',
      'partners:edit',
      'partners:delete',

      'exceptions:visit',

      'application_messages:visit',

      'nps_surveys:visit',

      'external_configs:visit',
      'external_configs:create',
      'external_configs:edit',
      'external_configs:delete',

      'support-tokens:visit',
      'support-tokens:create',
      'support-tokens:edit',
      'support-tokens:delete',

      'versions:visit',
      'versions:create',
      'versions:edit',
      'versions:delete',

      'modules:visit',
      'modules:create',
      'modules:edit',
      'modules:delete',

      'release-groups:visit',
      'release-groups:create',
      'release-groups:edit',
      'release-groups:delete',
    ],
    dynamic: {},
  },

  admin: {
    static: [
      'users:visit',
      'users:create',
      'users:edit',
      'users:delete',

      'applications:visit',
      'applications:create',
      'applications:edit',
      'applications:delete',

      'contracts:visit',
      'contracts:create',
      'contracts:edit',
      'contracts:delete',
      'contracts/tech:visit',
      'contracts/tech:edit',
      'contracts/finance:visit',
      'contracts/finance:edit',
      'contracts/commercial:visit',
      'contracts/commercial:edit',

      'communications:visit',
      'communications:create',
      'communications:edit',
      'communications:delete',

      'licenses:visit',
      'licenses:create',
      'licenses:edit',
      'licenses:delete',

      'integrations:visit',
      'integrations:create',
      'integrations:edit',
      'integrations:delete',

      'customers:visit',
      'customers:create',
      'customers:edit',

      'partners:visit',
      'partners:create',
      'partners:edit',
      'partners:delete',

      'exceptions:visit',

      'application_messages:visit',

      'nps_surveys:visit',

      'external_configs:visit',
      'external_configs:create',
      'external_configs:edit',
      'external_configs:delete',

      'support-tokens:visit',
      'support-tokens:create',
      'support-tokens:edit',
      'support-tokens:delete',

      'versions:visit',
      'versions:create',
      'versions:edit',
      'versions:delete',

      'modules:visit',
      'modules:create',
      'modules:edit',
      'modules:delete',

      'release-groups:visit',
      'release-groups:create',
      'release-groups:edit',
      'release-groups:delete',
    ],
    dynamic: {},
  },

  suporte: {
    static: [
      'contracts:visit',
      'contracts/tech:visit',
      'contracts/tech:edit',
      'contracts/finance:visit',
      // 'contracts/finance:edit',
      'contracts/commercial:visit',
      // 'contracts/commercial:edit',

      'licenses:visit',
      'integrations:visit',

      'customers:visit',

      'partners:visit',

      'exceptions:visit',

      'application_messages:visit',

      'nps_surveys:visit',

      'support-tokens:visit',
      'support-tokens:create',

      'versions:visit',
    ],
    dynamic: {},
  },

  suporte2: {
    static: [
      'contracts:visit',
      'contracts:create',
      'contracts:edit',
      'contracts:delete',
      'contracts/tech:visit',
      'contracts/tech:edit',
      'contracts/finance:visit',
      // 'contracts/finance:edit',
      'contracts/commercial:visit',
      // 'contracts/commercial:edit',

      'licenses:visit',
      'licenses:create',
      'licenses:edit',
      'licenses:delete',

      'integrations:visit',
      'integrations:create',
      'integrations:edit',
      'integrations:delete',

      'customers:visit',
      'customers:create',
      'customers:edit',

      'partners:visit',
      'partners:create',
      'partners:edit',

      'exceptions:visit',

      'application_messages:visit',

      'nps_surveys:visit',

      'support-tokens:visit',
      'support-tokens:create',
      'support-tokens:edit',
      'support-tokens:delete',

      'versions:visit',
    ],
    dynamic: {},
  },

  desenvolvimento: {
    static: [
      'applications:visit',
      'applications:create',

      'contracts:visit',
      'contracts:create',
      'contracts:edit',
      // 'contracts:delete',
      'contracts/tech:visit',
      'contracts/tech:edit',
      'contracts/finance:visit',
      'contracts/finance:edit',
      'contracts/commercial:visit',
      'contracts/commercial:edit',

      'licenses:visit',
      'licenses:create',
      'licenses:edit',
      'licenses:delete',

      'integrations:visit',
      'integrations:create',
      'integrations:edit',
      'integrations:delete',

      'customers:visit',
      'customers:create',
      'customers:edit',

      'partners:visit',

      'exceptions:visit',

      'application_messages:visit',

      'nps_surveys:visit',

      'external_configs:visit',
      'external_configs:create',
      'external_configs:edit',
      // 'external_configs:delete',

      'support-tokens:visit',
      'support-tokens:create',
      'support-tokens:edit',
      'support-tokens:delete',

      'versions:visit',
      'versions:create',
      'versions:edit',
      'versions:delete',

      'modules:visit',
    ],
    dynamic: {},
  },

  qualidade: {
    static: [
      'applications:visit',
      'applications:create',
      // 'applications:edit',
      // 'applications:delete',

      'contracts:visit',
      'contracts:create',
      'contracts:edit',
      'contracts:delete',
      'contracts/tech:visit',
      'contracts/tech:edit',
      'contracts/finance:visit',
      'contracts/finance:edit',
      'contracts/commercial:visit',
      'contracts/commercial:edit',

      'licenses:visit',
      'licenses:create',
      'licenses:edit',
      'licenses:delete',

      'integrations:visit',
      'integrations:create',
      'integrations:edit',
      'integrations:delete',

      'customers:visit',
      'customers:create',
      'customers:edit',

      'partners:visit',
      'partners:create',
      'partners:edit',
      'partners:delete',

      'exceptions:visit',

      'application_messages:visit',

      'nps_surveys:visit',

      'external_configs:visit',
      'external_configs:create',
      'external_configs:edit',
      // 'external_configs:delete',

      'support-tokens:visit',
      'support-tokens:create',
      'support-tokens:edit',
      'support-tokens:delete',

      'versions:visit',
      'versions:create',
      'versions:edit',
      'versions:delete',

      'modules:visit',
      'modules:create',
      'modules:edit',
      'modules:delete',

      'release-groups:visit',
      'release-groups:create',
      'release-groups:edit',
      'release-groups:delete',
    ],
    dynamic: {},
  },

  financeiro: {
    static: [
      'contracts:visit',
      'contracts:create',
      'contracts:edit',
      'contracts:delete',
      // 'contracts/tech:visit',
      // 'contracts/tech:edit',
      'contracts/finance:visit',
      'contracts/finance:edit',
      'contracts/commercial:visit',
      'contracts/commercial:edit',

      'licenses:visit',
      'licenses:create',
      'licenses:edit',
      'licenses:delete',

      'customers:visit',
      'customers:create',
      'customers:edit',

      'partners:visit',

      'nps_surveys:visit',
      'modules:visit',
    ],
    dynamic: {},
  },

  comercial: {
    static: [
      'contracts:visit',
      'contracts:create',
      'contracts:edit',
      'contracts:delete',
      // 'contracts/tech:visit',
      // 'contracts/tech:edit',
      'contracts/finance:visit',
      // 'contracts/finance:edit',
      'contracts/commercial:visit',
      'contracts/commercial:edit',

      'licenses:visit',
      'licenses:create',
      'licenses:edit',
      'licenses:delete',

      'integrations:visit',

      'customers:visit',
      'customers:create',
      'customers:edit',

      'partners:visit',

      'nps_surveys:visit',
      'modules:visit',
    ],
    dynamic: {},
  },

  marketing: {
    static: [
      'applications:visit',

      'nps_surveys:visit',

      'communications:visit',
      'communications:create',
      'communications:edit',
      'communications:delete',
    ],
    dynamic: {},
  },
};

export default permissionRules;
