import styled from 'styled-components';
import colors from '~/styles/colors';

export const Button = styled.button`
  background: none;
  margin: 3px;
  color: ${colors.textDark};
  padding: 10px;
  cursor: pointer;
  transition: all 0.3s ease 0s;

  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-bottom: ${(props) =>
    props.active
      ? `2px solid ${colors.color_3}`
      : `2px solid ${colors.background}`};
`;

export const Container = styled.div`
  padding: 0 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  /* button:hover {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  } */
`;
