import { darken } from 'polished';

const colors = {
  color_5: '#1B1B1E',
  color_4: '#373F51',
  color_3: '#58A4B0',
  color_2: '#A9BCD0',
  color_1: '#D8DBE2',

  primary: '#58A4B0',
  neutral: '#D8DBE2',

  textDark: '#373F51',
  textDarker: '#1B1B1E',
  textLight: '#fff',
  textDisabled: '#ccc',
  textError: '#fb6f91',

  statusRed: '#DE3B3B',
  statusYellow: '#C1BC35',

  iconLight: '#fff',

  inputBorder: '#ccc',
  inputBorderHover: darken(0.06, '#ccc'),
  panelBorder: '#ddd',

  background: '#f5f5f5',

  panelBackground: '#fff',
  inputBackground: '#fff',
  inputPlaceholder: '#ccc',

  statusGreenStrong: '#2CA42B',
  statusGreenSoft: '#DFF0DF',
  statusBlueStrong: '#4D85EE',
  statusBlueSoft: '#BAD2FF',
  statusYellowStrong: '#C1BC35',
  statusYellowSoft: '#F0F0DF',
  statusRedStrong: '#DE3B3B',
  statusRedSoft: '#FAB0B0',

  // linkColor: '#0000ee',
  linkColor: '#0073BB',
};

export default colors;
