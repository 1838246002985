import React, { useState, useEffect } from 'react';
// import InputMask from 'react-input-mask';
import PropTypes from 'prop-types';
import { Form, Input, Check } from '@rocketseat/unform';
import { MdDone, MdArrowBack } from 'react-icons/md';
import { toast } from 'react-toastify';
import 'react-tabs/style/react-tabs.css';
import { useSelector } from 'react-redux';
import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/pt';

import api from '~/services/api';
import history from '~/services/history';
import colors from '~/styles/colors';

import { BaseContainer } from '~/components/BaseContainer';
import { FormHeader } from '~/components/FormHeader';
import { Button } from '~/components/Buttons/Button';
import { Title } from '~/components/Title';
import { FormWrapper, FieldGroup, Field } from '~/components/Forms';

import checkPermission from '~/lib/checkPermission';
import getErrorMessage from '~/lib/getErrorMessage';

// import { } from './styles';

function ExternalConfigsForm({ location }) {
  const { config } = location;
  const isEditing = history.location.pathname === '/external-configs/edit';
  const [value, setValue] = useState(config ? config.value : null);

  /**
   * Permissions
   */
  const role = useSelector((state) => state.role.role);
  const denied =
    !isEditing && !checkPermission(role, 'external_configs:create');

  useEffect(() => {
    /**
     * Redirect if permission denied
     */
    if (denied) {
      history.push('/denied');
      return;
    }

    /**
     * Go back on page refresh
     */
    if (isEditing && !config) {
      history.goBack();
    }
  }, [config, isEditing, denied]);

  /**
   * Form
   */
  function handleClickBack() {
    history.goBack();
  }

  function handleChange({ jsObject }) {
    setValue(jsObject);
  }

  async function save(name, key, active) {
    const payload = { name, key, value: value || null, active };

    try {
      if (config) {
        await api.put(`external-configs/${config.id}`, payload); // Edit
      } else {
        await api.post('external-configs', payload); // Create
      }

      toast.success(
        `Cadastro ${isEditing ? 'alterado' : 'realizado'} com sucesso!`
      );

      return history.push('/external-configs');
    } catch (err) {
      return toast.error(
        <div>
          Falha ao salvar! <br /> <br />
          {getErrorMessage(err)}
        </div>
      );
    }
  }

  function handleSubmit({ name, key, active }) {
    if (!isEditing && !checkPermission(role, 'external_configs:create')) {
      return;
    }
    if (isEditing && !checkPermission(role, 'external_configs:edit')) {
      return;
    }

    save(name, key, active);
  }

  return (
    <>
      <BaseContainer>
        <FormWrapper>
          <Form onSubmit={handleSubmit} initialData={config}>
            <FormHeader>
              <Title>Cadastro de Configuração</Title>
              <div>
                <Button type="button" onClick={handleClickBack}>
                  <MdArrowBack size={24} color={colors.iconLight} />
                  <span>Voltar</span>
                </Button>
                <Button
                  primary
                  type="submit"
                  disabled={
                    isEditing && !checkPermission(role, 'external_configs:edit')
                  }>
                  <MdDone size={24} color={colors.iconLight} />
                  <span>Salvar</span>
                </Button>
              </div>
            </FormHeader>

            <FieldGroup>
              <Field flex="none">
                <label htmlFor="name">Nome</label>
                <Input
                  name="name"
                  type="text"
                  maxLength="255"
                  autoFocus
                  autoComplete="off"
                />
              </Field>
              <Field flex="none">
                <label htmlFor="key">Chave</label>
                <Input
                  name="key"
                  type="text"
                  maxLength="255"
                  autoComplete="off"
                />
              </Field>
              {isEditing && (
                <Field flex="none">
                  <label htmlFor="active">Ativo</label>
                  <Check name="active" />
                </Field>
              )}
            </FieldGroup>
            <FieldGroup>
              <Field flex={1}>
                <label htmlFor="config">Configurações (Json)</label>
                <JSONInput
                  id="a_unique_id"
                  name="config"
                  placeholder={config && config.value}
                  theme="dark_vscode_tribute"
                  locale={locale}
                  height="750px"
                  width="100%"
                  style={{
                    body: { fontSize: 16 },
                    container: { borderRadius: 4 },
                  }}
                  onChange={handleChange}
                />
              </Field>
            </FieldGroup>
          </Form>
        </FormWrapper>
      </BaseContainer>
    </>
  );
}

ExternalConfigsForm.propTypes = {
  location: PropTypes.shape(),
};

ExternalConfigsForm.defaultProps = {
  location: null,
};

export default ExternalConfigsForm;
