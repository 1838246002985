import styled, { css } from 'styled-components';
import { darken } from 'polished';
import colors from '~/styles/colors';

export const Button = styled.button`
  /* background: ${(props) =>
    props.primary ? colors.primary : colors.color_2}; */
  color: ${colors.textLight};

  ${(props) => {
    if (props.primary) {
      return css`
        background: ${colors.primary}};
      `;
    }

    if (props.delete) {
      return css`
        background: ${colors.statusRedSoft}};
        color: ${colors.statusRedStrong};
      `;
    }

    return css`
        background: ${colors.color_2}};
      `;
  }}

  border: 0;
  /* border-width: thin; */
  padding: ${(props) => (props.padding ? props.padding : '6px 30px')};
  /* height: 36px; */
  border-radius: 4px;
  font-weight: bold;
  margin-left: 10px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

  svg {
    margin: ${(props) => (props.iconSpace ? props.iconSpace : '0 5px')};
  }

  transition: all 0.2s ease 0s;
  /* box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.1); */

  opacity: ${(props) => (props.disabled ? 0.5 : 1)};

  &:hover {
    ${(props) => {
      if (props.primary) {
        return css`
          background: ${(props) =>
            props.disabled ? colors.primary : darken(0.06, colors.primary)};
        `;
      }

      if (props.delete) {
        return css`
          background: ${(props) =>
            props.disabled
              ? colors.statusRedSoft
              : darken(0.06, colors.statusRedSoft)};
          color: ${colors.statusRedStrong};
        `;
      }

      return css`
        background: ${(props) =>
          props.disabled ? colors.color_2 : darken(0.06, colors.color_2)};
      `;
    }}
  }

  &:active {
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);

    ${(props) => {
      if (props.primary) {
        return css`
          background: ${(props) =>
            props.disabled ? colors.primary : darken(0.1, colors.primary)};
        `;
      }

      if (props.delete) {
        return css`
          background: ${(props) =>
            props.disabled
              ? colors.statusRedSoft
              : darken(0.1, colors.statusRedSoft)};
          color: ${colors.statusRedStrong};
        `;
      }

      return css`
        background: ${(props) =>
          props.disabled ? colors.color_2 : darken(0.1, colors.color_2)};
      `;
    }}
  }
`;
