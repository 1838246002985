import styled from 'styled-components';

export const List = styled.div`
  padding: 10px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
`;

export const Cell = styled.div`
  padding: 10px 10px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; // This is where the magic happens
`;
