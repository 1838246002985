function getErrorMessage(err) {
  if (!err) {
    return '';
  }

  if (err.response && err.response.data && err.response.data.errors) {
    return err.response.data.errors.toString();
  }

  if (
    err.response &&
    err.response.data &&
    err.response.data.error &&
    err.response.data.error.message
  ) {
    return err.response.data.error.message.toString();
  }

  if (err.response && err.response.data && err.response.data.error) {
    return err.response.data.error.toString();
  }

  if (
    err.response &&
    err.response.statusText &&
    err.response.statusText === 'Too Many Requests'
  ) {
    return err.response.data.toString();
  }

  if (err.message) {
    return err.message.toString();
  }

  return err.toString();
}

export default getErrorMessage;
