import React from 'react';
import logo from '~/assets/logo.png';

import { Container, ImgWrapper } from './styles';

function Logo() {
  return (
    <Container>
      <ImgWrapper>
        <img src={logo} alt="e-Get" />
      </ImgWrapper>
    </Container>
  );
}

export default Logo;
