import React from 'react';
import PropTypes from 'prop-types';
import allowedStatus from './status';

import { Container } from './styles';

function ContractStatus({ status }) {
  const displayStatus = allowedStatus[status];

  return (
    <Container status={status}>
      <span />
      {displayStatus}
    </Container>
  );
}

ContractStatus.propTypes = {
  status: PropTypes.string.isRequired,
};

export default ContractStatus;
