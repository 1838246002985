import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { parseISO, format, compareAsc } from 'date-fns';
import { MdFilterList, MdRefresh, MdSave } from 'react-icons/md';

import api from '~/services/api';
import history from '~/services/history';

import checkPermission from '~/lib/checkPermission';
import getErrorMessage from '~/lib/getErrorMessage';
import colors from '~/styles/colors';

import { Button } from '~/components/Buttons/Button';
import { BaseContainer } from '~/components/BaseContainer';
import { Table } from '~/components/Table';
import { Title } from '~/components/Title';
import { NoInfoToShow } from '~/components/NoInfoToShow';
import ColumnHeader from '~/components/ColumnHeader';
import Pagination from '~/components/Pagination';
import SearchInput from '~/components/Inputs/SearchInput';
import { FieldGroup, FormWrapper } from '~/components/Forms';
import Modal from '~/components/Modal';
import useModal from '~/components/Modal/useModal';

import ModalFilter from '../NpsSurveyModals/ModalFilter';
import ModalExport from '../NpsSurveyModals/ModalExport';

import NpsSurveyStatus from './NpsSurveyStatus';
import { Header, MsgContainer, ModalContainer } from './styles';

function NpsSurveys() {
  const role = useSelector((state) => state.role.role);

  const [filter, setFilter] = useState('');
  const [npsSurveys, setNpsSurveys] = useState([]);
  const time = useRef(null);
  const [totalPages, setTotalPages] = useState(1);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState({ field: 'created_at', asc: false });
  const [refresh, setRefresh] = useState(true);
  const { isShowing, toggle } = useModal();
  const [startDate, setStartDate] = useState(null);
  const [finalDate, setFinalDate] = useState(null);
  const [rating, setRating] = useState();
  const [message, setMessage] = useState(true);
  const [currentModal, setCurrentModal] = useState('');

  const npsRating = [
    { value: -1, label: 'Selecione uma nota' },
    { value: 0, label: '0' },
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: 5, label: '5' },
    { value: 6, label: '6' },
    { value: 7, label: '7' },
    { value: 8, label: '8' },
    { value: 9, label: '9' },
    { value: 10, label: '10' },
  ];

  const loadNpsSurveys = async (f) => {
    try {
      setLoading(true);
      const response = await api.get('nps-surveys', {
        params: {
          q: f,
          page,
          pageLimit,
          order: order.field,
          direction: order.asc ? 'ASC' : 'DESC',
          startDate,
          finalDate,
          message,
          rating: rating && Number(rating.value),
        },
      });

      const surveys = response.data.map((ex) => {
        return {
          ...ex,
          // createdAtFormattedd:
          //   ex.createdAt &&
          //   formatDistanceToNow(parseISO(ex.createdAt), {
          //     locale: pt,
          //     addSuffix: true,
          //   }),
          createdAtFormatted:
            ex.createdAt && format(parseISO(ex.createdAt), 'dd/MM/yyyy HH:mm'),
        };
      });

      setTotalPages(Number(response.headers['x-api-totalpages']));
      setTotal(Number(response.headers['x-api-total']));

      setNpsSurveys(surveys);
      setLoading(false);
    } catch (err) {
      toast.error(
        <div>
          Falha ao carregar dados! <br /> <br />
          {getErrorMessage(err)}
        </div>
      );
    }
    return true;
  };

  useEffect(() => {
    /**
     * Check permissions
     */
    if (role && !checkPermission(role, 'nps_surveys:visit')) {
      history.push('/denied');
      return;
    }

    clearTimeout(time.current);
    time.current = setTimeout(() => {
      loadNpsSurveys(filter);
    }, 600);
  }, [filter, page, pageLimit, order, role, refresh]);

  useEffect(() => {
    if (startDate || finalDate) {
      const startDateToCompare = startDate && new Date(`${startDate} 00:00:00`);
      const finalDateToCompare = finalDate && new Date(`${finalDate} 00:00:00`);

      if (compareAsc(startDateToCompare, finalDateToCompare) === 1) {
        toast.error(
          <div>
            Erro nos campos! <br /> <br />
            {getErrorMessage('As datas estão incorretas!')}
          </div>
        );
      }
    }
  }, [startDate, finalDate]);

  async function exportNps() {
    if (npsSurveys.length === 0) {
      // eslint-disable-next-line no-alert
      alert('Nenhum Nps para exportar!');
      return;
    }

    try {
      await api.get('nps-surveys/export', {
        params: {
          q: filter,
          startDate,
          finalDate,
          message,
          rating: rating && Number(rating.value),
        },
      });
      toast.success(
        <div>
          Pesquisa NPS enviada para seu E-mail!
          <br /> <br />
        </div>
      );
    } catch (err) {
      toast.error(
        <div>
          Falha ao exportar Pesquisas NPS! <br /> <br />
          {getErrorMessage('informe ao administrador do sistema!')}
        </div>
      );
    } finally {
      toggle();
    }
  }

  function handleFilterChange(value) {
    setPage(1);
    setFilter(value);
  }

  function handleChangeOrder(field) {
    setPage(1);
    setOrder({ field, asc: field === order.field ? !order.asc : true });
  }

  function handleRefresh() {
    setLoading(true);
    setRefresh(!refresh);
  }

  function reload() {
    setRefresh(!refresh);
  }

  function handleCloseImportModal() {
    toggle();
    reload();
  }

  const cleanFilters = () => {
    setStartDate(null);
    setFinalDate(null);
    setRating({ value: -1, label: 'Selecione uma nota' });
    setMessage(true);
  };

  return (
    <BaseContainer>
      <Title>Pesquisas NPS {total > 0 && `(${total})`}</Title>

      <Modal isShowing={isShowing} hide={handleCloseImportModal}>
        <ModalContainer>
          <FormWrapper>
            {currentModal === 'filters' ? (
              <ModalFilter
                loadNpsSurveys={loadNpsSurveys}
                handleCloseImportModal={handleCloseImportModal}
                cleanFilters={cleanFilters}
                startDate={startDate}
                finalDate={finalDate}
                setStartDate={setStartDate}
                setFinalDate={setFinalDate}
                npsRating={npsRating}
                setRating={setRating}
                rating={rating}
                message={message}
                setMessage={setMessage}
              />
            ) : (
              <ModalExport toggle={toggle} exportNps={exportNps} />
            )}
          </FormWrapper>
        </ModalContainer>
      </Modal>

      <Header>
        <SearchInput
          placeholder="Nome do Cliente ou Usuário"
          value={filter}
          onChange={(e) => handleFilterChange(e.target.value)}
        />
        <FieldGroup>
          <Button
            type="button"
            padding="6px"
            disabled={loading}
            onClick={handleRefresh}>
            <MdRefresh size={24} color={colors.iconLight} />
          </Button>
          <Button
            disabled={npsSurveys.length < 1}
            type="button"
            onClick={() => {
              toggle();
              setCurrentModal('confirmExport');
            }}>
            <MdSave size={24} color={colors.iconLight} />
            <span>Exportar</span>
          </Button>

          <Button
            type="button"
            onClick={() => {
              toggle();
              setCurrentModal('filters');
            }}>
            <MdFilterList size={24} color={colors.iconLight} />
            <span>Filtros</span>
          </Button>
        </FieldGroup>
      </Header>
      <Table>
        <thead>
          <tr>
            <th>
              <ColumnHeader
                label="Data"
                showOrder={order.field === 'created_at'}
                asc={order.asc}
                onClick={() => handleChangeOrder('created_at')}
              />
            </th>
            <th>Aplicação</th>
            <th>Cliente</th>
            <th>Usuário</th>
            <th>
              <ColumnHeader
                label="Nota"
                showOrder={order.field === 'value'}
                asc={order.asc}
                onClick={() => handleChangeOrder('value')}
              />
            </th>
            {message ? <th>Mensagem</th> : null}
          </tr>
        </thead>
        <tbody>
          {npsSurveys.map((item) => (
            <tr key={item.id}>
              <td>{item.createdAtFormatted}</td>
              <td>{item.application.name}</td>
              <td>{item.ext_user.contract.customer.name}</td>
              <td>{item.ext_user.name || item.ext_user.login}</td>
              <td>
                <NpsSurveyStatus nps_value={item.value} />
              </td>
              {message ? (
                <td>
                  <MsgContainer>{item.message}</MsgContainer>
                </td>
              ) : null}
            </tr>
          ))}
        </tbody>
      </Table>

      {!loading && npsSurveys.length === 0 ? (
        <NoInfoToShow>Nenhuma informação a exibir.</NoInfoToShow>
      ) : (
        <Pagination
          totalPages={totalPages}
          page={page}
          setPage={setPage}
          pageLimit={pageLimit}
          setPageLimit={setPageLimit}
        />
      )}
    </BaseContainer>
  );
}

export default NpsSurveys;
