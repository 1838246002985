import React, { useState, useEffect, useRef } from 'react';
import { MdRefresh } from 'react-icons/md';

import PropTypes from 'prop-types';

import api from '~/services/api';
import colors from '~/styles/colors';

// import Can from '~/components/Can';
import { BaseContainer } from '~/components/BaseContainer';
import { Table } from '~/components/Table';
import { Title } from '~/components/Title';
import { NoInfoToShow } from '~/components/NoInfoToShow';
import Pagination from '~/components/Pagination';
import { Button } from '~/components/Buttons/Button';
import ColumnHeader from '~/components/ColumnHeader';
import { FieldGroup, Field } from '~/components/Forms';
import SearchInput from '~/components/Inputs/SearchInput';

import { Header } from './styles';

function ContractVersions({ contract, maxWidthPercent }) {
  // const role = useSelector((state) => state.role.role);

  const [filter, setFilter] = useState('');
  const time = useRef(null);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [contractVersions, setContractVersions] = useState([]);
  const [order, setOrder] = useState({ field: 'name', asc: true });
  const [refresh, setRefresh] = useState(true);

  useEffect(() => {
    async function loadContractVerions(f) {
      if (contract) {
        setLoading(true);

        const response = await api.get(
          `contracts/${contract.id}/applications/versions`,
          {
            params: {
              q: f,
              page,
              pageLimit,
              order: order.field,
              direction: order.asc ? 'ASC' : 'DESC',
            },
          }
        );

        setTotalPages(Number(response.headers['x-api-totalpages']));
        setTotal(Number(response.headers['x-api-total']));
        setContractVersions(response.data);

        setLoading(false);
      }
    }

    clearTimeout(time.current);
    time.current = setTimeout(() => {
      loadContractVerions(filter);
    }, 600);
  }, [filter, page, pageLimit, order, contract, refresh]);

  function handleFilterChange(value) {
    setPage(1);
    setFilter(value);
  }

  function handleChangeOrder(field) {
    setPage(1);
    setOrder({ field, asc: field === order.field ? !order.asc : true });
  }

  function handleRefresh() {
    setLoading(true);
    setRefresh(!refresh);
  }

  return (
    <BaseContainer maxWidthPercent={maxWidthPercent}>
      <Title>Versões {total > 0 && `(${total})`}</Title>

      <Header>
        <SearchInput
          placeholder="aplicação"
          value={filter}
          onChange={(e) => handleFilterChange(e.target.value)}
        />

        <FieldGroup>
          <Field flex="none">
            <Button
              type="button"
              padding="6px"
              disabled={loading}
              onClick={handleRefresh}>
              <MdRefresh size={24} color={colors.iconLight} />
            </Button>
          </Field>
        </FieldGroup>
      </Header>
      <Table>
        <thead>
          <tr>
            <th>
              <ColumnHeader
                label="Aplicação"
                showOrder={order.field === 'name'}
                asc={order.asc}
                onClick={() => handleChangeOrder('name')}
              />
            </th>
            <th>
              <ColumnHeader
                label="Versão"
                showOrder={order.field === 'version'}
                asc={order.asc}
                onClick={() => handleChangeOrder('version')}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {contractVersions.map((item) => (
            <tr key={item.id}>
              <td>{item.name}</td>
              <td>{item.version}</td>
            </tr>
          ))}
        </tbody>
      </Table>

      {!loading && contractVersions.length === 0 ? (
        <NoInfoToShow>Nenhuma informação a exibir.</NoInfoToShow>
      ) : (
        <Pagination
          totalPages={totalPages}
          page={page}
          setPage={setPage}
          pageLimit={pageLimit}
          setPageLimit={setPageLimit}
        />
      )}
    </BaseContainer>
  );
}

ContractVersions.propTypes = {
  contract: PropTypes.shape({
    id: PropTypes.number.isRequired,
    contract: PropTypes.shape(),
  }),
  maxWidthPercent: PropTypes.number,
};

ContractVersions.defaultProps = {
  contract: null,
  maxWidthPercent: undefined,
};

export default ContractVersions;
