import React, { useState, useEffect } from 'react';
// import InputMask from 'react-input-mask';
import PropTypes from 'prop-types';
import { Form, Input } from '@rocketseat/unform';
import { MdDone, MdArrowBack } from 'react-icons/md';
import { toast } from 'react-toastify';
// import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { useSelector } from 'react-redux';

import api from '~/services/api';
import history from '~/services/history';
import colors from '~/styles/colors';

// import HistoryList from '~/components/HistoryList';
import { BaseContainer } from '~/components/BaseContainer';
import { FormHeader } from '~/components/FormHeader';
import { Button } from '~/components/Buttons/Button';
import { Title } from '~/components/Title';
import { FormWrapper, FieldGroup, Field } from '~/components/Forms';

import checkPermission from '~/lib/checkPermission';
import getErrorMessage from '~/lib/getErrorMessage';

// import { } from './styles';

function PartnerForm({ location }) {
  const [partner] = useState(location.partner);
  const isEditing = history.location.pathname === '/partners/edit';

  /**
   * Permissions
   */
  const role = useSelector((state) => state.role.role);
  const denied = !isEditing && !checkPermission(role, 'partners:create');

  useEffect(() => {
    /**
     * Redirect if permission denied
     */
    if (denied) {
      history.push('/denied');
      return;
    }

    /**
     * Go back on page refresh
     */
    if (isEditing && !partner) {
      history.goBack();
    }
  }, [partner, isEditing, denied]);

  /**
   * Form
   */
  function handleClickBack() {
    history.goBack();
  }

  async function save(name, cpf_cnpj) {
    const payload = { name, cpf_cnpj };

    try {
      if (partner) {
        await api.put(`partners/${partner.id}`, payload); // Edit
      } else {
        await api.post('partners', payload); // Create
      }

      toast.success(
        `Cadastro ${isEditing ? 'alterado' : 'realizado'} com sucesso!`
      );

      return history.push('/partners');
    } catch (err) {
      return toast.error(
        <div>
          Falha ao salvar! <br /> <br />
          {getErrorMessage(err)}
        </div>
      );
    }
  }

  function handleSubmit({ name, cpf_cnpj }) {
    if (!isEditing && !checkPermission(role, 'partners:create')) {
      return;
    }
    if (isEditing && !checkPermission(role, 'partners:edit')) {
      return;
    }

    save(name, cpf_cnpj);
  }

  return (
    <>
      <BaseContainer>
        <FormWrapper>
          <Form onSubmit={handleSubmit} initialData={partner}>
            <FormHeader>
              <Title>Cadastro de Parceiros</Title>
              <div>
                <Button type="button" onClick={handleClickBack}>
                  <MdArrowBack size={24} color={colors.iconLight} />
                  <span>Voltar</span>
                </Button>
                <Button
                  primary
                  type="submit"
                  disabled={
                    isEditing && !checkPermission(role, 'partners:edit')
                  }>
                  <MdDone size={24} color={colors.iconLight} />
                  <span>Salvar</span>
                </Button>
              </div>
            </FormHeader>

            <FieldGroup>
              <Field>
                <label htmlFor="name">Nome</label>
                <Input
                  name="name"
                  type="text"
                  maxLength="255"
                  autoFocus
                  autoComplete="off"
                />
              </Field>
              <Field>
                <label htmlFor="cpf_cnpj">CPF / CNPJ</label>
                <Input
                  name="cpf_cnpj"
                  type="text"
                  maxLength="20"
                  autoComplete="off"
                />
              </Field>
            </FieldGroup>
          </Form>
        </FormWrapper>

        {/* <Field>
          <Tabs>
            <TabList>
              <Tab>Histórico</Tab>
            </TabList>

            <TabPanel>
              {partner && (
                <HistoryList
                  id={partner.id}
                  route="partners"
                  maxWidthPercent={100}
                />
              )}
            </TabPanel>
          </Tabs>
        </Field> */}
      </BaseContainer>
    </>
  );
}

PartnerForm.propTypes = {
  location: PropTypes.shape(),
};

PartnerForm.defaultProps = {
  location: null,
};

export default PartnerForm;
