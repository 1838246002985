import styled from 'styled-components';
import colors from '~/styles/colors';

export const FormWrapper = styled.div`
  border: 1px solid ${colors.panelBorder};
  border-radius: 4px;

  input {
    border: 1px solid ${colors.inputBorder};
    padding: 10px;
    border-radius: 4px;
  }

  textArea {
    border: 1px solid ${colors.inputBorder};
    padding: 10px;
    border-radius: 4px;
  }

  label {
    padding: 5px 0;
    color: ${colors.textDark};
  }

  form {
    background: ${colors.panelBackground};
    border-radius: 4px;
    padding: 16px;
  }
`;
export const LabelCheckBox = styled.label`
  padding: 10px 10px 10px 10px;
  /* padding: 1px 0; */
  color: ${colors.textDark};
  /* margin-right: 20px; */
  /* margin-bottom: 20px; */
  flex: 1;
`;

export const FieldGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 6px;

  @media only screen and (max-width: 992px) {
    display: block;
  }
`;

export const Field = styled.div`
  /* background: lightsalmon; */
  display: flex;
  flex-direction: column;
  padding: 3px;
  margin-right: 6px;
  flex: ${(props) => (props.flex ? props.flex : '1')};

  > input:focus {
    box-shadow: 0 0 3px ${colors.primary};
    border: 1px solid ${colors.primary};
  }
`;

export const FieldCheckBox = styled.div`
  /* background: lightsalmon; */
  display: flex;
  flex-direction: row;
  padding: 3px;
  margin: 5px;
  flex: ${(props) => (props.flex ? props.flex : '1')};

  > input:focus {
    box-shadow: 0 0 3px ${colors.primary};
    border: 1px solid ${colors.primary};
  }
`;

export const FieldInlineWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
