import styled from 'styled-components';
import colors from '~/styles/colors';

export const LinkButton = styled.button`
  background: none;
  border: 0;
  color: ${colors.linkColor};
  /* display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center; */

  &:hover {
    text-decoration: underline;
  }
`;
