export function refreshRoleRequest(user) {
  return {
    type: '@role/REFRESH_ROLE_REQUEST',
    payload: { user },
  };
}

export function refreshRoleSuccess(role) {
  return {
    type: '@role/REFRESH_ROLE_SUCCESS',
    payload: { role },
  };
}
