import styled from 'styled-components';
import { darken } from 'polished';
import colors from '~/styles/colors';

export const Container = styled.div`
  background: ${colors.panelBackground};
  border-bottom: 1px solid ${colors.panelBorder};
`;

export const ContainerSandbox = styled.div`
  background: ${colors.statusRedSoft};
  padding: 7px;
  color: ${colors.statusRed};
  text-align: center;
  border-bottom: 1px solid ${colors.statusRed};
`;

export const Content = styled.div`
  height: 64px;
  max-width: 95%;
  margin: 0 auto;
  padding: 0 30px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  nav {
    display: flex;
    align-items: center;
  }

  aside {
    display: flex;
    align-items: center;
  }
`;

export const HeaderLink = styled.div`
  /* background: lightblue; */

  padding: 10px;
  font-weight: bold;
  color: ${(props) => (props.active ? colors.color_4 : colors.color_2)};
  cursor: default;

  a {
    font-weight: bold;
    color: ${(props) => (props.active ? colors.color_4 : colors.color_2)};
  }
`;

export const Profile = styled.div`
  display: flex;
  align-items: center;
  margin-left: 20px;
  padding-left: 20px;
  border-left: 1px solid ${colors.panelBorder};

  div {
    text-align: right;
    margin-right: 10px;

    strong {
      display: block;
      color: ${colors.textDarker};
    }

    a {
      display: block;
      margin-top: 2px;
      color: ${colors.color_2};
    }
  }

  img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }
`;

export const LogoWrapper = styled.div`
  border-right-width: 1px;
  border-right-color: ${colors.color_1};
  border-right-style: solid;
`;

export const VersionContainer = styled.div`
  margin-left: 20px;
  color: ${colors.color_1};
`;

/**
 * Menu
 */
// export const DropdownBtn = styled.button`
//   /* background: #04aa6d; */
//   /* color: white; */
//   /* padding: 16px; */
//   /* font-size: 16px; */
//   border: none;
// `;

export const DropdownContent = styled.div`
  background: ${colors.panelBackground};
  display: none;
  position: absolute;
  min-width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;

  div {
    padding: 20px;

    /* Change color of dropdown links on hover */
    &:hover {
      background: ${darken(0.03, colors.panelBackground)};
    }

  }

  a {
    /* background: lightblue ; */
    /* color: ${colors.textDark}; */
    /* padding: 30px 30px; */
    /* text-decoration: none; */
    /* display: block; */

  }
`;

export const DropdownMenu = styled.div`
  position: relative;
  display: inline-block;

  /* Show the dropdown menu on hover */
  &:hover ${DropdownContent} {
    display: block;
  }

  /* &:hover {
    background: ${darken(0.03, colors.panelBackground)};
  } */
`;
