import styled from 'styled-components';
import colors from '~/styles/colors';

export const Header = styled.header`
  margin-top: 30px;
  padding: 10px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: 1500px) {
    display: block;
  }
`;

export const FormContainer = styled.div`
  /* background: lightcoral; */
  min-width: 1024px;
  /* padding: 20px; */
`;

export const AppsContainer = styled.div`
  /* background: lightyellow; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const AppContainer = styled.div`
  background: ${colors.color_1};
  padding: 3px 8px;
  border-radius: 10px;
  margin: 2px;
`;
