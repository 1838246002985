import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Button } from './styles';

const ButtonGroup = ({ buttons, onClick, initValue }) => {
  // const [clickedId, setClickedId] = useState(-1);
  const [selectedValue, setSelectedValue] = useState(initValue);

  const handleClick = (event, value) => {
    setSelectedValue(value === selectedValue ? '' : value);
    onClick(event);
  };

  return (
    <Container>
      {buttons.map((btn) => (
        <Button
          key={btn.value}
          name={btn.label}
          value={btn.value}
          type="button"
          onClick={(event) => handleClick(event, btn.value)}
          active={btn.value === selectedValue}>
          {btn.label}
        </Button>
      ))}
    </Container>
  );
};

ButtonGroup.defaultProps = {
  initValue: '',
};

ButtonGroup.propTypes = {
  buttons: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.node,
  ]).isRequired,
  onClick: PropTypes.func.isRequired,
  initValue: PropTypes.string,
};

export default ButtonGroup;
