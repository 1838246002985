import React, { useState, useEffect, useRef } from 'react';
import {
  MdAdd,
  MdEdit,
  MdVisibility,
  MdDeleteForever,
  MdRefresh,
} from 'react-icons/md';
import { toast } from 'react-toastify';
import { format, parseISO } from 'date-fns';
import { useSelector } from 'react-redux';

import api from '~/services/api';
import history from '~/services/history';
import colors from '~/styles/colors';

import { FieldGroup } from '~/components/Forms';
import { Button } from '~/components/Buttons/Button';
import Can from '~/components/Can';
import { BaseContainer } from '~/components/BaseContainer';
import { Table } from '~/components/Table';
import { Title } from '~/components/Title';
import { NoInfoToShow } from '~/components/NoInfoToShow';
import ColumnHeader from '~/components/ColumnHeader';
import Actions from '~/components/Actions';
import Pagination from '~/components/Pagination';
import checkPermission from '~/lib/checkPermission';
import getErrorMessage from '~/lib/getErrorMessage';
import { LinkButton } from '~/components/Buttons/LinkButton';
import SearchInput from '~/components/Inputs/SearchInput';

import { Header } from './styles';
import UserStatus from './UserStatus';

function UserList() {
  const role = useSelector((state) => state.role.role);

  const [filter, setFilter] = useState('');
  const [users, setUsers] = useState([]);
  const time = useRef(null);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState({ field: 'name', asc: true });
  const [refresh, setRefresh] = useState(true);

  useEffect(() => {
    async function loadUsers(f) {
      try {
        setLoading(true);
        const response = await api.get('users', {
          params: {
            q: f,
            page,
            pageLimit,
            order: order.field,
            direction: order.asc ? 'ASC' : 'DESC',
          },
        });

        const data = response.data.map((u) => {
          return {
            ...u,
            roleFormatted: u.role.charAt(0).toUpperCase() + u.role.slice(1),
            last_session_formatted:
              u.last_session &&
              format(parseISO(u.last_session), 'dd/MM/yyyy HH:mm'),
          };
        });

        setTotalPages(Number(response.headers['x-api-totalpages']));
        setTotal(Number(response.headers['x-api-total']));
        setUsers(data);
        setLoading(false);
      } catch (err) {
        toast.error(
          <div>
            Falha ao carregar dados! <br /> <br />
            {getErrorMessage(err)}
          </div>
        );
      }
      return true;
    }

    /**
     * Check permissions
     */
    if (role && !checkPermission(role, 'users:visit')) {
      history.push('/denied');
      return;
    }

    clearTimeout(time.current);
    time.current = setTimeout(() => {
      loadUsers(filter);
    }, 600);
  }, [filter, page, pageLimit, order, role, refresh]);

  function handleFilterChange(value) {
    setPage(1);
    setFilter(value);
  }

  function handleCreate() {
    history.push('/users/create');
  }

  function reload() {
    setFilter(' ');
    setFilter('');
  }

  function handleEdit(id) {
    const userToEdit = users.find((d) => d.id === id);
    if (userToEdit) {
      history.push({
        pathname: '/users/edit',
        user: userToEdit,
      });
    }
  }

  async function handleDelete(id) {
    if (!window.confirm('Deseja mesmo inativar o usuário?')) {
      return;
    }

    try {
      await api.delete(`users/${id}`);
      toast.success('Usuário inativado com sucesso!');
      reload();
    } catch (err) {
      toast.error(
        <div>
          Falha ao inativar usuário! <br /> <br />
          {getErrorMessage(err)}
        </div>
      );
    }
  }

  function handleChangeOrder(field) {
    setPage(1);
    setOrder({ field, asc: field === order.field ? !order.asc : true });
  }

  function handleRefresh() {
    setLoading(true);
    setRefresh(!refresh);
  }

  return (
    <BaseContainer>
      <Title>Usuários {total > 0 && `(${total})`}</Title>

      <Header>
        <SearchInput
          placeholder="nome, e-mail, perfil"
          value={filter}
          onChange={(e) => handleFilterChange(e.target.value)}
        />

        <FieldGroup>
          <Button
            type="button"
            padding="6px"
            disabled={loading}
            onClick={handleRefresh}>
            <MdRefresh size={24} color={colors.iconLight} />
          </Button>

          <Can
            role={role}
            perform="users:create"
            yes={() => (
              <Button primary type="button" onClick={handleCreate}>
                <MdAdd size={24} color={colors.iconLight} />
                <span>Cadastrar</span>
              </Button>
            )}
          />
        </FieldGroup>
      </Header>
      <Table>
        <thead>
          <tr>
            <th>
              <ColumnHeader
                label="Nome"
                showOrder={order.field === 'name'}
                asc={order.asc}
                onClick={() => handleChangeOrder('name')}
              />
            </th>
            <th>
              <ColumnHeader
                label="E-Mail"
                showOrder={order.field === 'email'}
                asc={order.asc}
                onClick={() => handleChangeOrder('email')}
              />
            </th>
            <th>
              <ColumnHeader
                label="Perfil"
                showOrder={order.field === 'role'}
                asc={order.asc}
                onClick={() => handleChangeOrder('role')}
              />
            </th>
            <th>
              <ColumnHeader
                label="Status"
                showOrder={order.field === 'active'}
                asc={order.asc}
                onClick={() => handleChangeOrder('active')}
              />
            </th>
            <th>Último login</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr key={user.id}>
              {/* <td>
                <Avatar url={user.avatar && user.avatar.url} name={user.name} />
              </td> */}
              <td>{user.name}</td>
              <td>
                <Can
                  role={role}
                  perform="users:edit"
                  yes={() => (
                    <LinkButton onClick={() => handleEdit(user.id)}>
                      {user.email}
                    </LinkButton>
                  )}
                  no={() => <>{user.email}</>}
                />
              </td>
              <td>{user.roleFormatted}</td>
              <td>
                <UserStatus user={user} />
              </td>
              <td>{user.last_session_formatted || 'Nenhum login até agora'}</td>
              <td>
                <Actions>
                  <Can
                    role={role}
                    perform="users:edit"
                    yes={() => (
                      <li>
                        <MdEdit color="#4D85EE" size={16} />
                        <button
                          type="button"
                          onClick={() => handleEdit(user.id)}>
                          Editar
                        </button>
                      </li>
                    )}
                    no={() => (
                      <li>
                        <MdVisibility color="#4D85EE" size={16} />
                        <button
                          type="button"
                          onClick={() => handleEdit(user.id)}>
                          Visualizar
                        </button>
                      </li>
                    )}
                  />

                  <Can
                    role={role}
                    perform="users:delete"
                    yes={() => (
                      <li>
                        <MdDeleteForever color="#DE3B3B" size={16} />
                        <button
                          type="button"
                          onClick={() => handleDelete(user.id)}>
                          Inativar
                        </button>
                      </li>
                    )}
                    no={() => (
                      <li>
                        <MdDeleteForever
                          color={colors.textDisabled}
                          size={16}
                        />
                        <button type="button" disabled>
                          Inativar
                        </button>
                      </li>
                    )}
                  />
                </Actions>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {!loading && users.length === 0 ? (
        <NoInfoToShow>Nenhuma informação a exibir.</NoInfoToShow>
      ) : (
        <Pagination
          totalPages={totalPages}
          page={page}
          setPage={setPage}
          pageLimit={pageLimit}
          setPageLimit={setPageLimit}
        />
      )}
    </BaseContainer>
  );
}

export default UserList;
