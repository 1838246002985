import checkPermission from '../../lib/checkPermission';

const Can = (props) =>
  checkPermission(props.role, props.perform, props.data)
    ? props.yes()
    : props.no();

Can.defaultProps = {
  yes: () => null,
  no: () => null,
};

export default Can;
