import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { parseISO } from 'date-fns';
import { Form, Input, Check } from '@rocketseat/unform';
import { MdDone, MdArrowBack } from 'react-icons/md';
import { toast } from 'react-toastify';
import 'react-tabs/style/react-tabs.css';
import { useSelector } from 'react-redux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/pt';

import api from '~/services/api';
import history from '~/services/history';
import colors from '~/styles/colors';

import getErrorMessage from '~/lib/getErrorMessage';
import checkPermission from '~/lib/checkPermission';

import { BaseContainer } from '~/components/BaseContainer';
import { FormHeader } from '~/components/FormHeader';
import { Button } from '~/components/Buttons/Button';
import { Title } from '~/components/Title';
import ImageInput from '~/components/Inputs/ImageInput';
import {
  FormWrapper,
  FieldGroup,
  Field,
  LabelCheckBox,
} from '~/components/Forms';

import CommunicationsApplicationsList from '~/pages/CommunicationsApplications/CommunicationsApplicationsList';

function CommunicationForm({ location }) {
  const [communication] = useState(location.communication);
  const [extraFields, setExtraFields] = useState(
    communication ? communication.extra_fields : null
  );

  const isEditing = history.location.pathname === '/communications/edit';
  const { v4: uuidv4 } = require('uuid');

  /**
   * Atenção!
   * Estados da imagem controlados separadamente.
   * As propriedades "communication.image_id" e "communication.image" não serão alteradas!
   */
  const [imageId, setImageId] = useState(
    communication && communication.image_id
  );
  const [imageUrl, setImageUrl] = useState(
    communication &&
      communication.image &&
      communication.image.url &&
      `${communication.image.url}?${Math.random()}`
  );

  /**
   * Permissions
   */
  const role = useSelector((state) => state.role.role);
  const denied = !isEditing && !checkPermission(role, 'communications:create');

  useEffect(() => {
    /**
     * Redirect if permission denied
     */
    if (denied) {
      history.push('/denied');
      return;
    }

    /**
     * Go back on page refresh
     */
    if (isEditing && !communication) {
      history.goBack();
    }
  }, [communication, isEditing, denied]);

  /**
   * Form
   */
  function handleClickBack() {
    history.goBack();
  }

  async function save({
    name,
    initial_date,
    final_date,
    title,
    contents,
    image_url,
    cta,
    url_cta,
    active,
    extra_fields,
  }) {
    try {
      if (communication) {
        await api.put(`communications/${communication.id}`, {
          name,
          title,
          contents,
          image_url,
          initial_date: parseISO(initial_date) || null,
          final_date: parseISO(final_date) || null,
          cta,
          url_cta,
          extra_fields,
          active,
        }); // Edit
      } else {
        await api.post('communications', {
          name,
          title,
          contents,
          image_url,
          initial_date: parseISO(initial_date) || null,
          final_date: parseISO(final_date) || null,
          cta,
          url_cta,
          active,
          extra_fields,
          external_id: uuidv4(),
        }); // Create
      }

      toast.success(
        `Comunicado ${isEditing ? 'alterado' : 'cadastrado'} com sucesso!`
      );

      return history.push('/communications');
    } catch (err) {
      return toast.error(
        <div>
          Falha ao salvar! <br /> <br />
          {getErrorMessage(err)}
        </div>
      );
    }
  }

  function handleSubmit({
    name,
    initialDateEn: initial_date,
    finalDateEn: final_date,
    title,
    contents,
    image_url,
    cta,
    url_cta,
    active,
  }) {
    if (!isEditing && !checkPermission(role, 'communications:create')) {
      return;
    }
    if (isEditing && !checkPermission(role, 'communications:edit')) {
      return;
    }

    save({
      name,
      initial_date,
      final_date,
      title,
      contents,
      image_url,
      cta,
      url_cta,
      active,
      extra_fields: extraFields || null,
    });
  }

  function handleChange({ jsObject }) {
    setExtraFields(jsObject);
  }

  async function handleImageRemove() {
    if (!window.confirm('Deseja mesmo remover a imagem?')) {
      return false;
    }

    try {
      if (!communication) return false;

      await api.put(`communications/${communication.id}`, { image_id: null });

      // O ideal seria recarregar o communicado inteiro
      setImageId(undefined);
      setImageUrl(undefined);

      return toast.success(`Imagem removida com sucesso!`);
    } catch (err) {
      return toast.error(
        <div>
          Falha ao remover imagem! <br /> <br />
          {getErrorMessage(err)}
        </div>
      );
    }
  }

  async function handleImageChange(data) {
    if (!isEditing) {
      throw new Error('Cadastre o comunicado antes de definrir a imagem.');
    }

    if (!communication) {
      throw new Error('Comunicado não definido.');
    }

    if (imageId) {
      const response = await api.put(`files/${imageId}`, data); // Update
      const { url } = response.data;
      setImageUrl(`${url}?${Math.random()}`);
    } else {
      const response = await api.post('files/communications', data); // Insert
      const { id, url } = response.data;
      setImageId(id);
      setImageUrl(`${url}?${Math.random()}`);
      await api.put(`communications/${communication.id}`, { image_id: id });
    }

    return true;
  }

  return (
    <>
      <BaseContainer>
        <FormWrapper>
          <Form onSubmit={handleSubmit} initialData={communication}>
            <fieldset>
              <FormHeader>
                <Title>Cadastro de Comunicados</Title>
                <div>
                  <Button type="button" onClick={handleClickBack}>
                    <MdArrowBack size={24} color={colors.iconLight} />
                    <span>Voltar</span>
                  </Button>
                  <Button
                    primary
                    type="submit"
                    disabled={
                      isEditing && !checkPermission(role, 'communications:edit')
                    }>
                    <MdDone size={24} color={colors.iconLight} />
                    <span>Salvar</span>
                  </Button>
                </div>
              </FormHeader>

              <FieldGroup>
                <Field flex="0.415">
                  <label htmlFor="name">Nome</label>
                  <Input
                    name="name"
                    type="text"
                    maxLength="100"
                    autoFocus
                    autoComplete="off"
                  />
                </Field>

                <Field flex="none">
                  <LabelCheckBox htmlFor="active">Ativo</LabelCheckBox>
                  {isEditing ? (
                    <Check name="active" />
                  ) : (
                    <Check name="active" disabled />
                  )}
                </Field>
              </FieldGroup>

              <FieldGroup>
                <Field flex="none">
                  <label htmlFor="initialDateEn">Data Inicial</label>
                  <Input
                    name="initialDateEn"
                    type="date"
                    autoComplete="off"
                    required
                  />
                </Field>

                <Field flex="none">
                  <label htmlFor="finalDateEn">Data Final</label>
                  <Input
                    name="finalDateEn"
                    type="date"
                    autoComplete="off"
                    required
                  />
                </Field>
              </FieldGroup>

              <FieldGroup>
                <Field flex="0.4">
                  <label htmlFor="title">Título</label>
                  <Input
                    name="title"
                    type="text"
                    maxLength="100"
                    autoComplete="off"
                  />
                </Field>
              </FieldGroup>

              <FieldGroup>
                <Field flex="0.4">
                  <label htmlFor="contents">Conteúdo</label>
                  <Input
                    multiline
                    name="contents"
                    type="text"
                    maxLength="500"
                    autoComplete="off"
                    rows={5}
                  />
                </Field>
              </FieldGroup>

              <FieldGroup>
                <Field flex="none">
                  <label htmlFor="avatar_id">Imagem</label>
                  <ImageInput
                    // disabled={!isEditing}
                    url={imageUrl}
                    onChange={handleImageChange}
                  />
                </Field>
                <Field flex="none">
                  <Button
                    type="button"
                    disabled={!isEditing || !imageId}
                    onClick={handleImageRemove}>
                    Remover imagem
                  </Button>
                </Field>
              </FieldGroup>

              <FieldGroup>
                <Field>
                  <Tabs>
                    <TabList>
                      <Tab>CTA</Tab>
                      <Tab>Campos Extras</Tab>
                    </TabList>
                    <TabPanel>
                      <FieldGroup>
                        <Field flex="0.4">
                          <label htmlFor="cta">Nome do CTA</label>
                          <Input
                            name="cta"
                            type="text"
                            maxLength="100"
                            // value={ctaName || ''}
                            // onChange={(e) => setCtaName(e.target.value)}
                            autoComplete="off"
                            placeholder="Call to action"
                          />
                        </Field>
                      </FieldGroup>

                      <FieldGroup>
                        <Field flex="1">
                          <label htmlFor="url_cta">URL do CTA</label>
                          <Input
                            name="url_cta"
                            type="text"
                            maxLength="500"
                            autoComplete="off"
                            // value={urlCta || ''}
                            // onChange={(e) => setUrlCta(e.target.value)}
                          />
                        </Field>
                      </FieldGroup>
                    </TabPanel>

                    <TabPanel>
                      <FieldGroup>
                        <Field flex={1}>
                          <label htmlFor="extra_fields">Campos Extras</label>
                          <JSONInput
                            id="a_unique_id"
                            name="extra_fields"
                            placeholder={
                              communication && communication.extra_fields
                            }
                            theme="dark_vscode_tribute"
                            locale={locale}
                            height="750px"
                            width="100%"
                            value={extraFields || ''}
                            style={{
                              body: { fontSize: 16 },
                              container: { borderRadius: 4 },
                            }}
                            onChange={handleChange}
                          />
                        </Field>
                      </FieldGroup>
                    </TabPanel>
                  </Tabs>
                </Field>
              </FieldGroup>
            </fieldset>
          </Form>
        </FormWrapper>
        <Field>
          <Tabs>
            <TabList>
              <Tab>Aplicações</Tab>
            </TabList>
            <TabPanel>
              {communication && (
                <CommunicationsApplicationsList
                  communication={communication}
                  maxWidthPercent={100}
                />
              )}
            </TabPanel>
          </Tabs>
        </Field>
      </BaseContainer>
    </>
  );
}

CommunicationForm.propTypes = {
  location: PropTypes.shape(),
};

CommunicationForm.defaultProps = {
  location: null,
};

export default CommunicationForm;
