import styled from 'styled-components';

export const Header = styled.header`
  margin-top: 30px;
  padding: 10px 0;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const MsgContainer = styled.div`
  max-width: fit-content;
  text-align: justify;
  padding: 10px;
  font-size: smaller;
`;

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
`;
