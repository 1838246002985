import React, { useState, useEffect, useRef } from 'react';
import {
  MdAdd,
  MdEdit,
  MdVisibility,
  MdDeleteForever,
  MdRefresh,
} from 'react-icons/md';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import api from '~/services/api';
import history from '~/services/history';
import colors from '~/styles/colors';

import { FieldGroup } from '~/components/Forms';
import { Button } from '~/components/Buttons/Button';
import { LinkButton } from '~/components/Buttons/LinkButton';
import Can from '~/components/Can';
import { BaseContainer } from '~/components/BaseContainer';
import { Table } from '~/components/Table';
import { Title } from '~/components/Title';
import { NoInfoToShow } from '~/components/NoInfoToShow';
import ColumnHeader from '~/components/ColumnHeader';
import Actions from '~/components/Actions';
import Pagination from '~/components/Pagination';
import checkPermission from '~/lib/checkPermission';
import getErrorMessage from '~/lib/getErrorMessage';
import SearchInput from '~/components/Inputs/SearchInput';
import ExternalConfigsStatus from './ExternalConfigsStatus';

import { Header } from './styles';

function ExternalConfigs() {
  const role = useSelector((state) => state.role.role);

  const [filter, setFilter] = useState('');
  const [configs, setConfigs] = useState([]);
  const time = useRef(null);
  const [totalPages, setTotalPages] = useState(1);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState({ field: 'key', asc: true });
  const [refresh, setRefresh] = useState(true);

  useEffect(() => {
    async function loadExternalConfigs(f) {
      try {
        setLoading(true);
        const response = await api.get('external-configs', {
          params: {
            q: f,
            page,
            pageLimit,
            order: order.field,
            direction: order.asc ? 'ASC' : 'DESC',
          },
        });

        const data = response.data.map((c) => {
          return {
            ...c,
            parsedValue: JSON.stringify(c.value),
          };
        });

        setTotalPages(Number(response.headers['x-api-totalpages']));
        setTotal(Number(response.headers['x-api-total']));
        setConfigs(data);
        setLoading(false);
      } catch (err) {
        toast.error(
          <div>
            Falha ao carregar dados! <br /> <br />
            {getErrorMessage(err)}
          </div>
        );
      }
      return true;
    }

    /**
     * Check permissions
     */
    if (role && !checkPermission(role, 'external_configs:visit')) {
      history.push('/denied');
      return;
    }

    clearTimeout(time.current);
    time.current = setTimeout(() => {
      loadExternalConfigs(filter);
    }, 600);
  }, [filter, page, pageLimit, order, role, refresh]);

  function handleFilterChange(value) {
    setPage(1);
    setFilter(value);
  }

  function reload() {
    setFilter(' ');
    setFilter('');
  }

  function handleCreate() {
    history.push('/external-configs/create');
  }

  function handleEdit(id) {
    const configToEdit = configs.find((d) => d.id === id);
    if (configToEdit) {
      history.push({
        pathname: '/external-configs/edit',
        config: configToEdit,
      });
    }
  }

  async function handleDelete(id) {
    if (!window.confirm('Deseja mesmo inativar a configuração?')) {
      return;
    }

    try {
      await api.delete(`external-configs/${id}`);
      toast.success('Configuração inativada com sucesso!');
      reload();
    } catch (err) {
      toast.error(
        <div>
          Falha ao inativar configuração! <br /> <br />
          {getErrorMessage(err)}
        </div>
      );
    }
  }

  function handleChangeOrder(field) {
    setPage(1);
    setOrder({ field, asc: field === order.field ? !order.asc : true });
  }

  function handleRefresh() {
    setLoading(true);
    setRefresh(!refresh);
  }

  return (
    <BaseContainer>
      <Title>Configurações {total > 0 && `(${total})`}</Title>

      <Header>
        <SearchInput
          placeholder="chave"
          value={filter}
          onChange={(e) => handleFilterChange(e.target.value)}
        />

        <FieldGroup>
          <Button
            type="button"
            padding="6px"
            disabled={loading}
            onClick={handleRefresh}>
            <MdRefresh size={24} color={colors.iconLight} />
          </Button>

          <Can
            role={role}
            perform="external_configs:create"
            yes={() => (
              <Button primary type="button" onClick={handleCreate}>
                <MdAdd size={24} color={colors.iconLight} />
                <span>Cadastrar</span>
              </Button>
            )}
          />
        </FieldGroup>
      </Header>
      <Table>
        <thead>
          <tr>
            <th>
              <ColumnHeader
                label="Nome"
                showOrder={order.field === 'name'}
                asc={order.asc}
                onClick={() => handleChangeOrder('name')}
              />
            </th>
            <th>
              <ColumnHeader
                label="Chave"
                showOrder={order.field === 'key'}
                asc={order.asc}
                onClick={() => handleChangeOrder('key')}
              />
            </th>
            <th>
              <ColumnHeader
                label="Status"
                showOrder={order.field === 'active'}
                asc={order.asc}
                onClick={() => handleChangeOrder('active')}
              />
            </th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {configs.map((item) => (
            <tr key={item.id}>
              <td>
                <Can
                  role={role}
                  perform="external_configs:edit"
                  yes={() => (
                    <LinkButton onClick={() => handleEdit(item.id)}>
                      {item.name}
                    </LinkButton>
                  )}
                  no={() => <>{item.name}</>}
                />
              </td>
              <td>{item.key}</td>
              <td>
                <ExternalConfigsStatus config={item} />
              </td>
              <td>
                <Actions height={90} width={150}>
                  <Can
                    role={role}
                    perform="external_configs:edit"
                    yes={() => (
                      <li>
                        <MdEdit color="#4D85EE" size={16} />
                        <button
                          type="button"
                          onClick={() => handleEdit(item.id)}>
                          Editar
                        </button>
                      </li>
                    )}
                    no={() => (
                      <li>
                        <MdVisibility color="#4D85EE" size={16} />
                        <button
                          type="button"
                          onClick={() => handleEdit(item.id)}>
                          Visualizar
                        </button>
                      </li>
                    )}
                  />

                  <Can
                    role={role}
                    perform="external_configs:delete"
                    yes={() => (
                      <li>
                        <MdDeleteForever color="#DE3B3B" size={16} />
                        <button
                          type="button"
                          onClick={() => handleDelete(item.id)}>
                          Inativar
                        </button>
                      </li>
                    )}
                    no={() => (
                      <li>
                        <MdDeleteForever
                          color={colors.textDisabled}
                          size={16}
                        />
                        <button type="button" disabled>
                          Inativar
                        </button>
                      </li>
                    )}
                  />
                </Actions>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {!loading && configs.length === 0 ? (
        <NoInfoToShow>Nenhuma informação a exibir.</NoInfoToShow>
      ) : (
        <Pagination
          totalPages={totalPages}
          page={page}
          setPage={setPage}
          pageLimit={pageLimit}
          setPageLimit={setPageLimit}
        />
      )}
    </BaseContainer>
  );
}

export default ExternalConfigs;
