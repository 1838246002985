import styled from 'styled-components';
import colors from '~/styles/colors';

export const Container = styled.div`
  position: relative;
  display: inline-block;
`;

export const DropdownContent = styled.ul`
  margin-top: -10px;
  width: max-content;
  border: 1px solid rgba(0, 0, 0, 0.1);
  position: absolute;
  right: ${(props) => (props.dropAlign === 'right' ? 0 : 'unset')};
  background: ${colors.iconLight};
  box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.1);
  z-index: 51; // para sobrepor o botão de ações de cada linha
  border-radius: 4px;

  // Animação
  transition: all 0.2s ease;
  visibility: ${(props) => (props.showMenu ? 'visible' : 'hidden')};
  opacity: ${(props) => (props.showMenu ? 1 : 0)};
  transform: ${(props) => (props.showMenu ? 'translateY(15px)' : 'none')};

  button {
    background: none;
    border: 0;
    padding: 15px;
    flex: 1;
    text-align: left;
  }

  li {
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 0 0 0 15px;

    svg {
      margin-right: 5px;
    }
  }

  li + li {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }

  li:hover {
    background: ${colors.color_1};
  }
`;

export const TextWrapper = styled.span`
  /* background: lightcyan; */
  /* padding-left: 8px;
  border-left: 1px solid rgba(255, 255, 255, 1); */
`;
