import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { MdMoreHoriz } from 'react-icons/md';

import colors from '~/styles/colors';
import {
  Container,
  ActionsButton,
  ActionsContainer,
  ActionsList,
} from './styles';

/**
 * Hook that alerts clicks outside of the passed ref
 */

function Actions({ children }) {
  const [visible, setVisible] = useState(false);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setVisible(false);
        }
      }

      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
    <Container>
      <ActionsButton onClick={() => setVisible(!visible)}>
        <MdMoreHoriz color={colors.textDark} size={25} />
      </ActionsButton>

      <ActionsContainer ref={wrapperRef} visible={visible}>
        <ActionsList>{children}</ActionsList>
      </ActionsContainer>
    </Container>
  );
}

Actions.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default Actions;
