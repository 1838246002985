import React, { useState, useEffect } from 'react';
// import InputMask from 'react-input-mask';
import PropTypes from 'prop-types';
import { Form, Input } from '@rocketseat/unform';
import { MdDone, MdArrowBack } from 'react-icons/md';
import { toast } from 'react-toastify';
import 'react-tabs/style/react-tabs.css';
import { useSelector } from 'react-redux';
import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/pt';

import api from '~/services/api';
import history from '~/services/history';
import colors from '~/styles/colors';

import { BaseContainer } from '~/components/BaseContainer';
import { FormHeader } from '~/components/FormHeader';
import { Button } from '~/components/Buttons/Button';
import { Title } from '~/components/Title';
import { FormWrapper, FieldGroup, Field } from '~/components/Forms';

import checkPermission from '~/lib/checkPermission';
import getErrorMessage from '~/lib/getErrorMessage';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import ModuleContractsList from './ModuleContractsList';

// import { } from './styles';

function ModuleForm({ location }) {
  const [module] = useState(location.module);
  const isEditing = history.location.pathname === '/modules/edit';
  const [extraFields, setExtraFields] = useState(
    module ? module.extra_fields : null
  );

  /**
   * Permissions
   */
  const role = useSelector((state) => state.role.role);
  const denied = !isEditing && !checkPermission(role, 'modules:create');

  useEffect(() => {
    /**
     * Redirect if permission denied
     */
    if (denied) {
      history.push('/denied');
      return;
    }

    /**
     * Go back on page refresh
     */
    if (isEditing && !module) {
      history.goBack();
    }
  }, [module, isEditing, denied]);

  /**
   * Form
   */
  const handleClickBack = () => {
    history.goBack();
  };

  async function save(name) {
    const payload = { name, extra_fields: extraFields || null };

    try {
      if (module) {
        await api.put(`modules/${module.id}`, payload); // Edit
      } else {
        await api.post('modules', payload); // Create
      }

      toast.success(
        `Cadastro ${isEditing ? 'alterado' : 'realizado'} com sucesso!`
      );

      return history.push('/modules');
    } catch (err) {
      return toast.error(
        <div>
          Falha ao salvar! <br /> <br />
          {getErrorMessage(err)}
        </div>
      );
    }
  }

  const handleSubmit = ({ name, keys }) => {
    if (!isEditing && !checkPermission(role, 'modules:create')) {
      return;
    }
    if (isEditing && !checkPermission(role, 'modules:edit')) {
      return;
    }

    save(name, keys);
  };

  const handleChangeExtraFields = ({ jsObject }) => {
    setExtraFields(jsObject);
  };

  return (
    <>
      <BaseContainer>
        <FormWrapper>
          <Form onSubmit={handleSubmit} initialData={module}>
            <FormHeader>
              <Title>Cadastro de Módulo</Title>
              <div>
                <Button type="button" onClick={handleClickBack}>
                  <MdArrowBack size={24} color={colors.iconLight} />
                  <span>Voltar</span>
                </Button>
                <Button
                  primary
                  type="submit"
                  disabled={
                    isEditing && !checkPermission(role, 'modules:edit')
                  }>
                  <MdDone size={24} color={colors.iconLight} />
                  <span>Salvar</span>
                </Button>
              </div>
            </FormHeader>

            <FieldGroup>
              <Field flex="none">
                <label htmlFor="name">Nome</label>
                <Input
                  name="name"
                  type="text"
                  maxLength="255"
                  autoFocus
                  autoComplete="off"
                />
              </Field>
            </FieldGroup>

            <FieldGroup>
              <Field flex={1}>
                <label htmlFor="extra_fields">Campos Extras (Json)</label>
                <JSONInput
                  id="a_unique_id"
                  name="extra_fields"
                  placeholder={extraFields}
                  theme="dark_vscode_tribute"
                  locale={locale}
                  height="150px"
                  width="100%"
                  style={{
                    body: { fontSize: 16 },
                    container: { borderRadius: 4 },
                  }}
                  onChange={handleChangeExtraFields}
                />
              </Field>
            </FieldGroup>
          </Form>
        </FormWrapper>

        <Field>
          <Tabs>
            <TabList>
              <Tab>Contratos</Tab>
            </TabList>

            <TabPanel>
              {module && (
                <ModuleContractsList module={module} maxWidthPercent={100} />
              )}
            </TabPanel>
          </Tabs>
        </Field>
      </BaseContainer>
    </>
  );
}

ModuleForm.propTypes = {
  location: PropTypes.shape(),
};

ModuleForm.defaultProps = {
  location: null,
};

export default ModuleForm;
