import React from 'react';
import { MdSentimentVeryDissatisfied } from 'react-icons/md';

import { BaseContainer } from '~/components/BaseContainer';

import colors from '~/styles/colors';
import { Container, Title, Text } from './styles';

function Denied() {
  return (
    <BaseContainer>
      <Container>
        <Title>Acesso negado</Title>
        <MdSentimentVeryDissatisfied color={colors.color_1} size={200} />
        <Text>Você não tem permissão para acessar o recurso solicitado.</Text>
      </Container>
    </BaseContainer>
  );
}

export default Denied;
