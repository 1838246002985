import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from '@rocketseat/unform';
import { MdDone, MdArrowBack } from 'react-icons/md';
import { toast } from 'react-toastify';
import 'react-tabs/style/react-tabs.css';
import { useSelector } from 'react-redux';
import AsyncSelect from 'react-select/async';

import api from '~/services/api';
import colors from '~/styles/colors';

import { BaseContainer } from '~/components/BaseContainer';
import { FormHeader } from '~/components/FormHeader';
import { Button } from '~/components/Buttons/Button';
import { Title } from '~/components/Title';
import { FormWrapper, FieldGroup, Field } from '~/components/Forms';

import checkPermission from '~/lib/checkPermission';
import getErrorMessage from '~/lib/getErrorMessage';

// import { } from './styles';

function ContractIntegrationForm({ integration, contract_id, hide }) {
  const role = useSelector((state) => state.role.role);
  const selectInputRef = useRef();
  const partnerTimer = useRef(null);
  const isEditing = Boolean(integration);

  const [selectedPartner, setSelectedPartner] = useState(
    integration && integration.partner
  );

  /**
   * AsyncSelect
   */
  async function loadPartners(q) {
    const response = await api.get('partners', { params: { q } });

    const partners = response.data.map((item) => {
      return { value: item.id, label: item.name };
    });

    return partners;
  }

  const loadPartnerOptions = (inputValue, callback) => {
    clearTimeout(partnerTimer.current);
    partnerTimer.current = setTimeout(async () => {
      callback(await loadPartners(inputValue));
    }, 600);
  };

  function handleChangePartner(selectedOptions) {
    setSelectedPartner(selectedOptions || []);
    // setSelectedPartner({
    //   id: selectedOptions.value,
    //   name: selectedOptions.label,
    // });
  }

  function clearSelectedPartner() {
    if (selectInputRef && selectInputRef.current) {
      selectInputRef.current.select.select.clearValue();
    }
  }

  /**
   * Form
   */

  function handleClose() {
    clearSelectedPartner();
    return hide && hide();
  }

  async function save({ name }) {
    const payload = {
      partner_id: selectedPartner && selectedPartner.value,
      name,
    };

    try {
      if (isEditing) {
        await api.put(
          `contracts/${contract_id}/integrations/${integration.id}`,
          payload
        ); // Edit
      } else {
        await api.post(`contracts/${contract_id}/integrations`, payload); // Create
      }

      toast.success(
        `Cadastro ${isEditing ? 'alterado' : 'realizado'} com sucesso!`
      );

      return handleClose();
    } catch (err) {
      return toast.error(
        <div>
          Falha ao salvar a integração! <br /> <br />
          {getErrorMessage(err)}
        </div>
      );
    }
  }

  function handleSubmit(data) {
    if (!isEditing && !checkPermission(role, 'contracts:edit')) {
      return;
    }
    if (isEditing && !checkPermission(role, 'contracts:edit')) {
      return;
    }

    save(data);
  }

  return (
    <BaseContainer>
      <FormWrapper>
        <Form onSubmit={handleSubmit} initialData={integration}>
          <FormHeader>
            <Title>Cadastro de Integração</Title>
            <div>
              <Button type="button" onClick={handleClose}>
                <MdArrowBack size={24} color={colors.iconLight} />
                <span>Voltar</span>
              </Button>
              <Button
                primary
                type="submit"
                disabled={
                  isEditing && !checkPermission(role, 'contracts:edit')
                }>
                <MdDone size={24} color={colors.iconLight} />
                <span>Salvar</span>
              </Button>
            </div>
          </FormHeader>

          <FieldGroup>
            <Field flex={3} style={{ minWidth: 300 }}>
              <label htmlFor="partner">Parceiro</label>
              {isEditing ? (
                <Input
                  name="partner_disabled"
                  type="text"
                  maxLength="255"
                  autoComplete="off"
                  disabled
                  value={selectedPartner && selectedPartner.name}
                />
              ) : (
                <AsyncSelect
                  defaultValue={
                    selectedPartner && {
                      value: selectedPartner.id,
                      label: selectedPartner.name,
                    }
                  }
                  ref={selectInputRef}
                  // isMulti
                  closeMenuOnSelect
                  name="partner"
                  placeholder="Selecione o parceiro..."
                  cacheOptions
                  loadOptions={loadPartnerOptions}
                  defaultOptions
                  onChange={handleChangePartner}
                  isClearable="true"
                  autoFocus
                />
              )}
            </Field>
          </FieldGroup>
          <FieldGroup>
            <Field flex={1}>
              <label htmlFor="name">Nome</label>
              <Input
                name="name"
                type="text"
                maxLength="255"
                autoComplete="off"
                autoFocus={isEditing}
              />
            </Field>
          </FieldGroup>
        </Form>
      </FormWrapper>
    </BaseContainer>
  );
}

ContractIntegrationForm.propTypes = {
  integration: PropTypes.shape(),
  contract_id: PropTypes.number,
  hide: PropTypes.func,
};

ContractIntegrationForm.defaultProps = {
  integration: null,
  contract_id: null,
  hide: null,
};

export default ContractIntegrationForm;
