import React, { useState, useEffect, useRef } from 'react';
import {
  MdAdd,
  MdDeleteForever,
  MdRefresh,
  MdFileUpload,
} from 'react-icons/md';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { format, parseISO } from 'date-fns';
import { useSelector } from 'react-redux';
import AsyncSelect from 'react-select/async';

import api from '~/services/api';
import colors from '~/styles/colors';

import Card from '~/components/Card';
import Can from '~/components/Can';
import { BaseContainer } from '~/components/BaseContainer';
import { Table } from '~/components/Table';
import { Title } from '~/components/Title';
import { NoInfoToShow } from '~/components/NoInfoToShow';
import Actions from '~/components/Actions';
import Pagination from '~/components/Pagination';
import { Button } from '~/components/Buttons/Button';
import SearchInput from '~/components/Inputs/SearchInput';
import ColumnHeader from '~/components/ColumnHeader';
import getErrorMessage from '~/lib/getErrorMessage';
import { FieldGroup, Field } from '~/components/Forms';
import ImportCSVModal from '~/components/ImportCSVModal';

import { Header } from './styles';

function ReleaseGroupContractList({ releaseGroup, maxWidthPercent }) {
  const role = useSelector((state) => state.role.role);
  const selectInputRef = useRef();

  const [filter, setFilter] = useState('');
  const time = useRef(null);
  const timerContract = useRef(null);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [releaseGroupContracts, setReleaseGroupContracts] = useState([]);
  const [order, setOrder] = useState({
    field: 'contract.description',
    asc: true,
  });
  const [selectedContracts, setSelectedContracts] = useState([]);
  const [refresh, setRefresh] = useState(true);
  const [isShowingCSVModal, setIsShowingCSVModal] = useState(false);

  useEffect(() => {
    async function loadReleaseGroupContracts(f) {
      if (releaseGroup) {
        setLoading(true);

        const response = await api.get(
          `release-groups/${releaseGroup.id}/contracts`,
          {
            params: {
              q: f,
              page,
              pageLimit,
              order: order.field,
              direction: order.asc ? 'ASC' : 'DESC',
            },
          }
        );

        const data = response.data.map((item) => {
          return {
            ...item,
            createdAtFormatted:
              item.createdAt &&
              format(parseISO(item.createdAt), 'dd/MM/yyyy HH:mm:ss'),
          };
        });

        setTotalPages(Number(response.headers['x-api-totalpages']));
        setTotal(Number(response.headers['x-api-total']));
        setReleaseGroupContracts(data);

        setLoading(false);
      }
    }

    clearTimeout(time.current);
    time.current = setTimeout(() => {
      loadReleaseGroupContracts(filter);
    }, 600);
  }, [filter, page, pageLimit, order, releaseGroup, refresh]);

  function handleFilterChange(value) {
    setPage(1);
    setFilter(value);
  }

  function reload() {
    setFilter(' ');
    setFilter('');
  }

  function clearSelectedContracts() {
    selectInputRef.current.select.select.clearValue();
  }

  async function addContractToReleaseGroup(contract_id) {
    try {
      if (!releaseGroup) {
        return;
      }

      await api.post(`release-groups/${releaseGroup.id}/contracts`, {
        contract_id,
      });

      toast.success('Contrato adicionado com sucesso!');
      reload();
    } catch (err) {
      toast.error(
        <div>
          Falha ao salvar! <br /> <br />
          {getErrorMessage(err)}
        </div>
      );
    }
  }

  function handleCreate() {
    if (selectedContracts.length > 0) {
      selectedContracts.forEach((c) => {
        addContractToReleaseGroup(c.value);
      });
      clearSelectedContracts();
    }
  }

  async function handleDelete(contract) {
    if (
      !window.confirm(
        contract
          ? 'Deseja mesmo remover o contrato?'
          : 'Deseja mesmo remover todos os contratos?'
      )
    ) {
      return;
    }

    try {
      await api.delete(
        contract
          ? `release-groups/${releaseGroup.id}/contracts/${contract.id}`
          : `release-groups/${releaseGroup.id}/contracts`
      );
      toast.success(
        contract
          ? 'Contrato removido com sucesso!'
          : 'Todos os contratos foram desvinculados com sucesso!'
      );
      reload();
    } catch (err) {
      toast.error(
        <div>
          Falha ao remover contrato! <br /> <br />
          {getErrorMessage(err)}
        </div>
      );
    }
  }

  function handleChangeOrder(field) {
    setPage(1);
    setOrder({ field, asc: field === order.field ? !order.asc : true });
  }

  /**
   * Async Select (Contracts)
   */

  async function loadContracts(q) {
    const response = await api.get('contracts', {
      params: { q, status: 'active' },
    });

    const contracts = response.data.map((item) => {
      return { value: item.id, label: item.description };
    });

    return contracts;
  }

  const loadContractOptions = (inputValue, callback) => {
    clearTimeout(timerContract.current);
    timerContract.current = setTimeout(async () => {
      callback(await loadContracts(inputValue));
    }, 600);
  };

  function handleChangeContract(selectedOptions) {
    setSelectedContracts(selectedOptions || []);
  }

  function handleRefresh() {
    setLoading(true);
    setRefresh(!refresh);
  }

  function onCloseCSVModal() {
    setIsShowingCSVModal(false);
    handleRefresh();
  }

  return (
    <BaseContainer maxWidthPercent={maxWidthPercent}>
      <Title>Contratos vinculados {total > 0 && `(${total})`}</Title>

      <ImportCSVModal
        isShowing={isShowingCSVModal}
        onClose={onCloseCSVModal}
        route={`release-groups/${releaseGroup.id}/contracts/import`}
        csvHeaders="contract_token,description"
      />

      <Header>
        <SearchInput
          placeholder="contrato"
          value={filter}
          onChange={(e) => handleFilterChange(e.target.value)}
        />

        <FieldGroup>
          <Field flex="none">
            <Button
              type="button"
              padding="6px"
              disabled={loading}
              onClick={handleRefresh}>
              <MdRefresh size={24} color={colors.iconLight} />
            </Button>
          </Field>

          <Can
            role={role}
            perform="release-groups:edit"
            yes={() => (
              <Field style={{ minWidth: 300 }}>
                <AsyncSelect
                  ref={selectInputRef}
                  isMulti
                  closeMenuOnSelect={false}
                  name="contracts"
                  placeholder="Selecione contratos..."
                  cacheOptions
                  loadOptions={loadContractOptions}
                  defaultOptions
                  onChange={handleChangeContract}
                  isClearable="true"
                />
              </Field>
            )}
          />

          <Can
            role={role}
            perform="release-groups:edit"
            yes={() => (
              <Button
                primary
                type="button"
                padding="6px"
                onClick={handleCreate}
                disabled={selectedContracts.length === 0}>
                <MdAdd size={24} color={colors.iconLight} />
              </Button>
            )}
          />

          <Can
            role={role}
            perform="release-groups:edit"
            yes={() => (
              <Card dropAlign="right">
                <li>
                  <MdFileUpload size={20} color={colors.color_2} />
                  <button
                    type="button"
                    onClick={() => setIsShowingCSVModal(true)}>
                    Importar arquivo .CSV
                  </button>
                </li>
                <li>
                  <MdDeleteForever size={20} color={colors.textDark} />
                  <button
                    type="button"
                    onClick={() => handleDelete()}
                    disabled={
                      !releaseGroupContracts ||
                      releaseGroupContracts.length === 0
                    }>
                    Remover todos
                  </button>
                </li>
              </Card>
            )}
          />
        </FieldGroup>
      </Header>
      <Table>
        <thead>
          <tr>
            <th>Cliente</th>
            <th>
              <ColumnHeader
                label="Contrato"
                showOrder={order.field === 'contract.description'}
                asc={order.asc}
                onClick={() => handleChangeOrder('contract.description')}
              />
            </th>

            <th>
              <ColumnHeader
                label="Criado em"
                showOrder={order.field === 'created_at'}
                asc={order.asc}
                onClick={() => handleChangeOrder('created_at')}
              />
            </th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {releaseGroupContracts.map((item) => (
            <tr key={item.id}>
              <td>{item.contract.customer.name}</td>
              <td>{item.contract.description}</td>
              <td>{item.createdAtFormatted}</td>
              <td>
                <Actions height={90} width={150}>
                  {/* <li>
                    <MdEdit color="#4D85EE" size={16} />
                    <button type="button" onClick={() => handleEdit(item.id)}>
                      Editar
                    </button>
                  </li> */}
                  <Can
                    role={role}
                    perform="release-groups:delete"
                    yes={() => (
                      <li>
                        <MdDeleteForever color="#DE3B3B" size={16} />
                        <button
                          type="button"
                          onClick={() => handleDelete(item.contract)}>
                          Remover
                        </button>
                      </li>
                    )}
                    no={() => (
                      <li>
                        <MdDeleteForever
                          color={colors.textDisabled}
                          size={16}
                        />
                        <button type="button" disabled>
                          Remover
                        </button>
                      </li>
                    )}
                  />
                </Actions>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {!loading && releaseGroupContracts.length === 0 ? (
        <NoInfoToShow>Nenhuma informação a exibir.</NoInfoToShow>
      ) : (
        <Pagination
          totalPages={totalPages}
          page={page}
          setPage={setPage}
          pageLimit={pageLimit}
          setPageLimit={setPageLimit}
        />
      )}
    </BaseContainer>
  );
}

ReleaseGroupContractList.propTypes = {
  releaseGroup: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
  maxWidthPercent: PropTypes.number,
};

ReleaseGroupContractList.defaultProps = {
  releaseGroup: null,
  maxWidthPercent: undefined,
};

export default ReleaseGroupContractList;
