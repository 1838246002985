import React, { useEffect, useRef, useState } from 'react';
import { MdRefresh } from 'react-icons/md';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import LoaderSpinner from 'react-loader-spinner';
import ReactTooltip from 'react-tooltip';
import { formatDistanceToNowStrict, parseISO } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';

import api from '~/services/api';
import getErrorMessage from '~/lib/getErrorMessage';
import colors from '~/styles/colors';
import { InvisibleButton } from '~/components/Buttons/InvisibleButton';

import { Container, UsersCount, ButtonContainer } from './styles';

function ContractCompanyCount({ contract_id }) {
  const [count, setCount] = useState(0);
  const [updatedAt, setUpdatedAt] = useState();
  const [loading, setLoading] = useState(true);
  const time = useRef(null);
  const [refreshCount, setRefreshCount] = useState(0);

  useEffect(() => {
    if (!contract_id) return;

    async function fetchData() {
      try {
        setLoading(true);
        const response = await api.get(`contracts/${contract_id}`);
        const { company_count, company_count_updated_at } = response.data;
        setCount(company_count);

        if (company_count_updated_at) {
          setUpdatedAt(
            formatDistanceToNowStrict(parseISO(company_count_updated_at), {
              locale: pt,
              addSuffix: true,
            })
          );
        }

        setLoading(false);
      } catch (err) {
        toast.error(
          <div>
            Falha ao carregar empresas ativas! <br /> <br />
            {getErrorMessage(err)}
          </div>
        );
      }
      return true;
    }

    clearTimeout(time.current);
    time.current = setTimeout(() => {
      fetchData();
    }, 1000);
  }, [contract_id, refreshCount]);

  const handleRefresh = () => {
    setLoading(true);
    setRefreshCount((prev) => prev + 1);
  };

  if (!contract_id) return null;

  return (
    <Container>
      <UsersCount data-tip data-for="company_count_data">
        {count ? `${count} ativa${count > 1 ? 's' : ''}` : '-'}
      </UsersCount>
      {count ? (
        <ReactTooltip
          id="company_count_data"
          textColor={colors.textLight}
          backgroundColor={colors.color_4}>
          <div style={{ padding: 8 }}>
            Atualizado automaticamente {updatedAt}
          </div>
        </ReactTooltip>
      ) : null}

      <ButtonContainer>
        {loading ? (
          <LoaderSpinner
            type="Oval"
            color={colors.color_3}
            height={20}
            width={20}
          />
        ) : (
          <InvisibleButton
            type="button"
            onClick={handleRefresh}
            disabled={!!loading}>
            <MdRefresh size={24} color={colors.color_2} />
          </InvisibleButton>
        )}
      </ButtonContainer>
    </Container>
  );
}

ContractCompanyCount.propTypes = {
  contract_id: PropTypes.number.isRequired,
};

export default ContractCompanyCount;
