import styled from 'styled-components';
import colors from '~/styles/colors';

export const Container = styled.div`
  padding: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
`;

export const InfoContainer = styled.div`
  padding: 30px;
`;

export const MainInfo = styled.div`
  text-align: center;
  padding: 15px;
  font-size: 40px;
  font-weight: bold;
  color: ${(props) => props.color || colors.color_4};
`;

export const Legend = styled.div`
  text-align: center;
  padding: 2px 15px;
  font-size: 16px;
  color: ${colors.color_2};
`;
