import styled from 'styled-components';

export const Header = styled.header`
  margin-top: 30px;
  padding: 10px 0;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const VerticalContainer = styled.div`
  padding: 4px;
`;

export const HorizontalContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const Field = styled.span`
  padding: 4px;
  text-align: right;
  flex: 1;
`;
export const Value = styled.span`
  padding: 4px;
  text-align: left;
  flex: 3;
`;
