import React from 'react';
import { useSelector } from 'react-redux';
import { BaseContainer } from '~/components/BaseContainer';
import { FieldGroup, Field } from '~/components/Forms';

import checkPermission from '~/lib/checkPermission';

import DashboardPanel from '~/components/DashboardPanel';
import LastExceptions from './LastExceptions';
import LastNpsSurveys from './LastNpsSurveys';
import Generic from './Generic';
import colors from '../../styles/colors';

// import { } from './styles';

function Dashboard() {
  const role = useSelector((state) => state.role.role);
  const showContractsFinance = checkPermission(role, 'contracts/finance:visit');
  const showLicenses = checkPermission(role, 'licenses:visit');
  const showExceptions = checkPermission(role, 'exceptions:visit');

  return (
    <BaseContainer>
      <FieldGroup>
        <Field flex="1">
          <DashboardPanel title="Contratos">
            <Generic
              keyValue="1"
              params={[
                {
                  key: 1,
                  route: 'dashboard',
                  queryParams: [{ name: 'q', value: 'active-contracts' }],
                  legend: 'ativos',
                  valueColor: colors.statusGreenStrong,
                },
                {
                  key: 2,
                  route: 'dashboard',
                  queryParams: [{ name: 'q', value: 'contracts-in-test' }],
                  legend: 'em teste',
                  valueColor: colors.statusYellowStrong,
                },
                {
                  key: 3,
                  route: 'dashboard',
                  queryParams: [{ name: 'q', value: 'suspended-contracts' }],
                  legend: 'suspensos',
                  valueColor: colors.statusBlueStrong,
                },
                // {
                //   key: 4,
                //   route: 'dashboard',
                //   queryParams: [{ name: 'q', value: 'cancelled-contracts' }],
                //   legend: 'cancelados',
                //   valueColor: colors.statusRedStrong,
                // },
              ]}
            />
          </DashboardPanel>
        </Field>

        {showContractsFinance && (
          <Field flex="1">
            <DashboardPanel title="Bloqueios Financeiros">
              <Generic
                keyValue="2"
                params={[
                  {
                    key: 1,
                    route: 'dashboard',
                    queryParams: [
                      { name: 'q', value: 'contracts-with-block-date' },
                    ],
                    legend: 'agendados',
                  },
                  {
                    key: 2,
                    route: 'dashboard',
                    queryParams: [{ name: 'q', value: 'blocked-contracts' }],
                    legend: 'bloqueados',
                  },
                ]}
              />
            </DashboardPanel>
          </Field>
        )}
      </FieldGroup>

      <FieldGroup>
        {showLicenses && (
          <Field flex="1">
            <DashboardPanel title="Usuários ativos hoje">
              <Generic
                keyValue="3"
                params={[
                  {
                    route: 'dashboard',
                    queryParams: [{ name: 'q', value: 'today-access' }],
                    legend: 'usuários',
                  },
                ]}
              />
            </DashboardPanel>
          </Field>
        )}

        {showExceptions && (
          <Field flex="1">
            <DashboardPanel title="Exceptions por dia">
              <Generic
                keyValue="4"
                params={[
                  {
                    route: 'dashboard',
                    queryParams: [{ name: 'q', value: 'avg-day-exceptions' }],
                    legend: 'exceptions / dia',
                  },
                ]}
              />
            </DashboardPanel>
          </Field>
        )}

        <Field flex="1">
          <DashboardPanel title="NPS">
            <Generic
              keyValue="5"
              params={[
                {
                  key: 1,
                  route: 'nps',
                  propName: 'nps',
                  // queryParams: [
                  //   { name: 'start_date', value: '2021-06-01' },
                  //   { name: 'end_date', value: '2021-06-03' },
                  // ],
                  legend: 'score',
                },
                {
                  key: 2,
                  route: 'nps',
                  propName: 'total',
                  // queryParams: [
                  //   { name: 'start_date', value: '2021-06-01' },
                  //   { name: 'end_date', value: '2021-06-03' },
                  // ],
                  legend: 'respostas',
                },
              ]}
            />
          </DashboardPanel>
        </Field>
      </FieldGroup>

      <FieldGroup>
        <Field flex="1">
          <DashboardPanel title="Últimas pesquisas NPS">
            <LastNpsSurveys maxWidthPercent={100} />
          </DashboardPanel>
        </Field>
      </FieldGroup>

      {showExceptions && (
        <FieldGroup>
          <Field flex="1">
            <DashboardPanel title="Exceptions de Hoje">
              <LastExceptions maxWidthPercent={100} />
            </DashboardPanel>
          </Field>
        </FieldGroup>
      )}
    </BaseContainer>
  );
}

export default Dashboard;
