import styled from 'styled-components';

export const Container = styled.div`
  padding: 6px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const UsersCount = styled.div`
  padding: 0 16px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: 30px;
  min-height: 26px;
`;
