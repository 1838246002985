import React, { useState, useEffect } from 'react';
// import InputMask from 'react-input-mask';
import PropTypes from 'prop-types';
import { Form, Input, Check } from '@rocketseat/unform';
import { MdDone, MdArrowBack } from 'react-icons/md';
import { toast } from 'react-toastify';
import 'react-tabs/style/react-tabs.css';
import { useSelector } from 'react-redux';

import api from '~/services/api';
import history from '~/services/history';
import colors from '~/styles/colors';

import { BaseContainer } from '~/components/BaseContainer';
import { FormHeader } from '~/components/FormHeader';
import { Button } from '~/components/Buttons/Button';
import { Title } from '~/components/Title';
import { FormWrapper, FieldGroup, Field } from '~/components/Forms';

import checkPermission from '~/lib/checkPermission';
import getErrorMessage from '~/lib/getErrorMessage';

// import { } from './styles';

function ApplicationForm({ location }) {
  const [application] = useState(location.application);
  const isEditing = history.location.pathname === '/applications/edit';

  /**
   * Permissions
   */
  const role = useSelector((state) => state.role.role);
  const denied = !isEditing && !checkPermission(role, 'applications:create');

  useEffect(() => {
    /**
     * Redirect if permission denied
     */
    if (denied) {
      history.push('/denied');
      return;
    }

    /**
     * Go back on page refresh
     */
    if (isEditing && !application) {
      history.goBack();
    }
  }, [application, isEditing, denied]);

  /**
   * Form
   */
  function handleClickBack() {
    history.goBack();
  }

  async function save(name, short_name, application_token, active) {
    const payload = { name, short_name, application_token, active };

    try {
      if (application) {
        await api.put(`applications/${application.id}`, payload); // Edit
      } else {
        await api.post('applications', payload); // Create
      }

      toast.success(
        `Cadastro ${isEditing ? 'alterado' : 'realizado'} com sucesso!`
      );

      return history.push('/applications');
    } catch (err) {
      return toast.error(
        <div>
          Falha ao salvar! <br /> <br />
          {getErrorMessage(err)}
        </div>
      );
    }
  }

  function handleSubmit({ name, short_name, application_token, active }) {
    if (!isEditing && !checkPermission(role, 'applications:create')) {
      return;
    }
    if (isEditing && !checkPermission(role, 'applications:edit')) {
      return;
    }

    save(name, short_name, application_token, active);
  }

  return (
    <>
      <BaseContainer>
        <FormWrapper>
          <Form onSubmit={handleSubmit} initialData={application}>
            <FormHeader>
              <Title>Cadastro de Aplicação</Title>
              <div>
                <Button type="button" onClick={handleClickBack}>
                  <MdArrowBack size={24} color={colors.iconLight} />
                  <span>Voltar</span>
                </Button>
                <Button
                  primary
                  type="submit"
                  disabled={
                    isEditing && !checkPermission(role, 'applications:edit')
                  }>
                  <MdDone size={24} color={colors.iconLight} />
                  <span>Salvar</span>
                </Button>
              </div>
            </FormHeader>

            <FieldGroup>
              <Field flex="none">
                <label htmlFor="name">Nome</label>
                <Input
                  name="name"
                  type="text"
                  maxLength="255"
                  autoFocus
                  autoComplete="off"
                />
              </Field>
              {isEditing && (
                <Field flex="none">
                  <label htmlFor="active">Ativo</label>
                  <Check name="active" />
                </Field>
              )}
            </FieldGroup>
            <FieldGroup>
              <Field flex="none">
                <label htmlFor="short_name">Sigla</label>
                <Input
                  name="short_name"
                  type="text"
                  maxLength="10"
                  autoComplete="off"
                />
              </Field>
            </FieldGroup>
            <FieldGroup>
              <Field flex="1">
                <label htmlFor="application_token">Application Token</label>
                <Input
                  name="application_token"
                  type="text"
                  maxLength="255"
                  autoComplete="off"
                  placeholder="Deixe em branco para gerar automáticamente"
                />
              </Field>
            </FieldGroup>
          </Form>
        </FormWrapper>
      </BaseContainer>
    </>
  );
}

ApplicationForm.propTypes = {
  location: PropTypes.shape(),
};

ApplicationForm.defaultProps = {
  location: null,
};

export default ApplicationForm;
