import React, { useState, useRef } from 'react';
// import InputMask from 'react-input-mask';
import PropTypes from 'prop-types';
import { Form, Input } from '@rocketseat/unform';
import { MdDone, MdArrowBack } from 'react-icons/md';
import { toast } from 'react-toastify';
import 'react-tabs/style/react-tabs.css';
import { useSelector } from 'react-redux';
import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/pt';
import AsyncSelect from 'react-select/async';

import api from '~/services/api';
import colors from '~/styles/colors';

import { BaseContainer } from '~/components/BaseContainer';
import { FormHeader } from '~/components/FormHeader';
import { Button } from '~/components/Buttons/Button';
import { Title } from '~/components/Title';
import { FormWrapper, FieldGroup, Field } from '~/components/Forms';

import checkPermission from '~/lib/checkPermission';
import getErrorMessage from '~/lib/getErrorMessage';

// import { } from './styles';

function DatabaseForm({ database, contract_id, hide }) {
  const role = useSelector((state) => state.role.role);
  const selectInputRef = useRef();
  const timerApplication = useRef(null);
  const isEditing = Boolean(database);
  const [extraFields, setExtraFields] = useState(
    database ? database.extra_fields : null
  );

  const getSelectedApps = () => {
    const applications =
      database && database.database_applications
        ? database.database_applications.map((dbApp) => dbApp.application)
        : [];

    // Converte para o formato do Async Select
    const selectedAppsTemp = [];
    if (applications.length > 0) {
      applications.forEach((app) => {
        selectedAppsTemp.push({ value: app.id, label: app.name });
      });
    }

    return selectedAppsTemp;
  };

  const [selectedApps, setSelectedApps] = useState(getSelectedApps());

  function handleChangeExtraFields({ jsObject }) {
    setExtraFields(jsObject);
  }

  /**
   * AsyncSelect
   */
  async function loadApplications(q) {
    const response = await api.get('applications', { params: { q } });

    const applications = response.data.map((item) => {
      return { value: item.id, label: item.name };
    });

    return applications;
  }

  const loadApplicationOptions = (inputValue, callback) => {
    clearTimeout(timerApplication.current);
    timerApplication.current = setTimeout(async () => {
      callback(await loadApplications(inputValue));
    }, 600);
  };

  function handleChangeApplication(selectedOptions) {
    setSelectedApps(selectedOptions || []);
  }

  function clearSelectedApps() {
    selectInputRef.current.select.select.clearValue();
  }

  /**
   * Form
   */

  function handleClose() {
    clearSelectedApps();
    return hide && hide();
  }

  async function save({
    path_db,
    remote_db,
    username_db,
    password_db,
    key,
    server_info,
  }) {
    const payload = {
      path_db,
      remote_db,
      username_db,
      password_db,
      key,
      extra_fields: extraFields || null,
      application_ids: selectedApps && selectedApps.map((app) => app.value),
      server_info,
    };

    try {
      if (isEditing) {
        await api.put(
          `contracts/${contract_id}/databases/${database.id}`,
          payload
        ); // Edit
      } else {
        await api.post(`contracts/${contract_id}/databases`, payload); // Create
      }

      toast.success(
        `Cadastro ${isEditing ? 'alterado' : 'realizado'} com sucesso!`
      );

      return handleClose();
    } catch (err) {
      return toast.error(
        <div>
          Falha ao salvar database! <br /> <br />
          {getErrorMessage(err)}
        </div>
      );
    }
  }

  function handleSubmit(data) {
    if (!isEditing && !checkPermission(role, 'contracts:edit')) {
      return;
    }
    if (isEditing && !checkPermission(role, 'contracts:edit')) {
      return;
    }

    save(data);
  }

  return (
    <BaseContainer>
      <FormWrapper>
        <Form onSubmit={handleSubmit} initialData={database}>
          <FormHeader>
            <Title>Cadastro de Database</Title>
            <div>
              <Button type="button" onClick={handleClose}>
                <MdArrowBack size={24} color={colors.iconLight} />
                <span>Voltar</span>
              </Button>
              <Button
                primary
                type="submit"
                disabled={
                  isEditing && !checkPermission(role, 'contracts:edit')
                }>
                <MdDone size={24} color={colors.iconLight} />
                <span>Salvar</span>
              </Button>
            </div>
          </FormHeader>

          <FieldGroup>
            <Field flex={3} style={{ minWidth: 300 }}>
              <label htmlFor="applications">Aplicações</label>
              <AsyncSelect
                defaultValue={selectedApps}
                ref={selectInputRef}
                isMulti
                closeMenuOnSelect
                name="applications"
                placeholder="Selecione aplicações..."
                cacheOptions
                loadOptions={loadApplicationOptions}
                defaultOptions
                onChange={handleChangeApplication}
                isClearable="true"
                autoFocus
              />
            </Field>
            <Field flex={1}>
              <label htmlFor="key">Chave</label>
              <Input
                name="key"
                type="text"
                maxLength="255"
                autoComplete="off"
              />
            </Field>
          </FieldGroup>

          <FieldGroup>
            <Field>
              <label htmlFor="path_db">Path DB</label>
              <Input
                name="path_db"
                type="text"
                maxLength="500"
                autoComplete="off"
              />
            </Field>
          </FieldGroup>

          <FieldGroup>
            <Field>
              <label htmlFor="remote_db">Remote DB</label>
              <Input
                name="remote_db"
                type="text"
                maxLength="500"
                autoComplete="off"
              />
            </Field>
          </FieldGroup>

          <FieldGroup>
            <Field>
              <label htmlFor="username_db">Username DB</label>
              <Input
                name="username_db"
                type="text"
                maxLength="100"
                autoComplete="off"
              />
            </Field>
            <Field>
              <label htmlFor="password_db">Password DB</label>
              <Input
                name="password_db"
                type="text"
                maxLength="100"
                autoComplete="off"
              />
            </Field>
          </FieldGroup>

          <FieldGroup>
            <Field>
              <label htmlFor="server_info">Servidor</label>
              <Input
                name="server_info"
                type="text"
                maxLength="255"
                autoComplete="off"
              />
            </Field>
          </FieldGroup>

          <FieldGroup>
            <Field flex={1}>
              <label htmlFor="extra_fields">Campos Extras (Json)</label>
              <JSONInput
                id="a_unique_id"
                name="extra_fields"
                placeholder={extraFields}
                theme="dark_vscode_tribute"
                locale={locale}
                height="150px"
                width="100%"
                style={{
                  body: { fontSize: 16 },
                  container: { borderRadius: 4 },
                }}
                onChange={handleChangeExtraFields}
              />
            </Field>
          </FieldGroup>
        </Form>
      </FormWrapper>
    </BaseContainer>
  );
}

DatabaseForm.propTypes = {
  database: PropTypes.shape(),
  contract_id: PropTypes.number,
  hide: PropTypes.func,
};

DatabaseForm.defaultProps = {
  // location: null,
  database: null,
  contract_id: null,
  hide: null,
};

export default DatabaseForm;
