import React, { useState, useEffect, useRef } from 'react';
import { MdAdd, MdEdit, MdVisibility, MdRefresh } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import api from '~/services/api';
import history from '~/services/history';
import colors from '~/styles/colors';

import { FieldGroup } from '~/components/Forms';
import { Button } from '~/components/Buttons/Button';
import Can from '~/components/Can';
import { BaseContainer } from '~/components/BaseContainer';
import { Table } from '~/components/Table';
import { Title } from '~/components/Title';
import { NoInfoToShow } from '~/components/NoInfoToShow';
import ColumnHeader from '~/components/ColumnHeader';
import Actions from '~/components/Actions';
import Pagination from '~/components/Pagination';
import checkPermission from '~/lib/checkPermission';
import getErrorMessage from '~/lib/getErrorMessage';
import { LinkButton } from '~/components/Buttons/LinkButton';
import SearchInput from '~/components/Inputs/SearchInput';

import { Header } from './styles';

function PartnerList() {
  const role = useSelector((state) => state.role.role);

  const [filter, setFilter] = useState('');
  const [partners, setPartners] = useState([]);
  const time = useRef(null);
  const [totalPages, setTotalPages] = useState(1);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState({ field: 'name', asc: true });
  const [refresh, setRefresh] = useState(true);

  useEffect(() => {
    async function loadData(f) {
      try {
        setLoading(true);
        const response = await api.get('partners', {
          params: {
            q: f,
            page,
            pageLimit,
            order: order.field,
            direction: order.asc ? 'ASC' : 'DESC',
          },
        });

        setTotalPages(Number(response.headers['x-api-totalpages']));
        setTotal(Number(response.headers['x-api-total']));
        setPartners(response.data);
        setLoading(false);
      } catch (err) {
        toast.error(
          <div>
            Falha ao carregar dados! <br /> <br />
            {getErrorMessage(err)}
          </div>
        );
      }
      return true;
    }

    /**
     * Check permissions
     */
    if (role && !checkPermission(role, 'partners:visit')) {
      history.push('/denied');
      return;
    }

    clearTimeout(time.current);
    time.current = setTimeout(() => {
      loadData(filter);
    }, 600);
  }, [filter, page, pageLimit, order, role, refresh]);

  function handleFilterChange(value) {
    setPage(1);
    setFilter(value);
  }

  function handleCreate() {
    history.push('/partners/create');
  }

  function handleEdit(id) {
    const partnerToEdit = partners.find((d) => d.id === id);
    if (partnerToEdit) {
      history.push({
        pathname: '/partners/edit',
        partner: partnerToEdit,
      });
    }
  }

  function handleChangeOrder(field) {
    setPage(1);
    setOrder({ field, asc: field === order.field ? !order.asc : true });
  }

  function handleRefresh() {
    setLoading(true);
    setRefresh(!refresh);
  }

  return (
    <BaseContainer>
      <Title>Parceiros {total > 0 && `(${total})`}</Title>

      <Header>
        <SearchInput
          placeholder="nome, cpf, cnpj"
          value={filter}
          onChange={(e) => handleFilterChange(e.target.value)}
        />

        <FieldGroup>
          <Button
            type="button"
            padding="6px"
            disabled={loading}
            onClick={handleRefresh}>
            <MdRefresh size={24} color={colors.iconLight} />
          </Button>

          <Can
            role={role}
            perform="partners:create"
            yes={() => (
              <Button primary type="button" onClick={handleCreate}>
                <MdAdd size={24} color={colors.iconLight} />
                <span>Cadastrar</span>
              </Button>
            )}
          />
        </FieldGroup>
      </Header>
      <Table>
        <thead>
          <tr>
            <th>
              <ColumnHeader
                label="Nome"
                showOrder={order.field === 'name'}
                asc={order.asc}
                onClick={() => handleChangeOrder('name')}
              />
            </th>
            <th>
              <ColumnHeader
                label="CPF / CNPJ"
                showOrder={order.field === 'cpf_cnpj'}
                asc={order.asc}
                onClick={() => handleChangeOrder('cpf_cnpj')}
              />
            </th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {partners.map((item) => (
            <tr key={item.id}>
              <td>{item.name}</td>
              <td>
                <Can
                  role={role}
                  perform="partners:edit"
                  yes={() => (
                    <LinkButton onClick={() => handleEdit(item.id)}>
                      {item.cpf_cnpj}
                    </LinkButton>
                  )}
                  no={() => <>{item.cpf_cnpj}</>}
                />
              </td>

              <td>
                <Actions height={90} width={150}>
                  <Can
                    role={role}
                    perform="partners:edit"
                    yes={() => (
                      <li>
                        <MdEdit color="#4D85EE" size={16} />
                        <button
                          type="button"
                          onClick={() => handleEdit(item.id)}>
                          Editar
                        </button>
                      </li>
                    )}
                    no={() => (
                      <li>
                        <MdVisibility color="#4D85EE" size={16} />
                        <button
                          type="button"
                          onClick={() => handleEdit(item.id)}>
                          Visualizar
                        </button>
                      </li>
                    )}
                  />
                </Actions>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {!loading && partners.length === 0 ? (
        <NoInfoToShow>Nenhuma informação a exibir.</NoInfoToShow>
      ) : (
        <Pagination
          totalPages={totalPages}
          page={page}
          setPage={setPage}
          pageLimit={pageLimit}
          setPageLimit={setPageLimit}
        />
      )}
    </BaseContainer>
  );
}

export default PartnerList;
