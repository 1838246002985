import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import api from '~/services/api';
import getErrorMessage from '~/lib/getErrorMessage';

import { Container, InfoContainer, MainInfo, Legend } from '../styles';

function Generic({ params, keyValue }) {
  const role = useSelector((state) => state.role.role);
  const [data, setData] = useState([]);

  useEffect(() => {
    async function load() {
      try {
        const results = [];

        for (let i = 0; i < params.length; i += 1) {
          const qParams = {};

          if (params[i].queryParams) {
            for (let j = 0; j < params[i].queryParams.length; j += 1) {
              qParams[params[i].queryParams[j].name] =
                params[i].queryParams[j].value;
            }
          }

          results.push(api.get(params[i].route, { params: qParams }));
        }

        const resultData = await Promise.all(results);
        setData(resultData);
      } catch (err) {
        toast.error(
          <div>
            Falha ao carregar dados! <br /> <br />
            {getErrorMessage(err)}
          </div>
        );
      }
      return true;
    }

    load();
  }, [role, params]);

  return (
    <Container>
      {data.map((item, i) => (
        <InfoContainer key={`${keyValue}-${params[i].key || 1}`}>
          <MainInfo color={params[i].valueColor}>
            {params[i].propName ? item.data[params[i].propName] : item.data}
          </MainInfo>

          <Legend color={params[i].legendColor}>{params[i].legend}</Legend>
        </InfoContainer>
      ))}
    </Container>
  );
}

Generic.propTypes = {
  params: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.node,
  ]).isRequired,
  keyValue: PropTypes.string.isRequired,
};

export default Generic;
