import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      error.response &&
      error.response.status === 412 // Precondition Failed = Token expirado
      // &&
      // error.response.statusText === 'Unauthorized'
    ) {
      localStorage.clear();
      window.location.reload();
    }

    return Promise.reject(error);
  }
);

export default api;
