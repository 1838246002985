import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { parseISO, formatDistanceToNow } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import api from '~/services/api';
import getErrorMessage from '~/lib/getErrorMessage';
import NpsSurveyStatus from '~/pages/NpsSurveys/NpsSurveyList/NpsSurveyStatus';

import { BaseContainer } from '~/components/BaseContainer';
import { NoInfoToShow } from '~/components/NoInfoToShow';
import ColumnHeader from '~/components/ColumnHeader';

import { List, Row, Cell } from './styles';

function LastNpsSurveys({ maxWidthPercent }) {
  const role = useSelector((state) => state.role.role);

  const [npsSurveys, setNpsSurveys] = useState([]);
  const time = useRef(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function loadNpsSurveys(f) {
      try {
        setLoading(true);
        const response = await api.get('nps-surveys', {
          params: { q: f, pageLimit: 5, message: 'true' },
        });

        const surveys = response.data.map((ex) => {
          return {
            ...ex,
            createdAtFormatted:
              ex.createdAt &&
              formatDistanceToNow(parseISO(ex.createdAt), {
                locale: pt,
                addSuffix: true,
              }),
          };
        });

        setNpsSurveys(surveys);
        setLoading(false);
      } catch (err) {
        toast.error(
          <div>
            Falha ao carregar dados! <br /> <br />
            {getErrorMessage(err)}
          </div>
        );
      }
      return true;
    }

    /**
     * Check permissions
     */
    // if (role && !checkPermission(role, 'nps-surveys:visit')) {
    //   history.push('/denied');
    //   return;
    // }

    clearTimeout(time.current);
    time.current = setTimeout(() => {
      loadNpsSurveys();
    }, 600);
  }, [role]);

  // function handleFilterChange(value) {
  //   setPage(1);
  //   setFilter(value);
  // }

  // function handleChangeOrder(field) {
  //   setPage(1);
  //   setOrder({ field, asc: field === order.field ? !order.asc : true });
  // }

  return (
    <BaseContainer maxWidthPercent={maxWidthPercent}>
      {/* <Title>Pesquisas NPS de Hoje {total > 0 && `(${total})`}</Title> */}

      {!loading && npsSurveys.length > 0 && (
        <List>
          <Row>
            <Cell style={{ minWidth: 200 }}>
              <ColumnHeader label="Data" />
            </Cell>
            <Cell style={{ minWidth: 120 }}>
              <ColumnHeader label="Aplicação" />
            </Cell>
            <Cell style={{ minWidth: 180 }}>
              <ColumnHeader label="Cliente" />
            </Cell>
            <Cell style={{ minWidth: 150 }}>
              <ColumnHeader label="Usuário" />
            </Cell>
            <Cell style={{ minWidth: 58 }}>
              <ColumnHeader label="Nota" />
            </Cell>
            <Cell style={{ minWidth: 300 }}>
              <ColumnHeader label="Mensagem" />
            </Cell>
          </Row>
          {npsSurveys.map((item) => (
            <Row key={item.id}>
              <Cell style={{ minWidth: 200 }}>{item.createdAtFormatted}</Cell>
              <Cell style={{ minWidth: 120 }}>{item.application.name}</Cell>
              <Cell style={{ minWidth: 180 }}>
                {item.ext_user.contract.customer.name}
              </Cell>
              <Cell style={{ minWidth: 150 }}>
                {item.ext_user.name || item.ext_user.login}
              </Cell>

              <Cell style={{ minWidth: 58 }}>
                <NpsSurveyStatus nps_value={item.value} />
              </Cell>
              <Cell
                style={{
                  minWidth: 300,
                  maxWidth: 300,
                  whiteSpace: 'normal',
                  fontSize: 'smaller',
                }}>
                {item.message}
              </Cell>
            </Row>
          ))}
        </List>
      )}

      {!loading && npsSurveys.length === 0 && (
        <NoInfoToShow>Nenhuma informação a exibir.</NoInfoToShow>
      )}
    </BaseContainer>
  );
}

LastNpsSurveys.propTypes = {
  maxWidthPercent: PropTypes.number,
};

LastNpsSurveys.defaultProps = {
  maxWidthPercent: null,
};

export default LastNpsSurveys;
