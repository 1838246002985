import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from '@rocketseat/unform';
import { MdDone, MdArrowBack } from 'react-icons/md';
import { toast } from 'react-toastify';
import 'react-tabs/style/react-tabs.css';
import { useSelector } from 'react-redux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import api from '~/services/api';
import history from '~/services/history';
import colors from '~/styles/colors';

import { BaseContainer } from '~/components/BaseContainer';
import { FormHeader } from '~/components/FormHeader';
import { Button } from '~/components/Buttons/Button';
import { Title } from '~/components/Title';
import { FormWrapper, FieldGroup, Field } from '~/components/Forms';
import ReleaseGroupContractList from '~/pages/ReleaseGroupContracts/ReleaseGroupContractList';

import checkPermission from '~/lib/checkPermission';
import getErrorMessage from '~/lib/getErrorMessage';

// import { } from './styles';

function ReleaseGroupForm({ location }) {
  const [releaseGroup] = useState(location.releaseGroup);
  const isEditing = history.location.pathname === '/release-groups/edit';

  /**
   * Permissions
   */
  const role = useSelector((state) => state.role.role);
  const denied = !isEditing && !checkPermission(role, 'release-groups:create');

  useEffect(() => {
    /**
     * Redirect if permission denied
     */
    if (denied) {
      history.push('/denied');
      return;
    }

    /**
     * Go back on page refresh
     */
    if (isEditing && !releaseGroup) {
      history.goBack();
    }
  }, [releaseGroup, isEditing, denied]);

  /**
   * Form
   */
  function handleClickBack() {
    history.goBack();
  }

  async function save(name, obs) {
    const payload = { name, obs };

    try {
      if (releaseGroup) {
        await api.put(`release-groups/${releaseGroup.id}`, payload); // Edit
      } else {
        await api.post('release-groups', payload); // Create
      }

      toast.success(
        `Cadastro ${isEditing ? 'alterado' : 'realizado'} com sucesso!`
      );

      return history.push('/release-groups');
    } catch (err) {
      return toast.error(
        <div>
          Falha ao salvar! <br /> <br />
          {getErrorMessage(err)}
        </div>
      );
    }
  }

  function handleSubmit({ name, obs }) {
    if (!isEditing && !checkPermission(role, 'release-groups:create')) {
      return;
    }
    if (isEditing && !checkPermission(role, 'release-groups:edit')) {
      return;
    }

    save(name, obs);
  }

  return (
    <>
      <BaseContainer>
        <FormWrapper>
          <Form onSubmit={handleSubmit} initialData={releaseGroup}>
            <FormHeader>
              <Title>Cadastro de Grupo de Atualização</Title>
              <div>
                <Button type="button" onClick={handleClickBack}>
                  <MdArrowBack size={24} color={colors.iconLight} />
                  <span>Voltar</span>
                </Button>
                <Button
                  primary
                  type="submit"
                  disabled={
                    isEditing && !checkPermission(role, 'release-groups:edit')
                  }>
                  <MdDone size={24} color={colors.iconLight} />
                  <span>Salvar</span>
                </Button>
              </div>
            </FormHeader>

            <FieldGroup>
              <Field flex="0.3">
                <label htmlFor="name">Nome</label>
                <Input
                  name="name"
                  type="text"
                  maxLength="255"
                  autoFocus
                  autoComplete="off"
                />
              </Field>
            </FieldGroup>
            <FieldGroup>
              <Field>
                <label htmlFor="obs">Observação</label>
                <Input
                  name="obs"
                  type="text"
                  maxLength="255"
                  autoComplete="off"
                />
              </Field>
            </FieldGroup>
          </Form>
        </FormWrapper>

        <Field>
          <Tabs>
            <TabList>
              <Tab>Contratos</Tab>
            </TabList>

            <TabPanel>
              {releaseGroup && (
                <ReleaseGroupContractList
                  releaseGroup={releaseGroup}
                  maxWidthPercent={100}
                />
              )}
            </TabPanel>
          </Tabs>
        </Field>
      </BaseContainer>
    </>
  );
}

ReleaseGroupForm.propTypes = {
  location: PropTypes.shape(),
};

ReleaseGroupForm.defaultProps = {
  location: null,
};

export default ReleaseGroupForm;
