import React from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Form, Input } from '@rocketseat/unform';

import * as Yup from 'yup';
import api from '~/services/api';
import history from '~/services/history';

import Logo from '~/components/Logo';
import { Container, LinkContainer } from './syles';
// import { signInRequest } from '~/store/modules/auth/actions';
// import logo from '~/assets/logo.png';
import getErrorMessage from '~/lib/getErrorMessage';

const schema = Yup.object().shape({
  email: Yup.string()
    .email('Insira um e-mail válido')
    .required('O e-mail é obrigatório'),
});

function ResetPassword() {
  // const [loading, setLoading] = useState(false);

  async function resetPassword(email) {
    const payload = { email };

    try {
      await api.post('reset-password', payload);
      toast.success(`Uma nova senha foi gerada e enviada para o seu e-mail.`);
      return history.push('/');
    } catch (err) {
      return toast.error(
        <div>
          Falha ao redefinir senha! <br /> <br />
          {getErrorMessage(err)}
        </div>
      );
    }
  }

  function handleSubmit({ email }) {
    resetPassword(email);
  }

  return (
    <Container>
      <Logo />

      <Form schema={schema} onSubmit={handleSubmit}>
        <label htmlFor="email">SEU E-MAIL</label>
        <Input name="email" type="email" placeholder="exemplo@e-mail.com" />

        <button type="submit">Redefinir senha</button>
      </Form>
      <LinkContainer>
        <Link to="/">Entrar</Link>
      </LinkContainer>
    </Container>
  );
}

export default ResetPassword;
