import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { MdImage } from 'react-icons/md';
import PropTypes from 'prop-types';
import Loader from 'react-loader-spinner';
import getErrorMessage from '~/lib/getErrorMessage';

import colors from '~/styles/colors';
import { Container } from './styles';

function ImageInput({ url, onChange, disabled = false }) {
  /**
   * States
   */
  const MAX_FILE_SIZE_500_KB = 500 * 1024;
  const [loading, setLoading] = useState(false);
  // const ref = useRef();

  async function handleFileChange(e) {
    try {
      const file = e.target.files[0];

      if (!file) return false;

      if (file.size > MAX_FILE_SIZE_500_KB) {
        throw new Error('A imagem não pode ser maior que 500Kb');
      }

      setLoading(true);

      const data = new FormData();
      data.append('file', e.target.files[0]);

      await onChange(data);

      setLoading(false);
      return toast.success('Imagem alterada com sucesso!');
    } catch (err) {
      setLoading(false);
      return toast.error(
        <div>
          Falha ao alterar imagem! <br /> <br />
          {getErrorMessage(err)}
        </div>
      );
    }
  }

  return (
    <Container disabled={disabled}>
      <label htmlFor="avatar">
        {url && !loading ? (
          <img src={url} alt="Foto" />
        ) : (
          <>
            {loading ? (
              <>
                <Loader
                  type="Oval"
                  color={colors.color_3}
                  height={40}
                  width={40}
                />
                <strong>Fazendo upload...</strong>
              </>
            ) : (
              <>
                <MdImage size={40} color="#ccc" />
                <strong>Adicionar imagem</strong>
              </>
            )}
          </>
        )}

        <input
          disabled={disabled}
          type="file"
          id="avatar"
          accept="image/*"
          // data-file={fileId}
          // ref={ref}
          onChange={handleFileChange}
        />
      </label>{' '}
    </Container>
  );
}

ImageInput.propTypes = {
  url: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

ImageInput.defaultProps = {
  disabled: false,
};

export default ImageInput;
