import React, { useState, useEffect, useRef } from 'react';
import AsyncSelect from 'react-select/async';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { Form, Input, Check } from '@rocketseat/unform';
import {
  MdDone,
  MdArrowBack,
  MdVisibility,
  MdVisibilityOff,
} from 'react-icons/md';
import { toast } from 'react-toastify';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { useSelector } from 'react-redux';

import getErrorMessage from '~/lib/getErrorMessage';
import checkPermission from '~/lib/checkPermission';

import api from '~/services/api';
import history from '~/services/history';
import colors from '~/styles/colors';

import LicensesList from '~/pages/Licenses/LicenseList';
import SupportTokenList from '~/pages/SupportTokens/SupportTokenList';
import ContractModuleList from '~/pages/ContractModules/ContractModuleList';
import ContractVersionsList from '~/pages/ContractVersions/ContractVersionsList';
import DatabaseList from '~/pages/Databases/DatabaseList';
import ContractIntegrationList from '~/pages/ContractIntegrations/ContractIntegrationList';

import HistoryList from '~/components/HistoryList';
import { BaseContainer } from '~/components/BaseContainer';
import { FormHeader } from '~/components/FormHeader';
import { Button } from '~/components/Buttons/Button';
import { Title } from '~/components/Title';
import ContractUserCount from '~/components/business/ContractUserCount';
import ContractCompanyCount from '~/components/business/ContractCompanyCount';
import {
  FormWrapper,
  FieldGroup,
  Field,
  FieldInlineWrapper,
} from '~/components/Forms';

import statusToStr from '../ContractList/ContractStatus/status';

// import { } from './styles';

function ContractForm({ location }) {
  const time = useRef(null);
  const timerStatus = useRef(null);
  const [contract] = useState(location.contract);
  const [selectedCustomer, setSelectedCustomer] = useState(
    contract && contract.customer
  );
  const [selectedStatus, setSelectedStatus] = useState(
    (contract && contract.status) || 'active'
  );
  const [showPass, setShowPass] = useState(false);
  const isEditing = history.location.pathname === '/contracts/edit';

  /**
   * Permissions
   */
  const role = useSelector((state) => state.role.role);
  const denied = !isEditing && !checkPermission(role, 'contracts:create');
  const viewTabTech = checkPermission(role, 'contracts/tech:visit');
  const editTabTech = checkPermission(role, 'contracts/tech:edit');

  const viewTabFinance = checkPermission(role, 'contracts/finance:visit');
  const editTabFinance = checkPermission(role, 'contracts/finance:edit');

  const viewTabCommercial = checkPermission(role, 'contracts/commercial:visit');
  const editTabCommercial = checkPermission(role, 'contracts/commercial:edit');

  const showTabLicenses = checkPermission(role, 'licenses:visit');
  const showTabTokens = checkPermission(role, 'support-tokens:visit');
  const showTabModules = checkPermission(role, 'contracts:visit');
  const showTabDatabases = checkPermission(role, 'contracts:visit');
  const showTabVersions = checkPermission(role, 'contracts:visit');
  const showTabIntegrations = checkPermission(role, 'contracts:visit');

  /**
   * Field States
   */

  const [description, setDescription] = useState(
    contract && contract.description
  );

  const [start_date, setStartDate] = useState(
    contract ? contract.startDateEn : format(new Date(), 'yyyy-MM-dd')
  );
  const [max_licenses, setMaxLicenses] = useState(
    contract && contract.max_licenses
  );
  const [max_users, setMaxUsers] = useState(contract && contract.max_users);
  const [path_db, setPathDB] = useState(contract && contract.path_db);
  const [remote_db, setRemoteDB] = useState(contract && contract.remote_db);
  const [username_db, setUsernameDB] = useState(
    contract && contract.username_db
  );
  const [password_db, setPasswordDB] = useState(
    contract && contract.password_db
  );
  const [contract_token, setContractToken] = useState(
    contract && contract.contract_token
  );
  const [alias, setAlias] = useState(contract && contract.alias);
  const [access_block_date, setAccessBlockDate] = useState(
    contract && contract.accessBlockDateEn
  );
  const [block_update, setBlockUpdate] = useState(
    contract ? contract.block_update : false
  );
  const [courtesy_licenses, setCourtesyLicenses] = useState(
    contract && contract.courtesy_licenses ? contract.courtesy_licenses : null
  );
  const [max_companies, setMaxCompanies] = useState(
    contract && contract.max_companies ? contract.max_companies : null
  );
  const [commercial_note, setCommercialNote] = useState(
    contract && contract.commercial_note ? contract.commercial_note : ''
  );
  const [tech_note, setTechNote] = useState(
    contract && contract.tech_note ? contract.tech_note : ''
  );
  const [finance_note, setFinanceNote] = useState(
    contract && contract.finance_note ? contract.finance_note : ''
  );
  const [send_new_licenses_info_email, setSendNewLicenseInfoEmail] = useState(
    contract ? contract.send_new_licenses_info_email : false
  );

  useEffect(() => {
    /**
     * Redirect if permission denied
     */
    if (denied) {
      history.push('/denied');
      return;
    }

    /**
     * Go back on page refresh
     */
    if (isEditing && !contract) {
      history.goBack();
    }
  }, [contract, isEditing, denied]);

  /**
   * Async Select
   */
  async function loadCustomers(filter) {
    const response = await api.get('customers', {
      params: { q: filter },
    });

    const customers = response.data.map((customer) => {
      return { value: customer.id, label: customer.name };
    });

    return customers;
  }

  const loadCustomerOptions = (inputValue, callback) => {
    clearTimeout(time.current);
    time.current = setTimeout(async () => {
      callback(await loadCustomers(inputValue));
    }, 600);
  };

  const handleChangeCustomer = (selectedOptions) => {
    setSelectedCustomer({
      id: selectedOptions.value,
      name: selectedOptions.label,
    });

    // Aproveita e define a descrição
    setDescription(selectedOptions.label);
  };

  // Status

  async function loadStatus(filter) {
    const response = await api.get('contract-status', {
      params: { q: filter },
    });

    const statusList = response.data.map((st) => {
      return { value: st, label: statusToStr[st] };
    });

    return statusList;
  }

  const loadStatusOptions = (inputValue, callback) => {
    clearTimeout(timerStatus.current);
    timerStatus.current = setTimeout(async () => {
      callback(await loadStatus(inputValue));
    }, 600);
  };

  const handleChangeStatus = (selectedOptions) => {
    setSelectedStatus(selectedOptions.value);
  };

  /**
   * Form
   */
  const handleClickBack = () => {
    history.goBack();
  };

  async function save(customer_id, admin_email, server_info) {
    const payload = {
      customer_id,
      description,
      contract_token,
      alias,
      start_date,
      max_licenses: max_licenses || null,
      courtesy_licenses: courtesy_licenses || null,
      max_companies: max_companies || null,
      max_users: max_users || null,
      path_db,
      remote_db,
      username_db,
      password_db,
      access_block_date: access_block_date || null,
      block_update,
      status: selectedStatus,
      admin_email,
      server_info,
      commercial_note,
      finance_note,
      tech_note,
      send_new_licenses_info_email,
    };

    try {
      if (contract) {
        await api.put(`contracts/${contract.contract_token}`, payload); // Edit
      } else {
        await api.post('contracts', payload); // Create
      }

      toast.success(
        `Cadastro ${isEditing ? 'alterado' : 'realizado'} com sucesso!`
      );

      return history.push('/contracts');
    } catch (err) {
      return toast.error(
        <div>
          Falha ao salvar! <br /> <br />
          {getErrorMessage(err)}
        </div>
      );
    }
  }

  const handleSubmit = ({ admin_email, server_info }) => {
    if (!isEditing && !checkPermission(role, 'contracts:create')) {
      return;
    }
    if (isEditing && !checkPermission(role, 'contracts:edit')) {
      return;
    }

    save(selectedCustomer && selectedCustomer.id, admin_email, server_info);
  };

  return (
    <>
      <BaseContainer>
        <FormWrapper>
          <Form
            onSubmit={handleSubmit}
            initialData={{
              admin_email: contract && contract.admin_email,
              server_info: contract && contract.server_info,
            }}>
            <FormHeader>
              <Title>Cadastro de Contratos</Title>
              <div>
                <Button type="button" onClick={handleClickBack}>
                  <MdArrowBack size={24} color={colors.iconLight} />
                  <span>Voltar</span>
                </Button>
                <Button
                  primary
                  type="submit"
                  disabled={
                    isEditing && !checkPermission(role, 'contracts:edit')
                  }>
                  <MdDone size={24} color={colors.iconLight} />
                  <span>Salvar</span>
                </Button>
              </div>
            </FormHeader>

            <FieldGroup>
              <Field flex={2}>
                <label htmlFor="customers">Cliente</label>
                <AsyncSelect
                  autoFocus
                  name="customers"
                  placeholder="Selecione um cliente..."
                  cacheOptions
                  loadOptions={loadCustomerOptions}
                  defaultOptions
                  onChange={handleChangeCustomer}
                  isDisabled={isEditing}
                  defaultValue={
                    contract && {
                      value: contract.customer.id,
                      label: contract.customer.name,
                    }
                  }
                  required
                />
              </Field>
              <Field flex={1}>
                <label htmlFor="status">Status</label>
                <AsyncSelect
                  name="status"
                  placeholder="Selecione um status..."
                  cacheOptions
                  loadOptions={loadStatusOptions}
                  defaultOptions
                  onChange={handleChangeStatus}
                  defaultValue={
                    (contract && {
                      value: contract.status,
                      label: statusToStr[contract.status],
                    }) || { value: 'active', label: statusToStr.active }
                  }
                  required
                />
              </Field>
              <Field>
                <label htmlFor="description">Descrição</label>
                <Input
                  name="description"
                  type="text"
                  autoComplete="off"
                  placeholder="Deixe em branco para gerar automaticamente"
                  maxLength="255"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Field>
            </FieldGroup>

            <FieldGroup>
              <Field>
                <label htmlFor="admin_email">
                  E-mails dos Administradores (separados por &quot;ponto e
                  vírgula&quot;)
                </label>
                <Input
                  name="admin_email"
                  type="text"
                  autoComplete="off"
                  placeholder="email1; email2; email3"
                  maxLength="255"
                  required={send_new_licenses_info_email}
                />
              </Field>
            </FieldGroup>
            <FieldGroup>
              <Check
                style={{ marginLeft: 10 }}
                name="send_new_licenses_info_email"
                checked={send_new_licenses_info_email}
                onChange={() =>
                  setSendNewLicenseInfoEmail(!send_new_licenses_info_email)
                }
              />
              <Field flex="none">
                <label htmlFor="send_new_licenses_info_email">
                  Enviar informações de alterações de Licenças para os
                  Adminsitradores
                </label>
              </Field>
            </FieldGroup>
            <FieldGroup>
              <Field>
                {(viewTabFinance || viewTabTech) && (
                  <Tabs>
                    <TabList>
                      {viewTabCommercial && <Tab>Informações Comerciais</Tab>}
                      {viewTabFinance && <Tab>Informações Financeiras</Tab>}
                      {viewTabTech && <Tab>Informações Técnicas</Tab>}
                    </TabList>

                    {viewTabCommercial && (
                      <TabPanel>
                        <>
                          <FieldGroup>
                            <Field flex="none">
                              <label htmlFor="start_date">
                                Data de Contratação
                              </label>
                              <Input
                                name="start_date"
                                type="date"
                                autoComplete="off"
                                value={start_date || ''}
                                onChange={(e) => setStartDate(e.target.value)}
                                required
                                disabled={!editTabCommercial}
                              />
                            </Field>

                            <Field flex="none">
                              <label htmlFor="max_licenses">
                                Limite de Licenças
                              </label>
                              <Input
                                name="max_licenses"
                                type="number"
                                autoComplete="off"
                                value={max_licenses || ''}
                                onChange={(e) => setMaxLicenses(e.target.value)}
                                disabled={!editTabCommercial}
                                maxLength={2}
                                max={99}
                                min={0}
                              />
                            </Field>

                            <Field flex="none">
                              <label htmlFor="courtesy_licenses">
                                Licenças Cortesia
                              </label>
                              <Input
                                name="courtesy_licenses"
                                type="number"
                                autoComplete="off"
                                value={courtesy_licenses || ''}
                                onChange={(e) =>
                                  setCourtesyLicenses(e.target.value)
                                }
                                disabled={!editTabCommercial}
                                maxLength={2}
                                min={0}
                                max={99}
                              />
                            </Field>
                          </FieldGroup>

                          <FieldGroup>
                            <Field flex="none">
                              <label htmlFor="max_users">
                                Limite de Usuários
                              </label>
                              <FieldInlineWrapper>
                                <Input
                                  name="max_users"
                                  type="number"
                                  autoComplete="off"
                                  value={max_users || ''}
                                  onChange={(e) => setMaxUsers(e.target.value)}
                                  disabled={!editTabCommercial}
                                  min={0}
                                  max={99}
                                />

                                {isEditing ? (
                                  <ContractUserCount
                                    contract_id={contract && contract.id}
                                  />
                                ) : null}
                              </FieldInlineWrapper>
                            </Field>
                          </FieldGroup>

                          <FieldGroup>
                            <Field flex="none">
                              <label htmlFor="max_companies">
                                Limite de Empresas
                              </label>
                              <FieldInlineWrapper>
                                <Input
                                  name="max_companies"
                                  type="number"
                                  autoComplete="off"
                                  value={max_companies || ''}
                                  onChange={(e) =>
                                    setMaxCompanies(e.target.value)
                                  }
                                  disabled={!editTabCommercial}
                                  min={0}
                                  max={99}
                                />

                                {isEditing ? (
                                  <ContractCompanyCount
                                    contract_id={contract && contract.id}
                                  />
                                ) : null}
                              </FieldInlineWrapper>
                            </Field>
                          </FieldGroup>

                          <Field>
                            <Input
                              multiline
                              name="commercial_note"
                              label="Observações Comerciais"
                              maxLength={500}
                              style={{
                                resize: 'none',
                                border: '1px solid #ccc',
                                borderRadius: '3px',
                                height: '70px',
                              }}
                              value={commercial_note}
                              onChange={(e) =>
                                setCommercialNote(e.target.value)
                              }
                            />
                          </Field>
                        </>
                      </TabPanel>
                    )}

                    {viewTabFinance && (
                      <TabPanel>
                        <FieldGroup>
                          <Field flex="none">
                            <label htmlFor="access_block_date">
                              Data de Bloqueio
                            </label>
                            <Input
                              name="access_block_date"
                              type={access_block_date ? 'date' : 'text'}
                              autoComplete="off"
                              value={access_block_date}
                              onChange={(e) =>
                                setAccessBlockDate(e.target.value)
                              }
                              onFocus={(e) => {
                                e.target.type = 'date';
                              }}
                              onBlur={(e) => {
                                e.target.type = access_block_date
                                  ? 'date'
                                  : 'text';
                              }}
                              disabled={!editTabFinance}
                            />
                          </Field>
                        </FieldGroup>
                        <FieldGroup>
                          <Field>
                            <Input
                              multiline
                              name="finance_note"
                              label="Observações Financeiras"
                              maxLength={500}
                              style={{
                                resize: 'none',
                                border: '1px solid #ccc',
                                borderRadius: '3px',
                                height: '70px',
                              }}
                              value={finance_note}
                              onChange={(e) => setFinanceNote(e.target.value)}
                            />
                          </Field>
                        </FieldGroup>
                      </TabPanel>
                    )}

                    {viewTabTech && (
                      <TabPanel>
                        <>
                          <FieldGroup>
                            <Field flex={0.3}>
                              <label htmlFor="contract_token">
                                Contract Token
                              </label>
                              <Input
                                name="contract_token"
                                type="text"
                                autoComplete="off"
                                placeholder="Gerado automaticamente"
                                maxLength="32"
                                disabled
                                value={contract_token}
                                onChange={(e) =>
                                  setContractToken(e.target.value)
                                }
                              />
                            </Field>
                            <Field flex={0.3}>
                              <label htmlFor="alias">
                                Código do Cliente (alias)
                              </label>
                              <Input
                                name="alias"
                                type="text"
                                autoComplete="off"
                                placeholder="Gerado automaticamente"
                                maxLength="100"
                                value={alias}
                                onChange={(e) => setAlias(e.target.value)}
                                disabled
                              />
                            </Field>
                            <Field flex="none">
                              <label htmlFor="block_update">
                                Bloquear Atualizações
                              </label>
                              <Check
                                name="block_update"
                                checked={block_update}
                                onChange={() => setBlockUpdate(!block_update)}
                                disabled={!editTabTech}
                              />
                            </Field>
                          </FieldGroup>
                          <FieldGroup>
                            <Field flex={2}>
                              <label htmlFor="path_db">Path DB</label>
                              <Input
                                name="path_db"
                                type="text"
                                maxLength="500"
                                autoComplete="off"
                                value={path_db}
                                onChange={(e) => setPathDB(e.target.value)}
                                disabled={!editTabTech}
                              />
                            </Field>
                            <Field flex={2}>
                              <label htmlFor="remote_db">Remote DB</label>
                              <Input
                                name="remote_db"
                                type="text"
                                maxLength="500"
                                autoComplete="off"
                                value={remote_db}
                                onChange={(e) => setRemoteDB(e.target.value)}
                                disabled={!editTabTech}
                              />
                            </Field>

                            {checkPermission(role, 'contracts:edit') && (
                              <Field flex={1}>
                                <label htmlFor="username_db">Username DB</label>
                                <Input
                                  name="username_db"
                                  type={showPass ? 'text' : 'password'}
                                  maxLength="100"
                                  autoComplete="off"
                                  value={username_db}
                                  onChange={(e) =>
                                    setUsernameDB(e.target.value)
                                  }
                                  disabled={!editTabTech}
                                />
                              </Field>
                            )}

                            {checkPermission(role, 'contracts:edit') && (
                              <Field flex={1}>
                                <label htmlFor="password_db">Password DB</label>
                                <Input
                                  style={{ flex: 1 }}
                                  name="password_db"
                                  type={showPass ? 'text' : 'password'}
                                  maxLength="100"
                                  autoComplete="off"
                                  value={password_db}
                                  onChange={(e) =>
                                    setPasswordDB(e.target.value)
                                  }
                                  disabled={!editTabTech}
                                />
                              </Field>
                            )}
                          </FieldGroup>

                          {checkPermission(role, 'contracts:edit') && (
                            <FieldGroup>
                              <Field>
                                <Button
                                  style={{
                                    paddingLeft: 10,
                                    paddingRight: 5,
                                    marginLeft: 0,
                                    maxWidth: 200,
                                  }}
                                  type="button"
                                  onClick={() => setShowPass(!showPass)}
                                  disabled={!editTabTech}>
                                  {showPass ? (
                                    <>
                                      <MdVisibility
                                        size={18}
                                        color={colors.iconLight}
                                      />
                                      Dados visíveis
                                    </>
                                  ) : (
                                    <>
                                      <MdVisibilityOff
                                        size={18}
                                        color={colors.iconLight}
                                      />
                                      Dados ocultos
                                    </>
                                  )}
                                </Button>
                              </Field>
                            </FieldGroup>
                          )}

                          <FieldGroup>
                            <Field>
                              <label htmlFor="server_info">Servidor</label>
                              <Input
                                name="server_info"
                                type="text"
                                maxLength="255"
                                autoComplete="off"
                                disabled={!editTabTech}
                              />
                            </Field>
                          </FieldGroup>
                          <FieldGroup>
                            <Field>
                              <Input
                                multiline
                                name="tech_note"
                                label="Observações Técnicas"
                                maxLength={500}
                                style={{
                                  resize: 'none',
                                  border: '1px solid #ccc',
                                  borderRadius: '3px',
                                  height: '70px',
                                }}
                                value={tech_note}
                                onChange={(e) => setTechNote(e.target.value)}
                              />
                            </Field>
                          </FieldGroup>
                        </>
                      </TabPanel>
                    )}
                  </Tabs>
                )}
              </Field>
            </FieldGroup>
          </Form>
        </FormWrapper>
        <Field>
          <Tabs>
            <TabList>
              {showTabLicenses && <Tab>Licenças</Tab>}
              {showTabModules && <Tab>Módulos</Tab>}
              {showTabTokens && <Tab>Tokens de Atendimento</Tab>}
              {showTabDatabases && <Tab>Databases</Tab>}
              {showTabVersions && <Tab>Versões</Tab>}
              {showTabIntegrations && <Tab>Integrações</Tab>}
              {checkPermission(role, 'contracts:edit') && <Tab>Histórico</Tab>}
            </TabList>

            {showTabLicenses && (
              <TabPanel>
                {contract && (
                  <LicensesList contract={contract} maxWidthPercent={100} />
                )}
              </TabPanel>
            )}

            {showTabModules && (
              <TabPanel>
                {contract && (
                  <ContractModuleList
                    contract={contract}
                    maxWidthPercent={100}
                  />
                )}
              </TabPanel>
            )}

            {showTabTokens && (
              <TabPanel>
                {contract && (
                  <SupportTokenList contract={contract} maxWidthPercent={100} />
                )}
              </TabPanel>
            )}

            {showTabDatabases && (
              <TabPanel>
                {contract && (
                  <DatabaseList contract={contract} maxWidthPercent={100} />
                )}
              </TabPanel>
            )}

            {showTabVersions && (
              <TabPanel>
                {contract && (
                  <ContractVersionsList
                    contract={contract}
                    maxWidthPercent={100}
                  />
                )}
              </TabPanel>
            )}

            {showTabIntegrations && (
              <TabPanel>
                {contract && (
                  <ContractIntegrationList
                    contract={contract}
                    maxWidthPercent={100}
                  />
                )}
              </TabPanel>
            )}

            {checkPermission(role, 'contracts:edit') && (
              <TabPanel>
                {contract && (
                  <HistoryList
                    id={contract.id}
                    route="contracts"
                    maxWidthPercent={100}
                  />
                )}
              </TabPanel>
            )}
          </Tabs>
        </Field>
      </BaseContainer>
    </>
  );
}

ContractForm.propTypes = {
  location: PropTypes.shape(),
};

ContractForm.defaultProps = {
  location: null,
};

export default ContractForm;
