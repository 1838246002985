import React from 'react';
import PropTypes from 'prop-types';

import { Container, Header, Title, Content } from './styles';

function DashboardPanel({ title, style, children }) {
  return (
    <Container style={style}>
      <Header>
        <Title>{title}</Title>
      </Header>
      <Content>{children}</Content>
    </Container>
  );
}

DashboardPanel.propTypes = {
  title: PropTypes.string,
  style: PropTypes.shape(),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

DashboardPanel.defaultProps = {
  title: null,
  style: null,
  children: null,
};

export default DashboardPanel;
