import styled from 'styled-components';

export const Header = styled.header`
  margin-top: 30px;
  padding: 10px 0;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const CellWrapper = styled.div`
  /* background: lightcoral; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */

  > svg {
    margin: 0 5px;
  }
`;
