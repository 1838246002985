import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

function UserStatus({ user }) {
  return (
    <Container active={user.active}>
      <span />
      {user.active ? 'ATIVO' : 'INATIVO'}
    </Container>
  );
}

UserStatus.propTypes = {
  user: PropTypes.shape().isRequired,
};

export default UserStatus;
