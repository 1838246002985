import styled from 'styled-components';
import colors from '~/styles/colors';

export const Table = styled.table`
  border-collapse: separate;
  border-spacing: 0px 12px;

  tr {
    height: 46px;
  }

  th {
    text-align: left;
    padding: 16px 10px;

    border-bottom-width: 1px;
    border-bottom-color: ${colors.color_1};
    border-bottom-style: solid;
  }

  tbody td {
    color: ${colors.textDark};
    vertical-align: middle;
  }

  // Header
  tr th {
    text-align: center;
  }
  // Header last column
  /* tr th:last-child {
    text-align: center;
  } */

  // Rows
  tr td {
    text-align: center;
  }

  tbody tr {
    background: ${colors.panelBackground};
    /* box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05); */
    /* transition: all 0.1s ease-out; */
  }

  /* tbody tr:hover {
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.05);
  } */

  // Rows last column
  /* tr td:last-child {
    text-align: center;
  } */

  /* Padding somente na primeira e última colunas */
  tr th:first-child {
    padding-left: 30px;
  }
  tr td:first-child {
    padding-left: 30px;
  }
  tr th:last-child {
    padding-right: 30px;
  }
  tr td:last-child {
    padding-right: 30px;
  }

  /* Borda arredondada na 1ª linha da tabela */
  tr th:first-child {
    border-top-left-radius: 4px;
  }
  tr th:first-child {
    border-bottom-left-radius: 4px;
  }
  tr th:last-child {
    border-top-right-radius: 4px;
  }
  tr th:last-child {
    border-bottom-right-radius: 4px;
  }

  /* Borda arredondada nas linhas da tabela */
  tr td:first-child {
    border-top-left-radius: 4px;
  }
  tr td:first-child {
    border-bottom-left-radius: 4px;
  }
  tr td:last-child {
    border-top-right-radius: 4px;
  }
  tr td:last-child {
    border-bottom-right-radius: 4px;
  }
`;
