import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

// import history from '~/services/history';
import api from '~/services/api';

import { refreshRoleSuccess } from './actions';
import { signOut } from '../auth/actions';

export function* refreshRole() {
  try {
    const response = yield call(api.get, 'users/profile');

    const { role } = response.data;

    yield put(refreshRoleSuccess(role));
  } catch (err) {
    if (err.response.status === 401) {
      toast.warning('Sessão expirada. Faça login novamente.');
      yield put(signOut());
    }
  }
}

export default all([takeLatest('@role/REFRESH_ROLE_REQUEST', refreshRole)]);
