import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { format, parseISO, isToday } from 'date-fns';
import { toast } from 'react-toastify';
import { MdRefresh } from 'react-icons/md';

import api from '~/services/api';
import history from '~/services/history';

import { Button } from '~/components/Buttons/Button';
import { BaseContainer } from '~/components/BaseContainer';
import { Table } from '~/components/Table';
import { Title } from '~/components/Title';
import { NoInfoToShow } from '~/components/NoInfoToShow';
import ColumnHeader from '~/components/ColumnHeader';
import Pagination from '~/components/Pagination';
import useModal from '~/components/Modal/useModal';
import Modal from '~/components/Modal';

import checkPermission from '~/lib/checkPermission';
import getErrorMessage from '~/lib/getErrorMessage';
import colors from '~/styles/colors';
import SearchInput from '~/components/Inputs/SearchInput';
import { InvisibleButton } from '~/components/Buttons/InvisibleButton';
import ExceptionModal from './ExceptionModal';

import { Header, Image } from './styles';

function Exceptions() {
  const role = useSelector((state) => state.role.role);
  const { isShowing, toggle } = useModal();
  const [filter, setFilter] = useState('');
  const [exceptions, setExceptions] = useState([]);
  const [exception, setException] = useState();
  const time = useRef(null);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState({ field: 'created_at', asc: false });
  const [refresh, setRefresh] = useState(true);

  useEffect(() => {
    async function loadExceptions(f) {
      try {
        setLoading(true);
        const response = await api.get('exceptions', {
          params: {
            q: f,
            page,
            pageLimit,
            order: order.field,
            direction: order.asc ? 'ASC' : 'DESC',
          },
        });

        const data = response.data.map((ex) => {
          return {
            ...ex,
            stackFormatted: ex.stack && ex.stack.replaceAll('/', ' / '),
            groupFormatted: ex.group && ex.group.replaceAll('/', ` / `),
            createdAtFormatted:
              ex.createdAt &&
              format(parseISO(ex.createdAt), 'dd/MM/yyyy HH:mm'),
            isToday: isToday(parseISO(ex.createdAt)),
          };
        });

        setTotalPages(Number(response.headers['x-api-totalpages']));
        setTotal(Number(response.headers['x-api-total']));
        setExceptions(data);
        setLoading(false);
      } catch (err) {
        toast.error(
          <div>
            Falha ao carregar dados! <br /> <br />
            {getErrorMessage(err)}
          </div>
        );
      }
      return true;
    }

    /**
     * Check permissions
     */
    if (role && !checkPermission(role, 'exceptions:visit')) {
      history.push('/denied');
      return;
    }

    clearTimeout(time.current);
    time.current = setTimeout(() => {
      loadExceptions(filter);
    }, 600);
  }, [filter, page, pageLimit, order, role, refresh]);

  function handleFilterChange(value) {
    setPage(1);
    setFilter(value);
  }

  function handleShow(id) {
    const except = exceptions.find((ex) => ex.id === id);
    if (except) {
      setException(except);
      toggle();
    }
  }

  function handleChangeOrder(field) {
    setPage(1);
    setOrder({ field, asc: field === order.field ? !order.asc : true });
  }

  function handleRefresh() {
    setLoading(true);
    setRefresh(!refresh);
  }

  return (
    <BaseContainer>
      <Modal isShowing={isShowing} hide={toggle}>
        <ExceptionModal exception={exception} />
      </Modal>

      <Title>Exceptions {total > 0 && `(${total})`}</Title>

      <Header>
        <SearchInput
          placeholder="mensagem, classe, stack, grupo, cliente, usuário"
          value={filter}
          onChange={(e) => handleFilterChange(e.target.value)}
        />
        <Button
          type="button"
          padding="6px"
          disabled={loading}
          onClick={handleRefresh}>
          <MdRefresh size={24} color={colors.iconLight} />
        </Button>
      </Header>
      <Table>
        <thead>
          <tr>
            <th>
              <ColumnHeader
                label="Data"
                showOrder={order.field === 'created_at'}
                asc={order.asc}
                onClick={() => handleChangeOrder('created_at')}
              />
            </th>
            <th>
              <ColumnHeader
                label="Aplicação"
                showOrder={order.field === 'application.name'}
                asc={order.asc}
                onClick={() => handleChangeOrder('application.name')}
              />
            </th>
            <th>
              <ColumnHeader
                label="Versão"
                showOrder={order.field === 'version'}
                asc={order.asc}
                onClick={() => handleChangeOrder('version')}
              />
            </th>
            <th>Imagem</th>
            <th>
              <ColumnHeader
                label="Mensagem"
                showOrder={order.field === 'message'}
                asc={order.asc}
                onClick={() => handleChangeOrder('message')}
              />
            </th>
            <th>
              <ColumnHeader
                label="Classe"
                showOrder={order.field === 'classname'}
                asc={order.asc}
                onClick={() => handleChangeOrder('classname')}
              />
            </th>
            <th>
              <ColumnHeader
                label="Stack"
                showOrder={order.field === 'stack'}
                asc={order.asc}
                onClick={() => handleChangeOrder('stack')}
              />
            </th>
            <th>
              <ColumnHeader
                label="Grupo"
                showOrder={order.field === 'group'}
                asc={order.asc}
                onClick={() => handleChangeOrder('group')}
              />
            </th>
            <th>
              <ColumnHeader
                label="Cliente"
                showOrder={order.field === 'customer.name'}
                asc={order.asc}
                onClick={() => handleChangeOrder('customer.name')}
              />
            </th>
            <th>
              <ColumnHeader
                label="Usuário"
                showOrder={order.field === 'username'}
                asc={order.asc}
                onClick={() => handleChangeOrder('username')}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {exceptions.map((item) => (
            <tr key={item.id}>
              <td>{item.createdAtFormatted}</td>
              <td>{item[`application.name`]}</td>
              <td>{item.version}</td>
              <td>
                {item[`image.url`] && (
                  <InvisibleButton>
                    <Image
                      src={item[`image.url`]}
                      alt={item.message}
                      onClick={() => handleShow(item.id)}
                    />
                  </InvisibleButton>
                )}
              </td>
              <td style={{ maxWidth: 300 }}>{item.message}</td>
              <td>{item.classname}</td>
              <td>{item.stack}</td>
              <td>{item.groupFormatted}</td>
              <td>{item[`customer.name`]}</td>
              <td>{item.username}</td>
            </tr>
          ))}
        </tbody>
      </Table>

      {!loading && exceptions.length === 0 ? (
        <NoInfoToShow>Nenhuma informação a exibir.</NoInfoToShow>
      ) : (
        <Pagination
          totalPages={totalPages}
          page={page}
          setPage={setPage}
          pageLimit={pageLimit}
          setPageLimit={setPageLimit}
        />
      )}
    </BaseContainer>
  );
}

export default Exceptions;
