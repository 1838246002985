import React from 'react';
import PropTypes from 'prop-types';
import status from './status';

import { Container } from './styles';

function ApplicationStatus({ application }) {
  function getStatus() {
    return application.active ? status.ativo : status.inativo;
  }

  const applicationStatus = getStatus();

  return (
    <Container status={applicationStatus}>
      <span />
      {applicationStatus}
    </Container>
  );
}

ApplicationStatus.propTypes = {
  application: PropTypes.shape().isRequired,
};

export default ApplicationStatus;
