import React, { useState, useEffect, useRef } from 'react';
import { MdAdd, MdEdit, MdDeleteForever, MdRefresh } from 'react-icons/md';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { format, parseISO } from 'date-fns';
import { useSelector } from 'react-redux';

import api from '~/services/api';
import colors from '~/styles/colors';

import Can from '~/components/Can';
import { BaseContainer } from '~/components/BaseContainer';
import { Table } from '~/components/Table';
import { Title } from '~/components/Title';
import { NoInfoToShow } from '~/components/NoInfoToShow';
import Actions from '~/components/Actions';
import Pagination from '~/components/Pagination';
import { Button } from '~/components/Buttons/Button';
import ColumnHeader from '~/components/ColumnHeader';
import getErrorMessage from '~/lib/getErrorMessage';
import { FieldGroup, Field } from '~/components/Forms';
import Modal from '~/components/Modal';
import ContractIntegrationForm from '~/pages/ContractIntegrations/ContractIntegrationForm';
import { LinkButton } from '~/components/Buttons/LinkButton';
import SearchInput from '~/components/Inputs/SearchInput';

import { Header, FormContainer } from './styles';
import ActiveInactiveStatus from '../../../components/ActiveInactiveStatus';

function ContractIntegrationList({ contract, maxWidthPercent }) {
  const role = useSelector((state) => state.role.role);

  const [filter, setFilter] = useState('');
  const time = useRef(null);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [integrations, setIntegrations] = useState([]);
  const [order, setOrder] = useState({ field: 'created_at', asc: false });
  const [refresh, setRefresh] = useState(true);
  const [isShowingModal, setIsShowingModal] = useState(false);
  const [integrationToEdit, setIntegrationToEdit] = useState();

  useEffect(() => {
    async function loadData(f) {
      if (contract) {
        setLoading(true);

        const response = await api.get(
          `contracts/${contract.id}/integrations`,
          {
            params: {
              q: f,
              page,
              pageLimit,
              order: order.field,
              direction: order.asc ? 'ASC' : 'DESC',
            },
          }
        );

        const data = response.data.map((item) => {
          return {
            ...item,
            createdAtFormatted:
              item.createdAt &&
              format(parseISO(item.createdAt), 'dd/MM/yyyy HH:mm:ss'),
          };
        });

        setTotalPages(Number(response.headers['x-api-totalpages']));
        setTotal(Number(response.headers['x-api-total']));
        setIntegrations(data);

        setLoading(false);
      }
    }

    clearTimeout(time.current);
    time.current = setTimeout(() => {
      loadData(filter);
    }, 600);
  }, [filter, page, pageLimit, order, contract, refresh]);

  function handleFilterChange(value) {
    setPage(1);
    setFilter(value);
  }

  function reload() {
    setFilter(' ');
    setFilter('');
  }

  const handleCreate = () => {
    setIntegrationToEdit();
    setIsShowingModal(true);
  };

  async function handleDelete(id) {
    if (!window.confirm('Deseja mesmo inativar a integração?')) {
      return;
    }

    try {
      await api.delete(`contracts/${contract.id}/integrations/${id}`);
      toast.success('Integração inativada com sucesso!');
      reload();
    } catch (err) {
      toast.error(
        <div>
          Falha ao inativar integração! <br /> <br />
          {getErrorMessage(err)}
        </div>
      );
    }
  }

  const handleChangeOrder = (field) => {
    setPage(1);
    setOrder({ field, asc: field === order.field ? !order.asc : true });
  };

  const handleRefresh = () => {
    setLoading(true);
    setRefresh(!refresh);
  };

  function handleEdit(id) {
    const db = integrations.find((d) => d.id === id);
    if (db) {
      setIntegrationToEdit(db);
      setIsShowingModal(true);
    }
  }

  const handleHide = () => {
    setIsShowingModal(false);
    handleRefresh();
  };

  return (
    <BaseContainer maxWidthPercent={maxWidthPercent}>
      <Title>Integrações {total > 0 && `(${total})`}</Title>

      <Modal isShowing={isShowingModal} hide={handleHide}>
        <FormContainer>
          <ContractIntegrationForm
            contract_id={contract.id}
            integration={integrationToEdit}
            hide={handleHide}
          />
        </FormContainer>
      </Modal>

      <Header>
        <SearchInput
          placeholder="path_db, remote_db, chave"
          value={filter}
          onChange={(e) => handleFilterChange(e.target.value)}
        />

        <FieldGroup>
          <Field flex="none">
            <Button
              type="button"
              padding="6px"
              disabled={loading}
              onClick={handleRefresh}>
              <MdRefresh size={24} color={colors.iconLight} />
            </Button>
          </Field>

          <Can
            role={role}
            perform="contracts:edit"
            yes={() => (
              <Button
                primary
                type="button"
                // padding="6px"
                onClick={handleCreate}>
                <MdAdd size={24} color={colors.iconLight} />
                <span>Cadastrar</span>
              </Button>
            )}
          />
        </FieldGroup>
      </Header>
      <Table>
        <thead>
          <tr>
            <th>
              <ColumnHeader
                label="Parceiro"
                showOrder={order.field === 'partner.name'}
                asc={order.asc}
                onClick={() => handleChangeOrder('partner.name')}
              />
            </th>

            <th>
              <ColumnHeader
                label="Token de Integração"
                showOrder={order.field === 'integration_token'}
                asc={order.asc}
                onClick={() => handleChangeOrder('integration_token')}
              />
            </th>

            <th>
              <ColumnHeader
                label="Nome"
                showOrder={order.field === 'name'}
                asc={order.asc}
                onClick={() => handleChangeOrder('name')}
              />
            </th>

            <th>
              <ColumnHeader
                label="Status"
                showOrder={order.field === 'cancel_date'}
                asc={order.asc}
                onClick={() => handleChangeOrder('cancel_date')}
              />
            </th>

            <th>
              <ColumnHeader
                label="Criado em"
                showOrder={order.field === 'created_at'}
                asc={order.asc}
                onClick={() => handleChangeOrder('created_at')}
              />
            </th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {integrations.map((item) => (
            <tr key={item.id}>
              <td>{item.partner && item.partner.name}</td>
              <td>
                <Can
                  role={role}
                  perform="contracts:edit"
                  yes={() => (
                    <LinkButton onClick={() => handleEdit(item.id)}>
                      {item.integration_token}
                    </LinkButton>
                  )}
                  no={() => <>{item.integration_token}</>}
                />
              </td>
              <td>{item.name}</td>
              <td>
                <ActiveInactiveStatus
                  active={!item.cancel_date}
                  activeText="Ativo"
                  inactiveText="Cancelado"
                />
              </td>
              <td>{item.createdAtFormatted}</td>
              <td>
                <Actions height={90} width={150}>
                  <li>
                    <MdEdit color="#4D85EE" size={16} />
                    <button type="button" onClick={() => handleEdit(item.id)}>
                      Editar
                    </button>
                  </li>
                  <Can
                    role={role}
                    perform="contracts:delete"
                    yes={() => (
                      <li>
                        <MdDeleteForever color="#DE3B3B" size={16} />
                        <button
                          type="button"
                          onClick={() => handleDelete(item.id)}>
                          Inativar
                        </button>
                      </li>
                    )}
                    no={() => (
                      <li>
                        <MdDeleteForever
                          color={colors.textDisabled}
                          size={16}
                        />
                        <button type="button" disabled>
                          Inativar
                        </button>
                      </li>
                    )}
                  />
                </Actions>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {!loading && integrations.length === 0 ? (
        <NoInfoToShow>Nenhuma informação a exibir.</NoInfoToShow>
      ) : (
        <Pagination
          totalPages={totalPages}
          page={page}
          setPage={setPage}
          pageLimit={pageLimit}
          setPageLimit={setPageLimit}
        />
      )}
    </BaseContainer>
  );
}

ContractIntegrationList.propTypes = {
  contract: PropTypes.shape({
    id: PropTypes.number.isRequired,
    contract: PropTypes.shape(),
  }),
  maxWidthPercent: PropTypes.number,
};

ContractIntegrationList.defaultProps = {
  contract: null,
  maxWidthPercent: undefined,
};

export default ContractIntegrationList;
