import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

function NpsSurveyStatus({ nps_value }) {
  return <Container nps_value={nps_value}>{nps_value}</Container>;
}

NpsSurveyStatus.propTypes = {
  nps_value: PropTypes.number.isRequired,
};

export default NpsSurveyStatus;
