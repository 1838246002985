import React from 'react';
import PropTypes from 'prop-types';
import status from './status';

import { Container } from './styles';

function ActiveInactiveStatus({ active }) {
  function getStatus() {
    return active ? status.ativo : status.inativo;
  }

  const strStatus = getStatus();

  return (
    <Container status={strStatus}>
      <span />
      {strStatus}
    </Container>
  );
}

ActiveInactiveStatus.propTypes = {
  active: PropTypes.bool.isRequired,
};

export default ActiveInactiveStatus;
