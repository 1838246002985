import React, { useState, useEffect, useRef } from 'react';
import { MdAdd, MdDeleteForever, MdRefresh } from 'react-icons/md';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { format, parseISO } from 'date-fns';
import { useSelector } from 'react-redux';
import AsyncSelect from 'react-select/async';
import api from '~/services/api';
import colors from '~/styles/colors';
import Card from '~/components/Card';
import Can from '~/components/Can';
import { BaseContainer } from '~/components/BaseContainer';
import { Table } from '~/components/Table';
import { Title } from '~/components/Title';
import { NoInfoToShow } from '~/components/NoInfoToShow';
import Actions from '~/components/Actions';
import Pagination from '~/components/Pagination';
import { Button } from '~/components/Buttons/Button';
import ColumnHeader from '~/components/ColumnHeader';
import getErrorMessage from '~/lib/getErrorMessage';
import { FieldGroup, Field } from '~/components/Forms';
import SearchInput from '~/components/Inputs/SearchInput';

import { Header } from './styles';

function CommunicationsApplications({ communication, maxWidthPercent }) {
  const role = useSelector((state) => state.role.role);
  const selectInputRef = useRef();

  const [filter, setFilter] = useState('');
  const time = useRef(null);
  const timerApplication = useRef(null);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [communicationsApplications, setCommunicationsApplications] = useState(
    []
  );
  const [order, setOrder] = useState({
    field: 'application.name',
    asc: true,
  });
  const [selectedApplications, setSelectedApplications] = useState([]);
  const [refresh, setRefresh] = useState(true);

  useEffect(() => {
    async function loadCommunicationsApplications(f) {
      if (communication) {
        setLoading(true);

        const response = await api.get(
          `communications/${communication.id}/applications`,
          {
            params: {
              q: f,
              page,
              pageLimit,
              order: order.field,
              direction: order.asc ? 'ASC' : 'DESC',
            },
          }
        );

        const data = response.data.map((item) => {
          return {
            ...item,
            createdAtFormatted:
              item.createdAt &&
              format(parseISO(item.createdAt), 'dd/MM/yyyy HH:mm:ss'),
          };
        });

        setTotalPages(Number(response.headers['x-api-totalpages']));
        setTotal(Number(response.headers['x-api-total']));
        setCommunicationsApplications(data);

        setLoading(false);
      }
    }

    clearTimeout(time.current);
    time.current = setTimeout(() => {
      loadCommunicationsApplications(filter);
    }, 600);
  }, [filter, page, pageLimit, order, communication, refresh]);

  function handleFilterChange(value) {
    setPage(1);
    setFilter(value);
  }

  function reload() {
    setFilter(' ');
    setFilter('');
  }

  function clearSelectedApplications() {
    selectInputRef.current.select.select.clearValue();
  }

  async function addApplicationToCommunication(application_id) {
    try {
      if (!communication) {
        return;
      }

      await api.post(`communications/${communication.id}/applications`, {
        application_id,
      });

      toast.success('Aplicação adicionada com sucesso!');
      reload();
    } catch (err) {
      toast.error(
        <div>
          Falha ao salvar! <br /> <br />
          {getErrorMessage(err)}
        </div>
      );
    }
  }

  function handleCreate() {
    if (selectedApplications.length > 0) {
      selectedApplications.forEach((c) => {
        addApplicationToCommunication(c.value);
      });
      clearSelectedApplications();
    }
  }

  async function handleDelete(application) {
    if (
      !window.confirm(
        application
          ? 'Deseja mesmo remover a aplicação?'
          : 'Deseja mesmo remover todas as aplicações?'
      )
    ) {
      return;
    }

    try {
      await api.delete(
        application
          ? `communications/${communication.id}/applications/${application}`
          : `communications/${communication.id}/applications`
      );
      toast.success(
        application
          ? 'Aplicação removida com sucesso!'
          : 'Todos os aplicações foram desvinculadas com sucesso!'
      );
      reload();
    } catch (err) {
      toast.error(
        <div>
          Falha ao remover aplicação! <br /> <br />
          {getErrorMessage(err)}
        </div>
      );
    }
  }

  function handleChangeOrder(field) {
    setPage(1);
    setOrder({ field, asc: field === order.field ? !order.asc : true });
  }

  /**
   * Async Select (Applications)
   */

  // eslint-disable-next-line no-shadow
  async function loadApplications(filter) {
    const response = await api.get('applications', {
      params: { q: filter, active: true },
    });

    const applications = response.data.map((item) => {
      return { value: item.id, label: item.name };
    });

    return applications;
  }

  const loadApplicationOptions = (inputValue, callback) => {
    clearTimeout(timerApplication.current);
    timerApplication.current = setTimeout(async () => {
      callback(await loadApplications(inputValue));
    }, 600);
  };

  function handleChangeApplication(selectedOptions) {
    setSelectedApplications(selectedOptions || []);
  }

  function handleRefresh() {
    setLoading(true);
    setRefresh(!refresh);
  }

  return (
    <BaseContainer maxWidthPercent={maxWidthPercent}>
      <Title>Aplicações vinculadas {total > 0 && `(${total})`}</Title>

      <Header>
        <SearchInput
          placeholder="aplicação"
          value={filter}
          onChange={(e) => handleFilterChange(e.target.value)}
        />

        <FieldGroup>
          <Field flex="none">
            <Button
              type="button"
              padding="6px"
              disabled={loading}
              onClick={handleRefresh}>
              <MdRefresh size={24} color={colors.iconLight} />
            </Button>
          </Field>

          <Can
            role={role}
            perform="communications:edit"
            yes={() => (
              <Field style={{ minWidth: 300 }}>
                <AsyncSelect
                  ref={selectInputRef}
                  isMulti
                  closeMenuOnSelect={false}
                  name="applications"
                  placeholder="Selecione aplicações..."
                  cacheOptions
                  loadOptions={loadApplicationOptions}
                  defaultOptions
                  onChange={handleChangeApplication}
                  isClearable="true"
                />
              </Field>
            )}
          />

          <Can
            role={role}
            perform="communications:edit"
            yes={() => (
              <Button
                primary
                type="button"
                padding="6px"
                onClick={handleCreate}
                disabled={selectedApplications.length === 0}>
                <MdAdd size={24} color={colors.iconLight} />
              </Button>
            )}
          />

          <Can
            role={role}
            perform="communications:edit"
            yes={() => (
              <Card dropAlign="right">
                <li>
                  <MdDeleteForever size={20} color={colors.textDark} />
                  <button
                    type="button"
                    onClick={() => handleDelete()}
                    disabled={
                      !communicationsApplications ||
                      communicationsApplications.length === 0
                    }>
                    Remover todos
                  </button>
                </li>
              </Card>
            )}
          />
        </FieldGroup>
      </Header>
      <Table>
        <thead>
          <tr>
            <th>
              <ColumnHeader
                label="Aplicação"
                showOrder={order.field === 'application.name'}
                asc={order.asc}
                onClick={() => handleChangeOrder('application.name')}
              />
            </th>

            <th>
              <ColumnHeader
                label="Criado em"
                showOrder={order.field === 'created_at'}
                asc={order.asc}
                onClick={() => handleChangeOrder('created_at')}
              />
            </th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {communicationsApplications.map((item) => (
            <tr key={item.id}>
              <td>{item.application.name}</td>
              <td>{item.createdAtFormatted}</td>
              <td>
                <Actions height={90} width={150}>
                  <Can
                    role={role}
                    perform="communications:delete"
                    yes={() => (
                      <li>
                        <MdDeleteForever color="#DE3B3B" size={16} />
                        <button
                          type="button"
                          onClick={() => handleDelete(item.application_id)}>
                          Remover
                        </button>
                      </li>
                    )}
                    no={() => (
                      <li>
                        <MdDeleteForever
                          color={colors.textDisabled}
                          size={16}
                        />
                        <button type="button" disabled>
                          Remover
                        </button>
                      </li>
                    )}
                  />
                </Actions>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {!loading && communicationsApplications.length === 0 ? (
        <NoInfoToShow>Nenhuma informação a exibir.</NoInfoToShow>
      ) : (
        <Pagination
          totalPages={totalPages}
          page={page}
          setPage={setPage}
          pageLimit={pageLimit}
          setPageLimit={setPageLimit}
        />
      )}
    </BaseContainer>
  );
}

CommunicationsApplications.propTypes = {
  communication: PropTypes.shape({
    id: PropTypes.number.isRequired,
    application: PropTypes.shape(),
  }),
  maxWidthPercent: PropTypes.number,
};

CommunicationsApplications.defaultProps = {
  communication: null,
  maxWidthPercent: undefined,
};

export default CommunicationsApplications;
