import styled from 'styled-components';
import colors from '~/styles/colors';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  span {
    color: ${colors.color_4};
    font-weight: bold;
    padding: 3px 25px 3px 10px;
  }
`;

export const ImgWrapper = styled.div`
  img {
    width: 100px;
  }
`;
