import styled from 'styled-components';
import colors from '~/styles/colors';

export const Container = styled.div`
  background: ${colors.textLight};
  border-radius: 10px;
  box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.1);
  height: 100%;
`;

export const Header = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px;
`;

export const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: ${colors.color_4};
  padding: 5px;
  text-align: left;
`;

export const Content = styled.div`
  color: ${colors.color_4};
`;
