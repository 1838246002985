import styled from 'styled-components';
import colors from '~/styles/colors';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const PageLimitContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 20px;
`;

export const SelectContainer = styled.div`
  width: 80px;
`;

export const PageNavigateContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

export const PageNavigateButton = styled.button`
  background: none;
  border: 0;
`;

export const PageNavigateText = styled.span`
  padding: 0 10px;
  color: ${colors.textDarker};
`;
