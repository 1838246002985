import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
import { Form, Input } from '@rocketseat/unform';
import {
  MdDone,
  MdArrowBack,
  MdVisibility,
  MdVisibilityOff,
} from 'react-icons/md';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';

// import api from '~/services/api';
import history from '~/services/history';
import colors from '~/styles/colors';

import { BaseContainer } from '~/components/BaseContainer';
import { FormHeader } from '~/components/FormHeader';
import { Button } from '~/components/Buttons/Button';
import { Title } from '~/components/Title';
import {
  FormWrapper,
  FieldGroup,
  Field,
  FieldInlineWrapper,
} from '~/components/Forms';
import getErrorMessage from '~/lib/getErrorMessage';
// import AvatarInput from '~/components/AvatarInput';

// import { } from './styles';
import { updateProfileRequest } from '~/store/modules/user/actions';

function ProfileForm() {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.user.profile);
  const [showPass, setShowPass] = useState(false);

  useEffect(() => {
    /**
     * Go back on page refresh
     */
    if (!profile) {
      history.goBack();
    }
  }, [profile]);

  /**
   * Form
   */
  function handleClickBack() {
    history.goBack();
  }

  async function save(name, old_password, password, confirm_password) {
    const payload = {
      name,
      // email,
      old_password,
      password,
      confirm_password,
    };

    try {
      dispatch(updateProfileRequest(payload));
    } catch (err) {
      return toast.error(
        <div>
          Falha ao salvar! <br /> <br />
          {getErrorMessage(err)}
        </div>
      );
    }
  }

  function handleSubmit({ name, old_password, password, confirm_password }) {
    save(
      name,
      old_password || undefined,
      password || undefined,
      confirm_password || undefined
    );
  }

  return (
    <BaseContainer>
      <FormWrapper>
        <Form initialData={profile} onSubmit={handleSubmit}>
          <FormHeader>
            <Title>Seu Perfil</Title>
            <div>
              <Button type="button" onClick={handleClickBack}>
                <MdArrowBack size={24} color={colors.iconLight} />
                <span>Voltar</span>
              </Button>
              <Button primary type="submit">
                <MdDone size={24} color={colors.iconLight} />
                <span>Salvar</span>
              </Button>
            </div>
          </FormHeader>

          <FieldGroup>
            <Field>
              <label htmlFor="name">Nome</label>
              <Input
                name="name"
                type="text"
                maxLength="100"
                autoFocus
                autoComplete="off"
              />
            </Field>
          </FieldGroup>

          <FieldGroup>
            <Field>
              <label htmlFor="old_password">Senha Antiga</label>
              <Input
                name="old_password"
                type="password"
                maxLength="100"
                autoComplete="off"
              />
            </Field>
          </FieldGroup>

          {/* <FieldGroup>
            <Field>
              <label htmlFor="password">Nova Senha</label>
              <Input
                name="password"
                type="password"
                maxLength="100"
                autoComplete="off"
              />
            </Field>
          </FieldGroup> */}

          <FieldGroup>
            <Field>
              <label htmlFor="password">Nova Senha</label>
              <FieldInlineWrapper>
                <Input
                  style={{ flex: 1 }}
                  name="password"
                  type={showPass ? 'text' : 'password'}
                  maxLength="100"
                  autoComplete="off"
                />
                <Button
                  style={{ paddingLeft: 10, paddingRight: 5 }}
                  type="button"
                  onClick={() => setShowPass(!showPass)}>
                  {showPass ? (
                    <MdVisibility size={18} color={colors.iconLight} />
                  ) : (
                    <MdVisibilityOff size={18} color={colors.iconLight} />
                  )}
                </Button>
              </FieldInlineWrapper>
              <label htmlFor="password">
                <small>
                  * No mínimo 8 caracteres, pelo menos 1 letra maiúscula, 1
                  letra minúscula, 1 número e 1 caractere especial
                </small>
              </label>
            </Field>
          </FieldGroup>

          <FieldGroup>
            <Field>
              <label htmlFor="confirm_password">Confirmação de Senha</label>
              <Input
                name="confirm_password"
                type="password"
                maxLength="100"
                autoComplete="off"
              />
            </Field>
          </FieldGroup>
        </Form>
      </FormWrapper>
    </BaseContainer>
  );
}

// ProfileForm.propTypes = {
//   location: PropTypes.shape(),
// };

// ProfileForm.defaultProps = {
//   location: null,
// };

export default ProfileForm;
