import React from 'react';
import { Form } from '@rocketseat/unform';
import { MdClose, MdSave } from 'react-icons/md';
import PropTypes from 'prop-types';
import { FormHeader } from '~/components/FormHeader';
import { Title } from '~/components/Title';
import { FieldGroup, Field } from '~/components/Forms';
import { Button } from '~/components/Buttons/Button';
import colors from '~/styles/colors';

const ModalExport = ({ toggle, exportNps }) => {
  return (
    <Form>
      <FormHeader>
        <Title>Deseja realmente exportar as Pesquisas NPS?</Title>
      </FormHeader>
      <FieldGroup>
        <Field>
          <Button
            primary
            type="button"
            onClick={() => {
              toggle();
            }}>
            <MdClose size={24} color={colors.iconLight} />
            <span>Não</span>
          </Button>
        </Field>
        <Field>
          <Button
            primary
            type="button"
            onClick={() => {
              exportNps();
            }}
            style={{ alignContent: 'center' }}>
            <MdSave size={24} color={colors.iconLight} />
            <span>Sim</span>
          </Button>
        </Field>
      </FieldGroup>
      <FieldGroup>
        <Field style={{ alignItems: 'center' }}>
          <b>Obs: No máximo 5 mil registros são exportados por consulta!</b>
        </Field>
      </FieldGroup>
    </Form>
  );
};

ModalExport.propTypes = {
  toggle: PropTypes.func.isRequired,
  exportNps: PropTypes.func.isRequired,
};

export default ModalExport;
