import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { MdContentCopy, MdDone } from 'react-icons/md';

// import { Container } from './styles';
import { Button } from '~/components/Buttons/Button';
import colors from '~/styles/colors';

// const defaultCopySuccessMessage = 'ID copied!';

function ClipboardButton(props) {
  const { value } = props;

  const [showCopySuccess, setCopySuccess] = useState(false);

  function fallbackToCopy(text) {
    if (window.clipboardData && window.clipboardData.setData) {
      // IE specific code path to prevent textarea being shown while dialog is visible.
      return window.clipboardData.setData('Text', text);
    }
    if (
      document.queryCommandSupported &&
      document.queryCommandSupported('copy')
    ) {
      const textarea = document.createElement('textarea');
      textarea.innerText = text;

      const parentElement = document.getElementById('copy');
      if (!parentElement) {
        return;
      }
      parentElement.appendChild(textarea);
      textarea.style.position = 'fixed'; // Prevent scrolling to bottom of page in MS Edge.
      textarea.select();
      try {
        setCopySuccess(true);
        document.execCommand('copy'); // Security exception may be thrown by some browsers.
      } catch (ex) {
        // console.tron.log('Copy to clipboard failed.', ex);
        return false;
      } finally {
        parentElement.removeChild(textarea);
      }
    }
  }

  const copyID = () => {
    if (!navigator.clipboard) {
      fallbackToCopy(value);
      return;
    }
    navigator.clipboard.writeText(value);
    setCopySuccess(true);
    setTimeout(() => {
      setCopySuccess(false);
    }, 1000);
  };

  return showCopySuccess ? (
    // <Button style={{ paddingLeft: 10, paddingRight: 5 }} type="button" disabled>
    //   <MdDone size={20} color={colors.iconLight} />
    // </Button>

    <span id="copy">
      <Button
        iconSpace="0"
        padding="5px"
        type="button"
        style={{ background: 'none' }}
        onClick={copyID}>
        <MdDone size={16} color={colors.color_2} />
      </Button>
    </span>
  ) : (
    <span id="copy">
      <Button
        iconSpace="0"
        padding="5px"
        type="button"
        style={{ background: 'none' }}
        onClick={copyID}>
        <MdContentCopy size={16} color={colors.color_2} />
      </Button>
    </span>
  );
}

ClipboardButton.propTypes = {
  value: PropTypes.string.isRequired,
};

// ClipboardButton.defaultProps = {
//   url: null,
// };

export default ClipboardButton;
