import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { MdArrowDropDown } from 'react-icons/md';
import colors from '~/styles/colors';

import { Button } from '~/components/Buttons/Button';
import { Container, DropdownContent, TextWrapper } from './styles';

class Card extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]).isRequired,
    dropAlign: PropTypes.string,
  };

  static defaultProps = {
    dropAlign: 'left',
  };

  constructor() {
    super();

    this.state = { showMenu: false };

    this.showMenu = this.showMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
  }

  showMenu(event) {
    event.preventDefault();

    this.setState({ showMenu: true }, () => {
      document.addEventListener('click', this.closeMenu);
    });
  }

  closeMenu(event) {
    // Não fecha o menu se clicar dentro
    // if (!this.dropdownMenu.contains(event.target)) {
    this.setState({ showMenu: false }, () => {
      document.removeEventListener('click', this.closeMenu);
    });
    // }
  }

  render() {
    const { children, dropAlign } = this.props;
    const { showMenu } = this.state;

    return (
      <Container>
        <Button type="button" onClick={this.showMenu}>
          <MdArrowDropDown size={24} color={colors.iconLight} />
          <TextWrapper>Opções</TextWrapper>
        </Button>

        <DropdownContent
          dropAlign={dropAlign}
          showMenu={showMenu}
          ref={(el) => {
            this.dropdownMenu = el;
          }}>
          {children}
        </DropdownContent>
      </Container>
    );
  }
}

export default Card;
