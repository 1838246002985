import styled from 'styled-components';

import colors from '~/styles/colors';

export const Container = styled.div`
  align-self: center;
  border: 2px dashed #ccc;
  width: 400px;
  height: 400px;
  border-radius: 4px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  strong {
    color: ${colors.textDisabled};
  }

  label {
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

    &:hover {
      opacity: ${(props) => (props.disabled ? '1' : '0.7')};
    }

    img {
      width: 400px;
      height: 400px;
      border-radius: 4px;
      background: ${colors.textDisabled};
    }

    input {
      display: none;
    }
  }
`;

// export const SubContainer = styled.div`
//   /* background: lightblue; */
//   position: relative;
//   width: 400px;
//   /* height: 3px; */

//   button {
//     position: absolute;
//     right: 12px;
//     bottom: 10px;
//   }
// `;
