import styled from 'styled-components';
import colors from '~/styles/colors';

export const Header = styled.header`
  margin-top: 30px;
  padding: 10px 0;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Cell = styled.div`
  /* background: lightseagreen; */
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: center; */
`;

export const CellSpaced = styled.div`
  /* background: lightcoral; */
  /* max-width: 95%; */
`;

export const Today = styled.div`
  background: ${colors.color_2};
  border-radius: 10px;
  color: #fff;
  font-size: 9px;
  padding: 3px 10px;
  margin-left: 10px;
`;

export const Image = styled.img`
  width: 120px;
  padding: 10px;
`;
