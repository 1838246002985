import React, { useState, useEffect, useRef } from 'react';
import { MdAdd, MdDeleteForever, MdRefresh } from 'react-icons/md';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { format, parseISO } from 'date-fns';
import { useSelector } from 'react-redux';

import api from '~/services/api';
import colors from '~/styles/colors';

import Can from '~/components/Can';
import ClipboardButton from '~/components/ClipboardButton';
import { BaseContainer } from '~/components/BaseContainer';
import { Table } from '~/components/Table';
import { Title } from '~/components/Title';
import { NoInfoToShow } from '~/components/NoInfoToShow';
import Actions from '~/components/Actions';
import Pagination from '~/components/Pagination';
import { Button } from '~/components/Buttons/Button';
import SearchInput from '~/components/Inputs/SearchInput';
import ColumnHeader from '~/components/ColumnHeader';
import getErrorMessage from '~/lib/getErrorMessage';
import LicenseStatus from './LicenseStatus';

import { Header, CellWrapper } from './styles';

function Licenses({ contract, maxWidthPercent }) {
  const role = useSelector((state) => state.role.role);

  const [filter, setFilter] = useState('');
  const time = useRef(null);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [licenses, setLicenses] = useState([]);
  const [order, setOrder] = useState({ field: 'created_at', asc: false });
  const [refresh, setRefresh] = useState(true);

  useEffect(() => {
    async function loadLicenses(f) {
      if (contract) {
        setLoading(true);

        const response = await api.get(`contracts/${contract.id}/licenses`, {
          params: {
            q: f,
            page,
            pageLimit,
            order: order.field,
            direction: order.asc ? 'ASC' : 'DESC',
          },
        });

        const data = response.data.map((license) => {
          return {
            ...license,

            lastActivationFormatted:
              license.last_activation &&
              format(parseISO(license.last_activation), 'dd/MM/yyyy HH:mm:ss'),
            lastAccessFormatted:
              license.last_access &&
              format(parseISO(license.last_access), 'dd/MM/yyyy HH:mm:ss'),
            createdAtFormatted:
              license.createdAt &&
              format(parseISO(license.createdAt), 'dd/MM/yyyy HH:mm:ss'),
          };
        });

        setTotalPages(Number(response.headers['x-api-totalpages']));
        setTotal(Number(response.headers['x-api-total']));
        setLicenses(data);

        setLoading(false);
      }
    }

    clearTimeout(time.current);
    time.current = setTimeout(() => {
      loadLicenses(filter);
    }, 600);
  }, [filter, page, pageLimit, order, contract, refresh]);

  function handleFilterChange(value) {
    setPage(1);
    setFilter(value);
  }

  function reload() {
    setFilter(' ');
    setFilter('');
  }

  async function createNewLicense({ fill_licenses }) {
    const payload = { fill_licenses };

    try {
      if (!contract) {
        return;
      }

      await api.post(`contracts/${contract.id}/licenses`, payload); // Edit

      toast.success('Licença gerada com sucesso!');
      reload();
    } catch (err) {
      toast.error(
        <div>
          Falha ao salvar! <br /> <br />
          {getErrorMessage(err)}
        </div>
      );
    }
  }

  const handleCreate = ({ fill_licenses }) => {
    if (
      !window.confirm(
        'Novas licenças serão geradas e incluídas ao contrato. \n \n Deseja continuar?'
      )
    ) {
      return;
    }

    createNewLicense({ fill_licenses });
  };

  async function handleIncrementActivationLimit(license) {
    if (!window.confirm('Deseja mesmo aumentar o limite de ativações?')) {
      return;
    }

    try {
      await api.put(
        `contracts/${contract.id}/licenses/${license.license_key}`,
        { activation_limit: license.activation_limit + 5 }
      );
      toast.success('Limite de ativações aumentado com sucesso!');
      reload();
    } catch (err) {
      toast.error(
        <div>
          Falha ao aumentar o limite de ativações! <br /> <br />
          {getErrorMessage(err)}
        </div>
      );
    }
  }

  async function handleDelete(license) {
    if (!window.confirm('Deseja mesmo cancelar a licença?')) {
      return;
    }

    try {
      await api.delete(
        `contracts/${contract.id}/licenses/${license.license_key}`
      );
      toast.success('Licença cancelada com sucesso!');
      reload();
    } catch (err) {
      toast.error(
        <div>
          Falha ao cancelar licença! <br /> <br />
          {getErrorMessage(err)}
        </div>
      );
    }
  }

  async function handleReactivate(license) {
    if (!window.confirm('Deseja mesmo descancelar a licença?')) {
      return;
    }

    try {
      await api.put(
        `contracts/${contract.id}/licenses/${license.license_key}`,
        { cancel_date: null }
      );
      toast.success('Licença descancelada com sucesso!');
      reload();
    } catch (err) {
      toast.error(
        <div>
          Falha ao descancelar licença! <br /> <br />
          {getErrorMessage(err)}
        </div>
      );
    }
  }

  function handleChangeOrder(field) {
    setPage(1);
    setOrder({ field, asc: field === order.field ? !order.asc : true });
  }

  const handleRefresh = () => {
    setLoading(true);
    setRefresh(!refresh);
  };

  return (
    <BaseContainer maxWidthPercent={maxWidthPercent}>
      <Title>Licenças {total > 0 && `(${total})`}</Title>

      <Header>
        <SearchInput
          placeholder="chave da licença"
          value={filter}
          onChange={(e) => handleFilterChange(e.target.value)}
        />

        <CellWrapper>
          <Button
            type="button"
            padding="6px"
            disabled={loading}
            onClick={handleRefresh}>
            <MdRefresh size={24} color={colors.iconLight} />
          </Button>

          <Can
            role={role}
            perform="licenses:create"
            yes={() => (
              <Button type="button" primary onClick={handleCreate}>
                <MdAdd size={24} color={colors.iconLight} />
                <span>Cadastrar</span>
              </Button>
            )}
          />
        </CellWrapper>
      </Header>
      <Table>
        <thead>
          <tr>
            <th>
              <ColumnHeader
                label="Chave da Licença"
                showOrder={order.field === 'license_key'}
                asc={order.asc}
                onClick={() => handleChangeOrder('license_key')}
              />
            </th>
            <th>
              <ColumnHeader
                label="Ativações"
                showOrder={order.field === 'activation_count'}
                asc={order.asc}
                onClick={() => handleChangeOrder('activation_count')}
              />
            </th>
            <th>
              <ColumnHeader
                label="Última Ativação"
                showOrder={order.field === 'last_activation'}
                asc={order.asc}
                onClick={() => handleChangeOrder('last_activation')}
              />
            </th>
            <th>
              <ColumnHeader
                label="Acessos"
                showOrder={order.field === 'access_count'}
                asc={order.asc}
                onClick={() => handleChangeOrder('access_count')}
              />
            </th>
            <th>
              <ColumnHeader
                label="Último Acesso"
                showOrder={order.field === 'last_access'}
                asc={order.asc}
                onClick={() => handleChangeOrder('last_access')}
              />
            </th>
            <th>
              <ColumnHeader
                label="Status"
                showOrder={order.field === 'cancel_date'}
                asc={order.asc}
                onClick={() => handleChangeOrder('cancel_date')}
              />
            </th>
            <th>Mais informações</th>
            <th>
              <ColumnHeader
                label="Criado em"
                showOrder={order.field === 'created_at'}
                asc={order.asc}
                onClick={() => handleChangeOrder('created_at')}
              />
            </th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {licenses.map((item) => (
            <tr key={item.id}>
              <td>
                <CellWrapper>
                  {item.license_key}
                  <div>
                    <ClipboardButton value={item.license_key} />
                  </div>
                </CellWrapper>
              </td>
              <td>
                <CellWrapper>
                  <div style={{ width: 60 }}>
                    {item.activation_limit
                      ? `${item.activation_count || 0} / ${
                          item.activation_limit
                        }`
                      : `${item.activation_count || 0}`}
                  </div>

                  {item.activation_limit ? (
                    <Can
                      role={role}
                      perform="licenses:edit"
                      yes={() => (
                        <Button
                          iconSpace="0"
                          padding="5px 7px"
                          data-tip
                          data-for={`block_updates_${item.id}`}
                          onClick={() => handleIncrementActivationLimit(item)}>
                          <MdAdd color={colors.iconLight} size={16} />5
                        </Button>
                      )}
                    />
                  ) : null}
                </CellWrapper>
              </td>
              <td>{item.lastActivationFormatted}</td>
              <td>{item.access_count}</td>

              <td>
                {item.last_username
                  ? `${item.lastAccessFormatted} (${item.last_username})`
                  : item.lastAccessFormatted}
              </td>

              <td>
                <LicenseStatus license={item} />
              </td>
              <td>{item.installation_info}</td>
              <td>{item.createdAtFormatted}</td>
              <td>
                <Actions height={90} width={150}>
                  {item.cancel_date ? (
                    <Can
                      role={role}
                      perform="licenses:delete"
                      yes={() => (
                        <li>
                          <MdRefresh
                            color={colors.statusBlueStrong}
                            size={16}
                          />
                          <button
                            type="button"
                            onClick={() => handleReactivate(item)}>
                            Descancelar
                          </button>
                        </li>
                      )}
                      no={() => (
                        <li>
                          <MdRefresh color={colors.textDisabled} size={16} />
                          <button type="button" disabled>
                            Descancelar
                          </button>
                        </li>
                      )}
                    />
                  ) : (
                    <Can
                      role={role}
                      perform="licenses:delete"
                      yes={() => (
                        <li>
                          <MdDeleteForever
                            color={colors.statusRedStrong}
                            size={16}
                          />
                          <button
                            type="button"
                            onClick={() => handleDelete(item)}>
                            Cancelar
                          </button>
                        </li>
                      )}
                      no={() => (
                        <li>
                          <MdDeleteForever
                            color={colors.textDisabled}
                            size={16}
                          />
                          <button type="button" disabled>
                            Cancelar
                          </button>
                        </li>
                      )}
                    />
                  )}
                </Actions>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {!loading && licenses.length === 0 ? (
        <NoInfoToShow>Nenhuma informação a exibir.</NoInfoToShow>
      ) : (
        <Pagination
          totalPages={totalPages}
          page={page}
          setPage={setPage}
          pageLimit={pageLimit}
          setPageLimit={setPageLimit}
        />
      )}
    </BaseContainer>
  );
}

Licenses.propTypes = {
  contract: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
  maxWidthPercent: PropTypes.number,
};

Licenses.defaultProps = {
  contract: null,
  maxWidthPercent: undefined,
};

export default Licenses;
