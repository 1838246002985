import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { format, parseISO } from 'date-fns';
import { MdRefresh } from 'react-icons/md';

import api from '~/services/api';
import history from '~/services/history';

import { BaseContainer } from '~/components/BaseContainer';
import { Button } from '~/components/Buttons/Button';
import { FieldGroup } from '~/components/Forms';
import { Table } from '~/components/Table';
import { Title } from '~/components/Title';
import { NoInfoToShow } from '~/components/NoInfoToShow';
import ColumnHeader from '~/components/ColumnHeader';
import Pagination from '~/components/Pagination';
import SearchInput from '~/components/Inputs/SearchInput';

import checkPermission from '~/lib/checkPermission';
import getErrorMessage from '~/lib/getErrorMessage';
import colors from '~/styles/colors';

import { Header } from './styles';

function ApplicationMessages() {
  const role = useSelector((state) => state.role.role);

  const [filter, setFilter] = useState('');
  const [applicationMessages, setApplicationMessages] = useState([]);
  const time = useRef(null);
  const [totalPages, setTotalPages] = useState(1);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState({ field: 'created_at', asc: false });
  const [refresh, setRefresh] = useState(true);

  useEffect(() => {
    async function loadApplicationMessages(f) {
      try {
        setLoading(true);
        const response = await api.get('application-messages', {
          params: {
            q: f,
            page,
            pageLimit,
            order: order.field,
            direction: order.asc ? 'ASC' : 'DESC',
          },
        });

        const data = response.data.map((appMsg) => {
          return {
            ...appMsg,
            createdAtFormatted:
              appMsg.createdAt &&
              format(parseISO(appMsg.createdAt), 'dd/MM/yyyy HH:mm'),
          };
        });

        setTotalPages(Number(response.headers['x-api-totalpages']));
        setTotal(Number(response.headers['x-api-total']));
        setApplicationMessages(data);
        setLoading(false);
      } catch (err) {
        toast.error(
          <div>
            Falha ao carregar dados! <br /> <br />
            {getErrorMessage(err)}
          </div>
        );
      }
      return true;
    }

    /**
     * Check permissions
     */
    if (role && !checkPermission(role, 'application_messages:visit')) {
      history.push('/denied');
      return;
    }

    clearTimeout(time.current);
    time.current = setTimeout(() => {
      loadApplicationMessages(filter);
    }, 600);
  }, [filter, page, pageLimit, order, role, refresh]);

  function handleFilterChange(value) {
    setPage(1);
    setFilter(value);
  }

  function handleChangeOrder(field) {
    setPage(1);
    setOrder({ field, asc: field === order.field ? !order.asc : true });
  }

  function handleRefresh() {
    setLoading(true);
    setRefresh(!refresh);
  }

  return (
    <BaseContainer>
      <Title>Avisos {total > 0 && `(${total})`}</Title>

      <Header>
        <SearchInput
          placeholder="mensagem, cliente, aplicação"
          value={filter}
          onChange={(e) => handleFilterChange(e.target.value)}
        />
        <FieldGroup>
          <Button
            type="button"
            padding="6px"
            disabled={loading}
            onClick={handleRefresh}>
            <MdRefresh size={24} color={colors.iconLight} />
          </Button>
        </FieldGroup>
      </Header>
      <Table>
        <thead>
          <tr>
            <th>
              <ColumnHeader
                label="Mensagem"
                showOrder={order.field === 'message'}
                asc={order.asc}
                onClick={() => handleChangeOrder('message')}
              />
            </th>
            <th>
              <ColumnHeader
                label="Cliente"
                showOrder={order.field === 'customer.name'}
                asc={order.asc}
                onClick={() => handleChangeOrder('customer.name')}
              />
            </th>
            <th>
              <ColumnHeader
                label="Aplicação"
                showOrder={order.field === 'application.name'}
                asc={order.asc}
                onClick={() => handleChangeOrder('application.name')}
              />
            </th>
            <th>
              <ColumnHeader
                label="Criado em"
                showOrder={order.field === 'created_at'}
                asc={order.asc}
                onClick={() => handleChangeOrder('created_at')}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {applicationMessages.map((item) => (
            <tr key={item.id}>
              <td>{item.message}</td>
              <td>{item[`customer.name`]}</td>
              <td>{item[`application.name`]}</td>
              <td>{item.createdAtFormatted}</td>
            </tr>
          ))}
        </tbody>
      </Table>

      {!loading && applicationMessages.length === 0 ? (
        <NoInfoToShow>Nenhuma informação a exibir.</NoInfoToShow>
      ) : (
        <Pagination
          totalPages={totalPages}
          page={page}
          setPage={setPage}
          pageLimit={pageLimit}
          setPageLimit={setPageLimit}
        />
      )}
    </BaseContainer>
  );
}

export default ApplicationMessages;
