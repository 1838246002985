import styled from 'styled-components';
import colors from '~/styles/colors';

export const Container = styled.div`
  padding: 10px;
  color: ${colors.textDark};
  line-height: 1.8;
  min-width: 400px;
`;

export const Image = styled.img`
  max-width: 70%;
  /* padding: 10px; */
`;
