import React, { useState, useEffect, useRef } from 'react';
import { MdRefresh } from 'react-icons/md';
import PropTypes from 'prop-types';

import api from '~/services/api';
import colors from '~/styles/colors';

import { BaseContainer } from '~/components/BaseContainer';
import { Table } from '~/components/Table';
import { Title } from '~/components/Title';
import { NoInfoToShow } from '~/components/NoInfoToShow';
import Pagination from '~/components/Pagination';
import { Button } from '~/components/Buttons/Button';
import ColumnHeader from '~/components/ColumnHeader';
import { FieldGroup, Field } from '~/components/Forms';
import SearchInput from '~/components/Inputs/SearchInput';

import ContractStatus from '../../../Contracts/ContractList/ContractStatus';

import { Header } from './styles';

function ModuleContractsList({ module, maxWidthPercent }) {
  // const role = useSelector((state) => state.role.role);

  const [filter, setFilter] = useState('');
  const time = useRef(null);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [contracts, setContracts] = useState([]);
  const [order, setOrder] = useState({ field: 'description', asc: true });
  const [refresh, setRefresh] = useState(true);
  // const [contractToEdit, setContractToEdit] = useState();

  useEffect(() => {
    async function loadData(f) {
      if (module) {
        setLoading(true);

        const response = await api.get(`modules/${module.id}/contracts`, {
          params: {
            q: f,
            page,
            pageLimit,
            order: order.field,
            direction: order.asc ? 'ASC' : 'DESC',
          },
        });

        // const data = response.data.map((item) => {
        //   return {
        //     ...item,
        //     createdAtFormatted:
        //       item.createdAt &&
        //       format(parseISO(item.createdAt), 'dd/MM/yyyy HH:mm:ss'),
        //   };
        // });

        setTotalPages(Number(response.headers['x-api-totalpages']));
        setTotal(Number(response.headers['x-api-total']));
        setContracts(response.data);

        setLoading(false);
      }
    }

    clearTimeout(time.current);
    time.current = setTimeout(() => {
      loadData(filter);
    }, 600);
  }, [filter, page, pageLimit, order, module, refresh]);

  function handleFilterChange(value) {
    setPage(1);
    setFilter(value);
  }

  // function reload() {
  //   setFilter(' ');
  //   setFilter('');
  // }

  // const handleCreate = () => {
  //   setContractToEdit();
  //   setIsShowingModal(true);
  // };

  // async function handleDelete(id) {
  //   if (!window.confirm('Deseja mesmo inativar a integração?')) {
  //     return;
  //   }

  //   try {
  //     await api.delete(`contracts/${contract.id}/contracts/${id}`);
  //     toast.success('Integração inativada com sucesso!');
  //     reload();
  //   } catch (err) {
  //     toast.error(
  //       <div>
  //         Falha ao inativar integração! <br /> <br />
  //         {getErrorMessage(err)}
  //       </div>
  //     );
  //   }
  // }

  const handleChangeOrder = (field) => {
    setPage(1);
    setOrder({ field, asc: field === order.field ? !order.asc : true });
  };

  const handleRefresh = () => {
    setLoading(true);
    setRefresh(!refresh);
  };

  // function handleEdit(id) {
  //   const db = contracts.find((d) => d.id === id);
  //   if (db) {
  //     setContractToEdit(db);
  //     setIsShowingModal(true);
  //   }
  // }

  return (
    <BaseContainer maxWidthPercent={maxWidthPercent}>
      <Title>Contratos {total > 0 && `(${total})`}</Title>

      {/* <Modal isShowing={isShowingModal} hide={handleHide}>
        <FormContainer>
          <ModuleContractsList module={module} hide={handleHide} />
        </FormContainer>
      </Modal> */}

      <Header>
        <SearchInput
          placeholder="descrição, contract token, alias"
          value={filter}
          onChange={(e) => handleFilterChange(e.target.value)}
        />

        <FieldGroup>
          <Field flex="none">
            <Button
              type="button"
              padding="6px"
              disabled={loading}
              onClick={handleRefresh}>
              <MdRefresh size={24} color={colors.iconLight} />
            </Button>
          </Field>

          {/* <Can
            role={role}
            perform="contracts:edit"
            yes={() => (
              <Button
                primary
                type="button"
                // padding="6px"
                onClick={handleCreate}>
                <MdAdd size={24} color={colors.iconLight} />
                <span>Cadastrar</span>
              </Button>
            )}
          /> */}
        </FieldGroup>
      </Header>
      <Table>
        <thead>
          <tr>
            <th>
              <ColumnHeader
                label="Descrição"
                showOrder={order.field === 'description'}
                asc={order.asc}
                onClick={() => handleChangeOrder('description')}
              />
            </th>
            <th>
              <ColumnHeader
                label="Alias"
                showOrder={order.field === 'alias'}
                asc={order.asc}
                onClick={() => handleChangeOrder('alias')}
              />
            </th>
            <th>
              <ColumnHeader
                label="Contract Token"
                showOrder={order.field === 'contract_token'}
                asc={order.asc}
                onClick={() => handleChangeOrder('contract_token')}
              />
            </th>
            <th>
              <ColumnHeader
                label="Status"
                showOrder={order.field === 'status'}
                asc={order.asc}
                onClick={() => handleChangeOrder('status')}
              />
            </th>
            {/* <th>Ações</th> */}
          </tr>
        </thead>
        <tbody>
          {contracts.map((item) => (
            <tr key={item.id}>
              {/* <td>
                <Can
                  role={role}
                  perform="contracts:edit"
                  yes={() => (
                    <LinkButton onClick={() => handleEdit(item.id)}>
                      {item.integration_token}
                    </LinkButton>
                  )}
                  no={() => <>{item.integration_token}</>}
                />
              </td> */}
              <td>{item.description}</td>
              <td>{item.alias}</td>
              <td>{item.contract_token}</td>
              <td>
                <ContractStatus status={item.status} />
              </td>
              {/* <td>
                <Actions height={90} width={150}>
                  <li>
                    <MdEdit color="#4D85EE" size={16} />
                    <button type="button" onClick={() => handleEdit(item.id)}>
                      Editar
                    </button>
                  </li>
                  <Can
                    role={role}
                    perform="contracts:delete"
                    yes={() => (
                      <li>
                        <MdDeleteForever color="#DE3B3B" size={16} />
                        <button
                          type="button"
                          onClick={() => handleDelete(item.id)}>
                          Inativar
                        </button>
                      </li>
                    )}
                    no={() => (
                      <li>
                        <MdDeleteForever
                          color={colors.textDisabled}
                          size={16}
                        />
                        <button type="button" disabled>
                          Inativar
                        </button>
                      </li>
                    )}
                  />
                </Actions>
              </td> */}
            </tr>
          ))}
        </tbody>
      </Table>

      {!loading && contracts.length === 0 ? (
        <NoInfoToShow>Nenhuma informação a exibir.</NoInfoToShow>
      ) : (
        <Pagination
          totalPages={totalPages}
          page={page}
          setPage={setPage}
          pageLimit={pageLimit}
          setPageLimit={setPageLimit}
        />
      )}
    </BaseContainer>
  );
}

ModuleContractsList.propTypes = {
  module: PropTypes.shape({
    id: PropTypes.number.isRequired,
    // contract: PropTypes.shape(),
  }),
  maxWidthPercent: PropTypes.number,
};

ModuleContractsList.defaultProps = {
  module: null,
  maxWidthPercent: undefined,
};

export default ModuleContractsList;
