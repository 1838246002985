import styled from 'styled-components';

export const InvisibleButton = styled.button`
  background: none;
  border: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
