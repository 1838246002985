import styled from 'styled-components';

const promoter_bg = '#DFF0DF';
const neutral_bg = '#F0F0DF';
const detractor_bg = '#FAB0B0';

const promoter = '#2CA42B';
const neutral = '#C1BC35';
const detractor = '#DE3B3B';

export const Container = styled.div`
  background: ${(props) => {
    switch (props.nps_value) {
      case 10:
        return promoter_bg;
      case 9:
        return promoter_bg;
      case 8:
        return neutral_bg;
      case 7:
        return neutral_bg;
      case 6:
        return detractor_bg;
      case 5:
        return detractor_bg;
      case 4:
        return detractor_bg;
      case 3:
        return detractor_bg;
      case 2:
        return detractor_bg;
      case 1:
        return detractor_bg;
      case 0:
        return detractor_bg;
      default:
        break;
    }
  }};

  color: ${(props) => {
    switch (props.nps_value) {
      case 10:
        return promoter;
      case 9:
        return promoter;
      case 8:
        return neutral;
      case 7:
        return neutral;
      case 6:
        return detractor;
      case 5:
        return detractor;
      case 4:
        return detractor;
      case 3:
        return detractor;
      case 2:
        return detractor;
      case 1:
        return detractor;
      case 0:
        return detractor;
      default:
        break;
    }
  }};

  border-radius: 50%;
  width: 34px;
  height: 34px;
  padding: 10px;
  text-align: center;
  font-weight: bold;
  display: inline-block;
`;
