import React, { useState, useEffect, useRef } from 'react';
import AsyncSelect from 'react-select/async';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Form, Input, Check } from '@rocketseat/unform';
import {
  MdDone,
  MdArrowBack,
  MdVisibility,
  MdVisibilityOff,
} from 'react-icons/md';
import { toast } from 'react-toastify';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { useSelector } from 'react-redux';

import api from '~/services/api';
import history from '~/services/history';
import colors from '~/styles/colors';

import HistoryList from '~/components/HistoryList';
import { BaseContainer } from '~/components/BaseContainer';
import { FormHeader } from '~/components/FormHeader';
import { Button } from '~/components/Buttons/Button';
import { Title } from '~/components/Title';
import {
  FormWrapper,
  FieldGroup,
  Field,
  FieldInlineWrapper,
} from '~/components/Forms';
// import AvatarInput from '~/components/AvatarInput';

import checkPermission from '~/lib/checkPermission';
import getErrorMessage from '~/lib/getErrorMessage';

// import { } from './styles';

function UserForm({ location }) {
  const time = useRef(null);
  const [user] = useState(location.user);
  const [selectedRole, setSelectedRole] = useState(user && user.role);
  const isEditing = history.location.pathname === '/users/edit';
  const [showPass, setShowPass] = useState(false);

  /**
   * Permissions
   */
  const userRole = useSelector((state) => state.role.role);
  const denied = !isEditing && !checkPermission(userRole, 'users:create');

  useEffect(() => {
    /**
     * Redirect if permission denied
     */
    if (denied) {
      history.push('/denied');
      return;
    }

    /**
     * Go back on page refresh
     */
    if (isEditing && !user) {
      history.goBack();
    }
  }, [user, isEditing, denied]);

  /**
   * Form
   */
  const handleClickBack = () => {
    history.goBack();
  };

  async function save(name, email, role, password, active) {
    const payload = {
      name,
      email,
      role,
      password,
      active,
    };

    try {
      const schema = Yup.object().shape({
        name: Yup.string().required('Por favor, informe o nome do usuário.'),
        email: Yup.string().required('Por favor, informe o e-mail do usuário.'),
      });
      try {
        await schema.validate(payload, { abortEarly: false });
      } catch (err) {
        return toast.error(err.errors[0]);
      }

      if (user) {
        await api.put(`users/${user.id}`, payload); // Edit
      } else {
        await api.post('users', payload); // Create
      }

      toast.success(
        `Cadastro ${isEditing ? 'alterado' : 'realizado'} com sucesso!`
      );

      return history.push('/users');
    } catch (err) {
      return toast.error(
        <div>
          Falha ao salvar! <br /> <br />
          {getErrorMessage(err)}
        </div>
      );
    }
  }

  const handleSubmit = ({ name, email, password, active }) => {
    if (!isEditing && !checkPermission(userRole, 'users:create')) {
      return;
    }
    if (isEditing && !checkPermission(userRole, 'users:edit')) {
      return;
    }

    save(name, email, selectedRole, password, active);
  };

  /**
   * Async Select
   */
  async function loadRoles(filter) {
    const response = await api.get('users/roles', {
      params: { q: filter },
    });

    const roles = response.data.map((role) => {
      return { value: role, label: role };
    });

    return roles;
  }

  const loadRoleOptions = (inputValue, callback) => {
    clearTimeout(time.current);
    time.current = setTimeout(async () => {
      callback(await loadRoles(inputValue));
    }, 600);
  };

  const handleChangeRole = (selectedOptions) => {
    setSelectedRole(selectedOptions.value);
  };

  return (
    <>
      <BaseContainer>
        <FormWrapper>
          <Form
            onSubmit={handleSubmit}
            initialData={{
              name: user && user.name,
              email: user && user.email,
              role: user && user.role,
              active: user ? user.active : true,
            }}>
            <FormHeader>
              <Title>Cadastro de Usuários</Title>
              <div>
                <Button type="button" onClick={handleClickBack}>
                  <MdArrowBack size={24} color={colors.iconLight} />
                  <span>Voltar</span>
                </Button>
                <Button
                  primary
                  type="submit"
                  disabled={
                    isEditing && !checkPermission(userRole, 'users:edit')
                  }>
                  <MdDone size={24} color={colors.iconLight} />
                  <span>Salvar</span>
                </Button>
              </div>
            </FormHeader>

            <FieldGroup>
              <Field>
                <label htmlFor="name">Nome</label>
                <Input
                  name="name"
                  type="text"
                  maxLength="100"
                  autoFocus
                  autoComplete="off"
                />
              </Field>
            </FieldGroup>

            <FieldGroup>
              <Field>
                <label htmlFor="email">E-Mail</label>
                <Input
                  name="email"
                  type="email"
                  maxLength="100"
                  autoComplete="off"
                  disabled={isEditing}
                />
              </Field>
            </FieldGroup>

            {!isEditing && (
              <FieldGroup>
                <Field>
                  <label htmlFor="password">Senha</label>
                  <FieldInlineWrapper>
                    <Input
                      style={{ flex: 1 }}
                      name="password"
                      type={showPass ? 'text' : 'password'}
                      maxLength="100"
                      autoComplete="off"
                    />
                    <Button
                      style={{ paddingLeft: 10, paddingRight: 5 }}
                      type="button"
                      onClick={() => setShowPass(!showPass)}>
                      {showPass ? (
                        <MdVisibility size={18} color={colors.iconLight} />
                      ) : (
                        <MdVisibilityOff size={18} color={colors.iconLight} />
                      )}
                    </Button>
                  </FieldInlineWrapper>
                  <label htmlFor="password">
                    <small>
                      {`* No mínimo 8 caracteres, pelo menos 1 letra maiúscula, 1
                      letra minúscula, 1 número e 1 desses caracteres especiais: !@#$%^&*`}
                    </small>
                  </label>
                </Field>
              </FieldGroup>
            )}

            <FieldGroup>
              <Field>
                <label htmlFor="role">Perfil:</label>
                <AsyncSelect
                  name="role"
                  placeholder="Selecione um perfil..."
                  cacheOptions
                  loadOptions={loadRoleOptions}
                  defaultOptions
                  onChange={handleChangeRole}
                  defaultValue={
                    user && {
                      value: user.role,
                      label: user.role,
                    }
                  }
                  required
                />
              </Field>
            </FieldGroup>

            <FieldGroup>
              <Field flex="none">
                <label htmlFor="active">Ativo</label>
                <Check name="active" />
              </Field>
            </FieldGroup>
          </Form>
        </FormWrapper>

        <Field>
          <Tabs>
            <TabList>
              <Tab>Histórico</Tab>
            </TabList>

            <TabPanel>
              {user && (
                <HistoryList id={user.id} route="users" maxWidthPercent={100} />
              )}
            </TabPanel>
          </Tabs>
        </Field>
      </BaseContainer>
    </>
  );
}

UserForm.propTypes = {
  location: PropTypes.shape(),
};

UserForm.defaultProps = {
  location: null,
};

export default UserForm;
