import React from 'react';
import PropTypes from 'prop-types';
import status from './status';

import { Container } from './styles';

function LicenseStatus({ license }) {
  function getStatus() {
    if (license.cancel_date) {
      return status.cancelado;
    }

    if (license.activation_count > 0) {
      return status.ativado;
    }

    return status.pendente;
  }

  const licenseStatus = getStatus();

  return (
    <Container status={licenseStatus}>
      <span />
      {licenseStatus}
    </Container>
  );
}

LicenseStatus.propTypes = {
  license: PropTypes.shape().isRequired,
};

export default LicenseStatus;
