import styled from 'styled-components';
import colors from '~/styles/colors';

export const Title = styled.div`
  margin: 30px 0;
  font-size: 30px;
  font-weight: bold;
  color: ${colors.color_1};
`;

export const Text = styled.div`
  margin: 30px 0;
  font-size: 20px;
  color: ${colors.color_1};
`;

export const Container = styled.div`
  /* background: lightcyan; */
  margin: 30px 0;
  padding: 10px;
  flex: 1;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
