import React, { useState, useRef } from 'react';
import Loader from 'react-loader-spinner';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { MdFileUpload, MdClear } from 'react-icons/md';

import api from '~/services/api';
import getErrorMessage from '~/lib/getErrorMessage';

import Modal from '~/components/Modal';
import { Title } from '~/components/Title';
import { Button } from '~/components/Buttons/Button';
import { Field, FieldGroup } from '~/components/Forms';
import colors from '~/styles/colors';

import {
  Container,
  UploadingContainer,
  SumaryContainer,
  LogTextArea,
  TextAreaLabel,
} from './styles';

function ImportCSVModal({ isShowing, onClose, route, csvHeaders }) {
  // const [isShowing, setIsShowing] = useState(true);
  const filesElement = useRef(null);
  const [uploading, setUploading] = useState(false);
  const uploadTimer = useRef(null);
  const [notImportedTxt, setNotImportedTxt] = useState('');
  const [errorsTxt, setErrorsTxt] = useState('');

  const [notImported, setNotImported] = useState([]);
  const [imported, setimported] = useState([]);
  const [errors, setErrors] = useState([]);
  const [file, setFile] = useState(null);
  const [isImported, setIsImported] = useState(false);

  function handleFileChange(e) {
    setFile(e.target.files[0]);
  }

  async function importCSV() {
    if (!file) {
      alert('Nenhum arquivo para importar.');
      return;
    }

    // Evita que o loading "pisque" na tela se houver algum erro de upload
    clearTimeout(uploadTimer.current);
    uploadTimer.current = setTimeout(() => setUploading(true), 100);

    const data = new FormData();
    // data.append('file', e.target.files[0]);
    data.append('file', file);

    try {
      const response = await api.post(route, data);
      const { errors: err, notImported: notImp } = response.data;

      if (notImp && notImp.length > 0) {
        let notImpTxt = '';
        notImp.map((item) => {
          notImpTxt += `${JSON.stringify(item)}\r\n`;
          return item;
        });
        setNotImportedTxt(notImpTxt);
      }

      if (err && err.length > 0) {
        let errTxt = '';
        err.map((item) => {
          errTxt += `${JSON.stringify(item)}\r\n`;
          return item;
        });
        setErrorsTxt(errTxt);
      }

      setimported(response.data.imported);
      setNotImported(response.data.notImported);
      setErrors(response.data.errors);
      setIsImported(true);
      setUploading(false);
      clearTimeout(uploadTimer.current);

      toast.success(<div>Importação finalizada.</div>);
    } catch (err) {
      clearTimeout(uploadTimer.current);
      setUploading(false);
      toast.error(
        <div>
          Falha ao importar arquivo CSV
          <br /> <br />
          {getErrorMessage(err)}
        </div>
      );
    }
  }

  function handleClearFile() {
    setUploading(false);
    setNotImportedTxt('');
    setErrorsTxt('');

    setimported([]);
    setNotImported([]);
    setErrors([]);

    filesElement.current.value = null;
    setFile(null);
    setIsImported(false);
  }

  function handleHide() {
    handleClearFile();
    onClose();
  }

  return (
    <Modal isShowing={isShowing} hide={handleHide}>
      <Container>
        <FieldGroup>
          <Field>
            <Title>Importar arquivo .CSV</Title>
          </Field>
        </FieldGroup>

        <FieldGroup>
          <Field>
            <strong>Headers do arquivo CSV:</strong>
            <span>{csvHeaders}</span>
          </Field>
        </FieldGroup>

        <FieldGroup>
          <Field>
            <input
              type="file"
              // multiple
              accept=".csv"
              // data-file={file}
              onChange={handleFileChange}
              onClick={handleClearFile}
              ref={filesElement}
              disabled={uploading}
            />
          </Field>

          {(isImported || file) && (
            <Field flex="none">
              <Button
                onClick={handleClearFile}
                style={{ width: 150 }}
                disabled={uploading}>
                <MdClear size={24} color={colors.iconLight} />
                Limpar
              </Button>
            </Field>
          )}

          {!isImported && file && (
            <Field flex="none">
              <Button
                primary
                onClick={importCSV}
                disabled={uploading}
                style={{ width: 150 }}>
                {/* {uploading ? (
                  <Loader
                    type="Oval"
                    color={colors.iconLight}
                    height={20}
                    width={20}
                  />
                ) : (
                  <MdFileUpload size={24} color={colors.iconLight} />
                )} */}
                <MdFileUpload size={24} color={colors.iconLight} />
                Importar
              </Button>
            </Field>
          )}
        </FieldGroup>

        {uploading && (
          <FieldGroup>
            <Field>
              <UploadingContainer>
                <Loader
                  type="Oval"
                  color={colors.color_3}
                  height={40}
                  width={40}
                />
              </UploadingContainer>
            </Field>
          </FieldGroup>
        )}

        {(imported.length > 0 ||
          notImported.length > 0 ||
          errors.length > 0) && (
          <FieldGroup>
            <Field>
              <SumaryContainer>
                <Field>
                  <div>Importados</div>
                  <strong>{imported.length}</strong>
                </Field>

                <Field>
                  <div>Não importados</div>
                  <strong>{notImported.length}</strong>
                </Field>

                <Field>
                  <div>Erros</div>
                  <strong>{errors.length}</strong>
                </Field>
              </SumaryContainer>
            </Field>
          </FieldGroup>
        )}

        {errorsTxt !== '' && (
          <FieldGroup>
            <Field>
              <div>
                <TextAreaLabel htmlFor="txtErrors">Erros</TextAreaLabel>
                <br />
                <LogTextArea
                  name="txtErrors"
                  cols="100"
                  rows="10"
                  value={errorsTxt}
                  readOnly
                />
              </div>
            </Field>
          </FieldGroup>
        )}

        {notImportedTxt !== '' && (
          <FieldGroup>
            <Field>
              <div>
                <TextAreaLabel htmlFor="txtNotImported">
                  Não importados
                </TextAreaLabel>
                <br />
                <LogTextArea
                  name="txtNotImported"
                  cols="100"
                  rows="10"
                  value={notImportedTxt}
                  readOnly
                />
              </div>
            </Field>
          </FieldGroup>
        )}
      </Container>
    </Modal>
  );
}

ImportCSVModal.propTypes = {
  isShowing: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  route: PropTypes.string.isRequired,
  csvHeaders: PropTypes.string.isRequired,
};

export default ImportCSVModal;
