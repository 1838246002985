import React from 'react';
import { Form, Input, Check } from '@rocketseat/unform';
import { MdDone, MdClearAll } from 'react-icons/md';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { FormHeader } from '~/components/FormHeader';
import { Title } from '~/components/Title';
import { Button } from '~/components/Buttons/Button';
import colors from '~/styles/colors';
import { FieldGroup, Field } from '~/components/Forms';

const ModalFilter = ({
  loadNpsSurveys,
  handleCloseImportModal,
  cleanFilters,
  startDate,
  finalDate,
  setStartDate,
  setFinalDate,
  npsRating,
  setRating,
  rating,
  message,
  setMessage,
}) => {
  return (
    <Form
      onSubmit={() => {
        loadNpsSurveys();
        handleCloseImportModal();
      }}>
      <FormHeader>
        <Title>Filtros de NPS</Title>
        <div>
          <Button primary onClick={() => cleanFilters()}>
            <MdClearAll size={24} color={colors.iconLight} />
            <span>Limpar</span>
          </Button>
        </div>
        <div>
          <Button primary type="submit">
            <MdDone size={24} color={colors.iconLight} />
            <span>Aplicar</span>
          </Button>
        </div>
      </FormHeader>
      <FieldGroup>
        <Field flex="none">
          <label htmlFor="startDate">Data Inicial</label>
          <Input
            name="startDate"
            type="date"
            autoComplete="off"
            value={startDate || ''}
            onChange={(e) => setStartDate(e.target.value)}
          />
        </Field>
        <Field flex="none">
          <label htmlFor="finalDate">Data Final</label>
          <Input
            name="finalDate"
            type="date"
            autoComplete="off"
            value={finalDate || ''}
            onChange={(e) => setFinalDate(e.target.value)}
          />
        </Field>
      </FieldGroup>
      <FieldGroup>
        <Field flex={1}>
          <label htmlFor="rating">Nota</label>
          <Select
            name="rating"
            placeholder="Selecione uma Nota"
            onChange={setRating}
            options={npsRating.map((nps) => nps)}
            value={rating}
            onClick={(e) => setRating(e.value)}
          />
        </Field>
        <Field flex="none">
          <label htmlFor="message">Com Mensagem</label>
          <Check
            name="message"
            checked={message}
            onChange={() => setMessage(!message)}
          />
        </Field>
      </FieldGroup>
    </Form>
  );
};

ModalFilter.propTypes = {
  loadNpsSurveys: PropTypes.func.isRequired,
  handleCloseImportModal: PropTypes.func.isRequired,
  cleanFilters: PropTypes.func.isRequired,
  startDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  finalDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  setStartDate: PropTypes.func.isRequired,
  setFinalDate: PropTypes.func.isRequired,
  npsRating: PropTypes.arrayOf(PropTypes.object).isRequired,
  setRating: PropTypes.func.isRequired,
  rating: PropTypes.oneOfType([PropTypes.object]),
  message: PropTypes.bool.isRequired,
  setMessage: PropTypes.func.isRequired,
};

ModalFilter.defaultProps = {
  rating: { label: 'Selecione uma nota', value: -1 },
  startDate: null,
  finalDate: null,
};

export default ModalFilter;
