import React, { useState, useEffect, useRef } from 'react';
import { MdAdd, MdDeleteForever, MdRefresh } from 'react-icons/md';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { format, parseISO } from 'date-fns';
import { useSelector } from 'react-redux';
import AsyncSelect from 'react-select/async';

import api from '~/services/api';
import colors from '~/styles/colors';

import Can from '~/components/Can';
import { BaseContainer } from '~/components/BaseContainer';
import { Table } from '~/components/Table';
import { Title } from '~/components/Title';
import { NoInfoToShow } from '~/components/NoInfoToShow';
import Actions from '~/components/Actions';
import Pagination from '~/components/Pagination';
import { Button } from '~/components/Buttons/Button';
import ColumnHeader from '~/components/ColumnHeader';
import getErrorMessage from '~/lib/getErrorMessage';
import { FieldGroup, Field } from '~/components/Forms';
import SearchInput from '~/components/Inputs/SearchInput';

import { Header } from './styles';

function ContractModules({ contract, maxWidthPercent }) {
  const role = useSelector((state) => state.role.role);
  const selectInputRef = useRef();

  const [filter, setFilter] = useState('');
  const time = useRef(null);
  const timerContract = useRef(null);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [contractModules, setContractModules] = useState([]);
  const [order, setOrder] = useState({
    field: 'module.name',
    asc: true,
  });
  const [selectedModules, setSelectedModules] = useState([]);
  const [refresh, setRefresh] = useState(true);

  /**
   * Ao mudar o "key" de um componente, foça a sua remontagem.
   * Por isso, esse foi o único jeito de recarregar os dados do Async Select de Módulos.
   */
  const [modulesSelectKey, setModulesSelectKey] = useState();

  function reloadModuleOptions() {
    setModulesSelectKey(modulesSelectKey ? undefined : 'setModulesSelectKey_1');
  }

  useEffect(() => {
    async function loadContractModules(f) {
      if (contract) {
        setLoading(true);

        const response = await api.get(`contracts/${contract.id}/modules`, {
          params: {
            q: f,
            page,
            pageLimit,
            order: order.field,
            direction: order.asc ? 'ASC' : 'DESC',
          },
        });

        const data = response.data.map((item) => {
          return {
            ...item,
            createdAtFormatted:
              item.createdAt &&
              format(parseISO(item.createdAt), 'dd/MM/yyyy HH:mm:ss'),
          };
        });

        setTotalPages(Number(response.headers['x-api-totalpages']));
        setTotal(Number(response.headers['x-api-total']));
        setContractModules(data);

        setLoading(false);
      }
    }

    clearTimeout(time.current);
    time.current = setTimeout(() => {
      loadContractModules(filter);
    }, 600);
  }, [filter, page, pageLimit, order, contract, refresh]);

  function handleFilterChange(value) {
    setPage(1);
    setFilter(value);
  }

  function reload() {
    setFilter(' ');
    setFilter('');
  }

  function clearSelectedModules() {
    selectInputRef.current.select.select.clearValue();
  }

  async function addModuleToContract(module_id) {
    try {
      if (!contract) {
        return;
      }

      await api.post(`contracts/${contract.id}/modules`, {
        module_id,
      });

      toast.success('Módulo adicionado com sucesso!');
      reload();
      reloadModuleOptions();
    } catch (err) {
      toast.error(
        <div>
          Falha ao adicionar módulo! <br /> <br />
          {getErrorMessage(err)}
        </div>
      );
    }
  }

  function handleCreate() {
    if (selectedModules.length > 0) {
      selectedModules.forEach((m) => {
        addModuleToContract(m.value);
      });
      clearSelectedModules();
    }
  }

  async function handleDelete(module_id) {
    if (!window.confirm('Deseja mesmo remover o módulo?')) {
      return;
    }

    try {
      await api.delete(`contracts/${contract.id}/modules/${module_id}`);
      toast.success('Módulo removido com sucesso!');
      reload();
      reloadModuleOptions();
    } catch (err) {
      toast.error(
        <div>
          Falha ao remover módulo! <br /> <br />
          {getErrorMessage(err)}
        </div>
      );
    }
  }

  function handleChangeOrder(field) {
    setPage(1);
    setOrder({ field, asc: field === order.field ? !order.asc : true });
  }

  /**
   * Async Select (Contracts)
   */

  async function loadModules(q) {
    const response = await api.get('modules', {
      params: { q, pageLimit: 50, ignore_contract_id: contract.id },
    });

    const modules = response.data.map((item) => {
      return { value: item.id, label: item.name };
    });

    return modules;
  }

  const loadModuleOptions = (inputValue, callback) => {
    clearTimeout(timerContract.current);
    timerContract.current = setTimeout(async () => {
      callback(await loadModules(inputValue));
    }, 600);
  };

  function handleChangeModule(selectedOptions) {
    setSelectedModules(selectedOptions || []);
  }

  function handleRefresh() {
    setLoading(true);
    setRefresh(!refresh);
  }

  return (
    <BaseContainer maxWidthPercent={maxWidthPercent}>
      <Title>Módulos Contratados {total > 0 && `(${total})`}</Title>

      <Header>
        <SearchInput
          placeholder="módulo"
          value={filter}
          onChange={(e) => handleFilterChange(e.target.value)}
        />

        <FieldGroup>
          <Field flex="none">
            <Button
              type="button"
              padding="6px"
              disabled={loading}
              onClick={handleRefresh}>
              <MdRefresh size={24} color={colors.iconLight} />
            </Button>
          </Field>

          <Can
            role={role}
            perform="contracts:edit"
            yes={() => (
              <Field style={{ minWidth: 300 }}>
                <AsyncSelect
                  key={modulesSelectKey}
                  ref={selectInputRef}
                  isMulti
                  closeMenuOnSelect={false}
                  name="modules"
                  placeholder="Selecione módulos..."
                  cacheOptions
                  loadOptions={loadModuleOptions}
                  defaultOptions
                  onChange={handleChangeModule}
                  isClearable="true"
                />
              </Field>
            )}
          />

          <Can
            role={role}
            perform="contracts:edit"
            yes={() => (
              <Button
                primary
                type="button"
                padding="6px"
                onClick={handleCreate}
                disabled={selectedModules.length === 0}>
                <MdAdd size={24} color={colors.iconLight} />
              </Button>
            )}
          />
        </FieldGroup>
      </Header>
      <Table>
        <thead>
          <tr>
            <th>
              <ColumnHeader
                label="Módulo"
                showOrder={order.field === 'module.name'}
                asc={order.asc}
                onClick={() => handleChangeOrder('module.name')}
              />
            </th>

            <th>
              <ColumnHeader
                label="Criado em"
                showOrder={order.field === 'created_at'}
                asc={order.asc}
                onClick={() => handleChangeOrder('created_at')}
              />
            </th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {contractModules.map((item) => (
            <tr key={item.id}>
              <td>{item.module.name}</td>
              <td>{item.createdAtFormatted}</td>
              <td>
                <Actions height={90} width={150}>
                  {/* <li>
                    <MdEdit color="#4D85EE" size={16} />
                    <button type="button" onClick={() => handleEdit(item.id)}>
                      Editar
                    </button>
                  </li> */}
                  <Can
                    role={role}
                    perform="contracts:edit"
                    yes={() => (
                      <li>
                        <MdDeleteForever color="#DE3B3B" size={16} />
                        <button
                          type="button"
                          onClick={() => handleDelete(item.module.id)}>
                          Remover
                        </button>
                      </li>
                    )}
                    no={() => (
                      <li>
                        <MdDeleteForever
                          color={colors.textDisabled}
                          size={16}
                        />
                        <button type="button" disabled>
                          Remover
                        </button>
                      </li>
                    )}
                  />
                </Actions>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {!loading && contractModules.length === 0 ? (
        <NoInfoToShow>Nenhuma informação a exibir.</NoInfoToShow>
      ) : (
        <Pagination
          totalPages={totalPages}
          page={page}
          setPage={setPage}
          pageLimit={pageLimit}
          setPageLimit={setPageLimit}
        />
      )}
    </BaseContainer>
  );
}

ContractModules.propTypes = {
  contract: PropTypes.shape({
    id: PropTypes.number.isRequired,
    contract: PropTypes.shape(),
  }),
  maxWidthPercent: PropTypes.number,
};

ContractModules.defaultProps = {
  contract: null,
  maxWidthPercent: undefined,
};

export default ContractModules;
