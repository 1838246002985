import React from 'react';
import PropTypes from 'prop-types';
import status from './status';

import { Container } from './styles';

function ExternalConfigsStatus({ config }) {
  function getStatus() {
    return config.active ? status.ativo : status.inativo;
  }

  const applicationStatus = getStatus();

  return (
    <Container status={applicationStatus}>
      <span />
      {applicationStatus}
    </Container>
  );
}

ExternalConfigsStatus.propTypes = {
  config: PropTypes.shape().isRequired,
};

export default ExternalConfigsStatus;
