import styled from 'styled-components';

export const Header = styled.header`
  margin-top: 30px;
  padding: 10px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const CellWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  > svg {
    margin: 0 5px;
  }
`;

export const ModalContainer = styled.div`
  /* background: lightcoral; */
  display: flex;
  flex-direction: column;
  padding: 30px;
`;

export const UploadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px;
  align-items: center;
  justify-content: center;
`;
