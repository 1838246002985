import styled from 'styled-components';
import colors from '~/styles/colors';

export const ColHeader = styled.div`
  color: ${colors.textDarker};
  font-weight: bold;

  cursor: pointer;

  > div {
    /* background: lightskyblue; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    svg {
      margin-left: 5px;
    }
  }
`;
